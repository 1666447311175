export class Wagtail {
  constructor(ajax, url){
    this.ajax = ajax;
    this.pages_url = '/api/v2/pages';
    this.images_url = '/api/v2/images';
    this.all_blog_posts_small = '/?type=blog.PostPage&fields=header_image';
    this.all_blog_posts_desc = '/?type=blog.PostPage&fields=header_image,body';
    this.all_blog_posts = '/?type=blog.PostPage';
    this.all_fields='&fields=header_image,body,tags';
    this.tags_filter='&tags=';
    this.order_by_new='&order=-first_published_at'
    this.offset='&offset='
    this.limit='?limit='
    this.debug_url_blog = url;
    this.debug_url_services = url;
    this.all_services_posts = '/wgtail/api/services';
    this.tos_page = '/wgtail/api/terms/';
    this.school_page = '/wgtail/api/publicschool/';
    this.custom_api='/wgtail/api/postpage'+  this.limit + 12;
    this.add_fav_post_url = '/numer/api/favpost/';
    this.tos_list = '/wgtail/api/allterms/';
    this.search='&search='
    this.get_comments = '/post/api/comments/';
  };

  debug_fetch(cbGet) {
    let url_all_desc = this.debug_url_blog+this.custom_api;
    this.ajax.get(false,url_all_desc).then(
      (data) => {

        cbGet(data);
      }
    )
    .catch(
      (rejection) => {

      }
    )
  }

  debug_fetch_url(cbGet,url) {
    this.ajax.get(false,url).then(
      (data) => {

        cbGet(data);
      }
    )
    .catch(
      (rejection) => {

      }
    )
  }

  debug_fetch_services(cbGet) {
    let url_all_desc = this.debug_url_services+this.all_services_posts;
    this.ajax.get(false,url_all_desc).then(
      (data) => {
        let new_data_experts = [];
        let new_data_olga = [];
        for (let ci in data){
          let i = data[ci];
          let nobj = {};

          nobj=i;
          if (nobj.expert) new_data_experts.push(nobj);
            //else new_data_olga.push(nobj);
        }
        cbGet(new_data_experts,new_data_olga);
      }
    )
    .catch(
      (rejection) => {

      }
    )
  }


  debug_search(cbGet,search) {
    if (search.length === 0)
      return this.debug_fetch(cbGet);

    let url_all_desc = this.debug_url_blog+this.custom_api+this.search+search;


    this.ajax.get(false,url_all_desc).then(
      (data) => {

        cbGet(data);
      }
    )
    .catch(
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  debug_comments(id,offset,cbGet) {
    let url = this.debug_url_blog+this.get_comments + id+'/';
    this.ajax.get(false,url).then(
      (data) => {
        cbGet(data,offset);
      }
    )
    .catch (
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  debug_add_comments(id,data,cbGet) {
    let url = this.debug_url_blog+this.get_comments + id+'/add';
    this.ajax.post(true,url,data).then(
      (data) => {

        cbGet(data);
      }
    )
    .catch (
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  debug_add_reply(id,data,cbGet) {
    let url = this.debug_url_blog+this.get_comments + id+'/reply';
    this.ajax.post(true,url,data).then(
      (data) => {
        cbGet(data);
      }
    )
    .catch (
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  debug_add_reply_like(id,cbRes) {
    let url = this.debug_url_blog + '/likes/api/reply/'+id+'/';

    this.ajax.post(true,url,null).then(
      (data) => {

        cbRes(data);
      }
    )
    .catch (
      (rejection) => {
        console.log(rejection);
      }
    )
  }
  debug_add_comment_like(id,cbRes) {
    let url = this.debug_url_blog + '/likes/api/comment/'+id+'/';

    this.ajax.post(true,url,null).then(
      (data) => {
        cbRes(data);
      }
    )
    .catch (
      (rejection) => {
        console.log(rejection);
      }
    )
  }
  debug_add_post_like(id,cb) {
    let url = this.debug_url_blog + '/likes/api/post/'+id+'/';

    this.ajax.post(true,url,null).then(
      (data) => {
        cb(data);
      }
    )
    .catch (
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  debug_add_fav_post(id,cb) {
    let url = this.debug_url_blog + this.add_fav_post_url+id+'/';

    this.ajax.post(true,url,null).then(
      (data) => {
        cb(data);
      }
    )
    .catch (
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  debug_get_terms_page(id,cbGet) {
    let url_all_desc = this.debug_url_blog+this.tos_page + id+'/';
    this.ajax.get(false,url_all_desc).then(
      (data) => {
        cbGet(true,data);
      }
    )
    .catch(
      (rejection) => {
        cbGet(false,rejection);
      }
    )
  }

  debug_get_schoolpublic_page( cbGet ){
    let url_all_desc = this.debug_url_blog+this.school_page +'555/';
    this.ajax.get(false,url_all_desc).then(
      (data) => {
        cbGet(true,data);
      }
    )
    .catch(
      (rejection) => {
        cbGet(false,rejection);
      }
    )
  }

  debug_fetch_all_terms(cbGet) {
    let url_all_desc = this.debug_url_blog+this.tos_list;
    this.ajax.get(false,url_all_desc).then(
      (data) => {
        cbGet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbGet(rejection,false);
      }
    )
  }


};
