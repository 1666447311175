import React, { Component } from 'react'
import { Text, StyleSheet } from 'react-native'


// https://github.com/necolas/react-native-web/issues/162
// need add this patch
// - break

export default class Ptext extends Component {
  render () {
    return (
      <Text style={[styles.paragraphText,{fontSize : this.props.fontSize},this.props.style]} >
      {this.props.children}
      </Text>
    )
  }
}

const styles = StyleSheet.create({
  paragraphText: {
    textAlign : 'center',
    fontFamily: 'Prosto One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: 15,
    marginTop : 5,
    marginBottom : 5,

  }
})
