import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import Ptext from "../../../common/Ptext.js"

class ContactChatItem extends Component {
  // default constructor
  _onPress = () => {

    if (this.props.onPressItem)
      this.props.onPressItem(this.props.id);
  }
  render () {
    return (
      <TouchableOpacity style={[styles.horizontalWrapper,{backgroundColor : this.props.selected ? '#f1f3f5' :'#fff'}]} onPress={this._onPress}>
        <Image style={styles.chatAvatar} source={this.props.chat !== undefined ? this.props.chat.avatars[6].url : ''}/>
        <Ptext style={styles.chatCaption}>{this.props.chat !== undefined ? '@'+this.props.chat.username: ''} {this.props.chat.real_name != null ? this.props.chat.real_name : ''}</Ptext>
      </TouchableOpacity>
    )
  }

};

const styles = StyleSheet.create({
  horizontalWrapper : {
    width : '100%',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'center',
    flexDirection : 'row',
    height : 56,
  },
  chatAvatar : {
    width : 32,
    height : 32,
  },
  chatCaption : {
    fontSize : 16,
    lineHeight : 24,
    marginLeft : 17,
  },


});

export default ContactChatItem;
