import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import Ptext from './Ptext.js';
import globalStyle from '../styles/globalStyle';

class ButtonArrow extends Component {
  render () {
    let _style = this.props.hasOwnProperty('theme') ? this.props.theme : 'primary';

    switch (_style) {
      case 'light':
        _style = 'primary';
        break;
      case 'dark':
        _style = 'secondary';
        break;
      default:
        _style = 'primary';
    }



    return (
      <TouchableOpacity
                style={[styles.button,this.props.style]}
                onPress={this.props.onPress}
                disabled={this.props.disabled}
                underlayColor='#fff'>
                <Ptext style={[styles.title,this.props.styleTitle,{margin : 0,padding : 0,
                color : globalStyle.colors['font_'+_style]}]}>{this.props.title}</Ptext>
                <svg style={{width : 178, height : 8}}width="178" height="8" viewBox="0 0 178 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M177.354 4.35355C177.549 4.15829 177.549 3.84171 177.354 3.64645L174.172 0.464466C173.976 0.269204 173.66 0.269204 173.464 0.464466C173.269 0.659728 173.269 0.976311 173.464 1.17157L176.293 4L173.464 6.82843C173.269 7.02369 173.269 7.34027 173.464 7.53553C173.66 7.7308 173.976 7.7308 174.172 7.53553L177.354 4.35355ZM0 4.5L177 4.5V3.5L0 3.5L0 4.5Z" fill={globalStyle.colors['font_'+_style]}/>
                </svg>
       </TouchableOpacity>
    )
   }
};

const styles = StyleSheet.create({
  button : {
    marginTop : 12,
    marginBottom : 12,
    paddingTop: 12,
    paddingBottom : 12,
    minHeight : 68, 
    alignItems : 'center',
    justifyContent : 'center',
  },
  title : {
    textAlign : 'center',
    paddingLeft : 12,
    paddingRight : 12,
    margin : 2,
    letterSpacing: 2,
    textTransform: 'uppercase',
  
  }
});

export default ButtonArrow;
