class AdaptiveSupport {
  constructor() {
    this._ranges = [{min:0,max:539},
                    {min:540,max:719},
                    {min:720,max:1023},
                    {min:1024,max:1440},
                    {min:1440,max:33000}];
  }

  testRange(prev,next){
    let pr_r = -1;
    let ne_r = -1;

    for (let i = 0; i < this._ranges.length; i++){
      if (prev >= this._ranges[i].min && prev <= this._ranges[i].max){
        pr_r = i;
      }
      if (next >= this._ranges[i].min && next <= this._ranges[i].max){
        ne_r = i;
      }
    }
    return {neq : pr_r !== ne_r, range : ne_r};
  }

}
export default AdaptiveSupport;
