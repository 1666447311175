import React,{Component} from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import CalculateForm from "../../components/matrix/CalculateForm.js"
import Stories from "../../components/stories/stories.js"
import WhatIs from "../../components/main/whatIs.js"
import ServicesViewPage from "../../components/services/servicesViewPage"
import BlogViewPage from "../../components/blog/blogViewPage"
import ReviewPageView from "../../components/review/reviewPageView"
import MainBanner from "../../components/main/mainBanner.js"
import FaqPageView from "../../components/faq/faqPageView"
import PromoService from "../../components/main/promoService.js"
import PromoSchool from "../../components/main/promoSchool.js"
import IndexFooter from "../../components/main/indexFooter.js"
import PromoAutoDescription from "../../components/main/promoAutoDescription.js"
import BlogListItemIndex from "../../components/blog/blogListItemIndex.js"
import Footer from "../../common/Footer.js"

var stories_debug = [
  {
    name : 'Обучение',
  },
  {
    name : 'Самопознание',
  },
  {
    name : 'Профессии',
  },
  {
    name : 'Дети',
  },
  {
    name : 'Отношения',
  },
  {
    name : 'Личности',
  },
  {
    name : 'Юмор',
  },
  {
    name : 'Новичку',
  },
  {
    name : 'Матрицы',
  },
  {
    name : 'Десятый',
  }
];

class IndexPage extends Component {


  _onDateInputChanged = (value) => {
    let ppp = value.replace(/[^\d]/g, '');
    let days = ppp.slice(0,2);
    let mm = ppp.slice(2,4);
    let yy = ppp.slice(4);

    if (parseInt(days,10) > 31)
      days='31';
    if ((days.length)&&(days[0] > '3'))
        days = ("000" + parseInt(days,10)).slice(-2);

    if (parseInt(mm,10) > 12)
        mm='12';
    if ((mm.length)&&(mm[0]>'1'))
        mm = ("000" + parseInt(mm,10)).slice(-2);

    let tt = [];
    if (days.length)
      tt.push(days);
    if (mm.length)
        tt.push(mm);
    if (yy.length)
        tt.push(yy);
    ppp = tt.join('.');



    this.props.setParentState({bidValue : ppp});
  }

  onCalcPress = () => {
    let calcItem = {
      date : this.props.conf.bidValue,
      name : '',
    }
    this.props.onCalcPress(calcItem);
    this.props.history.push('/matrix');
  }

  onServicePress = () => {
    this.props.history.push('/services');
  }

   cb_calulate_form = {
    cbDateInput : this._onDateInputChanged,
    cbCalcPress : this.onCalcPress
  }

  onApplySchool = () => {
    this.props.history.push('/school');
  }

  onOrderBook = () => {
    window.location.replace('https://ast.ru/book/nenumerologiya-analiz-lichnosti-842063/');
  }



  _renderNarrow = () => {
    let post = {};
    if (this.props.posts.hasOwnProperty('results'))
      post = this.props.posts.results[0];
    return (
          <View style={styles.wrapper2nd}>
            <MainBanner fullWidth={this.props.fullWidth}/>
            <PromoAutoDescription fullWidth={this.props.fullWidth} history={this.props.history}/>
            <CalculateForm supp={this.props.supp} cb={this.cb_calulate_form} bidValue={this.props.conf.bidValue} showFav={false}
            addLabel={true} history={this.props.history}/>
            <ServicesViewPage fullWidth={this.props.fullWidth} history={this.props.history} services={this.props.services}/>
            <BlogViewPage fullWidth={this.props.fullWidth} history={this.props.history} posts={this.props.posts !== undefined && this.props.posts.hasOwnProperty('results') && Array.isArray(this.props.posts.results) ? this.props.posts.results.slice(0,5) : []}/>
            <ReviewPageView fullWidth={this.props.fullWidth} history={this.props.history} review={this.props.review !== undefined ? this.props.review.slice(0,this.props.fullWidth > 555 ? 3 : 1) : undefined}/>
            <FaqPageView fullWidth={this.props.fullWidth} history={this.props.history} faq={this.props.faq}/>


          </View>
    )
  }
/*
          <Stories items={stories_debug} style={{width : '100%'}}/>


            <WhatIs/>
            <PromoService fullWidth={this.props.fullWidth}/>
            <BlogListItemIndex post={post} scrollWidth={this.props.fullWidth}/>
            <PromoSchool flow_cnt={13} school_date_started={'1 октября'} school_date_ended={'30 ноября'}/>

*/
  render () {

    return (
      <ScrollView style={[styles.wrapper,{height:this.props.fullHeight, width : this.props.fullWidth}]} contentContainerStyle={styles.wrapperContentStyle}>

          {this._renderNarrow()}
        <IndexFooter fullWidth={this.props.fullWidth}/>
        <Footer/>
      </ScrollView>

    )
  }
}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    alignSelf : 'center',
    backgroundColor : '#F1F3F5',
    width : '100%'
  },
  wrapperContentStyle : {
    alignItems : 'center',
    justifyContent : 'flex-start',
  },
  wrapper2nd : {
    flexDirection : 'column',
    alignSelf : 'center',
    alignItems : 'center',
    justifyContent : 'flex-start',
    backgroundColor : '#F1F3F5',
    width : '100%',
  },
  horizWide : {
    flexDirection : 'row',
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
  },
  verticalColumnLeft : {
    flexDirection : 'column',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    flex : 66,
    marginTop : 16,
    marginLeft : 16,
    marginRight : 8,
  },
  verticalColumnRight : {
    flexDirection : 'column',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    flex : 33,
    marginTop : 16,
    marginRight : 16,
    marginLeft : 8,
  }

});

export default IndexPage;
