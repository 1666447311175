import React, { Component } from 'react';
import {View, StyleSheet, TouchableOpacity, TextInput} from 'react-native';
import Modal from 'modal-react-native-web';
import Ptext from '../common/Ptext.js';
import Button from '../common/Button.js';





class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items  :  this.props.form.items,
    };
  } 

  _editItem = (id,value) => {
    var items = this.state.items;
    items[id].value = value;
    items[id].error = undefined;
    this.setState({items : items });
  }

  _formCb = (data, status) => {

    if (!status) {
      let summ_descr = '';
      let items = this.state.items;
      for (let i in data) {
        console.log(i);
        if (Array.isArray(data[i])) {
          for (let j in data[i]) {
            console.log(data[i][j]);
            summ_descr += data[i][j];
          }
          for (let j in items) {
            if (items[j].field === i) {
              items[j].error = summ_descr;
            }
          }
          summ_descr = '';
        }
      }
      this.setState({error_desc : summ_descr, items : items});
      setTimeout(() => {this.setState({error_desc: undefined})}, 4000);
    }else {
      if (!data.hasOwnProperty('detail'))
        this.setState({done_desc : 'Сохранено'});
      else {
        this.setState({done_desc : data.detail});
      }
      setTimeout(() => {this.setState({done_desc: undefined})}, 4000);

    }

  }

  _submitForm = () => {
    this.props.form.submit(this.state.items, this._formCb);

  }


  render () {
    return (
      <Modal visible={this.props.visible}
        transparent={false} ariaHideApp={false} style={styles.modal} animationType={'fade'} >
        <View style={styles.header}>
          <TouchableOpacity style={styles.headerChevron} onPress={this.props.form.cbBack}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4 7.4L14 6L8 12L14 18L15.4 16.6L10.8 12L15.4 7.4Z" fill="black"/>
            </svg>
            <Ptext style={styles.headerCaption}>
              {this.props.form.caption}
            </Ptext>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.props.form.cbBack} style={styles.closeCross}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
            <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
            </svg>
          </TouchableOpacity>
        </View>
        <View style={styles.wholeForm}>
          {this.state.error_desc ?
          <Ptext style={styles.error}>
            {this.state.error_desc}
          </Ptext>
          :
          null }
          {this.state.done_desc ?
          <Ptext style={styles.done_desc}>
            {this.state.done_desc}
          </Ptext>
          :
          null }

          <Ptext style={styles.mainHint}>
            {this.props.form.mainHint}
          </Ptext>
          {
            this.state.items.map((item,index,arr) => {
              let c_bottom = item.hasOwnProperty('hint') ? 0 : item.hasOwnProperty('error') ? 0 : 20;
              let borderColor = item.hasOwnProperty('error') && item.error !== undefined ? 'red' : '#green';
              let height = 40 * (item.multi ? 4 : 1);
              return (
                <View style={styles.formItem} key={index} id={index} >
                  <Ptext style={styles.itemHint}>
                    {item.caption}
                  </Ptext>
                  <TextInput value={item.value} placeholder={item.placeholder} style={[styles.itemTextInput,{marginBottom : c_bottom, borderColor : borderColor, height : height, minHeight : height }]}
                    multiline={item.multi}
                    secureTextEntry={item.secret}
                    onChangeText={this._editItem.bind(this,index)}/>
                  {
                      item.hasOwnProperty('error') ?
                      <Ptext style={styles.errorHint}>
                        {item.error}
                      </Ptext>
                      :
                      null
                  }
                  {
                    item.hasOwnProperty('hint') ?
                    <Ptext style={styles.itemSubHint}>
                      {item.hint}
                    </Ptext>
                    :
                    null
                  }
                </View>
              )
            })
          }
          <Button title={this.props.form.titleSubmit} style={styles.button}
            onPress={this._submitForm}/>
        </View>
      </Modal>

    )
  }

}

const styles=StyleSheet.create({
  modal : {
    width : '100%',
    height : '100%',
  },
  header : {
    width : '100%',
    minHeight : 65,
    justifyContent : 'flex-start',
    alignItems : 'center',
    alignSelf : 'center',
    flexDirection : 'row',
  },
  headerChevron : {
        marginLeft : 10,
        flexDirection : 'row',
        alignSelf : 'center',
        justifyContent : 'flex-start',
        alignItems : 'center',
        flex : 2,
  },
  headerCaption : {
    flex : 2,
    fontWeight : 'bold',
    fontSize : 20,
    lineHeight : 24,
    textAlign : 'left',
  },

  closeCross : {
    marginRight : 10,
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
  },
  backChevron : {
    marginLeft : 10,
  },
  wholeForm : {
    width : '90%',
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    alignSelf : 'center',
  },
  mainHint : {
    width  : '100%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
  },
  error : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : 'red',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  done_desc : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : '#90ee90',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  formItem : {
    width : '100%',
    flexDirection : 'column',
    alignSelf : 'flex-start',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  itemHint : {
    fontSize : 12,
    lineHeight : 16,
    marginBottom : 8,
  },
  itemSubHint : {
    fontSize : 12,
    lineHeight : 16,
    color : '#B4BBC2',
    textAlign : 'left',
    marginBottom : 20,
  },
  errorHint : {
    fontSize : 12,
    lineHeight : 16,
    fontWeight : 'italic',
    color : 'red',
    textAlign : 'left',
    marginBottom : 20,
  },
  itemTextInput : {
    borderWidth : 1,
    height : 40,
    minHeight : 40,
    borderRadius : 4,
    flex : 1,
    margin : 4,
    paddingLeft : 8,
    width : '98%',
    marginBottom : 20,
    borderColor : '#000',
  },
  button : {
    width : '100%',
  }

});


export default EditForm;
