import React, { Component } from 'react';
import { ScrollView,View, StyleSheet } from 'react-native';
import MenuHeader from "./menuheader.js"
import MenuStatistic from "./menustatistic.js"
import MenuContent from "./menucontent.js"


class SideMenu extends Component {

  closeMenuHandler = () => {
      this.props.cbCloseMenu();

  }


  render () {
    return (
      <ScrollView style={[styles.sidemenu,{width : this.props.fullWidth, height: window.innerHeight}]}>
        <View style={[styles.sidemenu,{width : this.props.fullWidth}]}>
        <MenuHeader style={{width : this.props.fullWidth}} cbCloseMenu={this.closeMenuHandler} user={this.props.user}
          avatar_url={this.props.user.hasOwnProperty('avatars') ? this.props.user.avatars[4].url : ''}
          debug_url={this.props.debug_url}
          userName={this.props.user.real_name === '' ? this.props.user.username : this.props.user.real_name}
          onAvatarPress={this.props.openImagePicker}/>
        <MenuStatistic style={{width : this.props.fullWidth}}/>
        <MenuContent style={{width : this.props.fullWidth}}
          logOut={this.props.logOut} closeTab={this.closeMenuHandler}/>
        </View>
      </ScrollView>
    )
  }
}

const styles = StyleSheet.create({
  sidemenu :{
    right : 0,
    top : 0,
    bottom : 0,
    position : 'absolute',
    shadowColor: '#A4ACB3',
    shadowOffset: { height: 3, width: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 5,
  },
  sidemenu_inner :{
    justifyContent: 'flex-start',
    alignItems : 'center',
    flexDirection : 'column',
  }
})

export default SideMenu;
