import React from 'react'
import { View, ScrollView, Text, StyleSheet } from 'react-native'
import ButtonBorderArrow from "./ButtonBorderArrow"
import Button from "./Button"
import moment from 'moment';
import 'moment/locale/ru'
import { TouchableOpacity } from 'react-native-web';

const Slide = (props: any) => {

    const { title, mobile, width, date, url, history } = props;

  
    return (
      <View style={[styles.slide, {width: width}]}>
        <View style={{width: '100%', justifyContent : 'flex-start', alignItems: 'flex-start'}}>
        <Text style={[styles.slideText,{alignSelf : mobile ? 'flex-start' : 'center', textAlign : mobile ? 'left' : 'center'}]}>
          {title}
        </Text>
        <Text style={[styles.slideDateText,{alignSelf : mobile ? 'flex-start' : 'center', textAlign : mobile ? 'left' : 'center'}]}>
          [{moment(date).format('LL')}]
        </Text>
        {mobile ? 
        <ButtonBorderArrow title={'читать'} onPress={() => {history.push(url)}} style={{alignSelf : 'flex-end'}}/>
        :
        <Button title={'читать'} theme={'dark'} onPress={() => {history.push(url)}} style={{alignSelf : 'center'}}/>
        }
       </View>
      </View>
    );
  }


export const Carousel = (props: any) => {
  const { items, style, history, mobile } = props;
  const itemsPerInterval = props.itemsPerInterval === undefined
    ? 1
    : props.itemsPerInterval;

  const [interval, setInterval] = React.useState(1);
  const [scrollRef, setscrollRef] = React.useState(1);
  const [intervals, setIntervals] = React.useState(1);
  const [width, setWidth] = React.useState(0);

  const init = (width: number) => {
    // initialise width
    setWidth(width);
    // initialise total intervals
    const totalItems = items.length;
    setIntervals(Math.ceil(totalItems / itemsPerInterval));
  }

  const getInterval = (offset: any) => {
    for (let i = 1; i <= intervals; i++) {
      if (offset < (width / intervals) * i) {
        return i;
      }
      if (i == intervals) {
        return i;
      }
    }
  }
/*
      <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" key={i} style={{margin: 5}}>
        <path d="M9.66664 5C9.66664 7.48267 7.6421 9.5 5.13886 9.5C2.63562 9.5 0.611084 7.48267 0.611084 5C0.611084 2.51733 2.63562 0.5 5.13886 0.5C7.6421 0.5 9.66664 2.51733 9.66664 5Z" stroke="white" stroke-opacity={interval === i ? 0.4 : 0.1}/>
        </svg>
*/
  let bullets = [];
  for (let i = 1; i <= intervals; i++) {
    bullets.push(
      <TouchableOpacity onPress={() => scrollRef.scrollTo({x:((i-1)*width/intervals),y:0,animated:true})}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginHorizontal: 3}}>
        <circle cx="12" cy="12" r="7" stroke="white" stroke-opacity={interval === i ? 0.9 : 0.3} strokeWidth={2}/>
        </svg>
      </TouchableOpacity>
    );
  }

  return (
    <View style={[styles.container,{width : style.width}]}>
      <ScrollView
        horizontal={true}
        contentContainerStyle={{ ...styles.scrollView, width: `${100 * intervals}%` }}
        showsHorizontalScrollIndicator={false}
        onContentSizeChange={(w, h) => init(w)}
        onScroll={data => {
          setWidth(data.nativeEvent.contentSize.width);
          setInterval(getInterval(data.nativeEvent.contentOffset.x));
        }}
        ref={ref => setscrollRef(ref)}
        scrollEventThrottle={200}
        pagingEnabled
        decelerationRate="fast"
      >
        {items.map((item: any, index: number) => {
          switch (style) {
  /*          case 'stats':
              return (
                <Text
                  key={index}
                  label={item.label}
                  value={item.value}
                />
                asdfasdf
                </Text>
              );*/
            default:
              return (
                <Slide
                  key={index}
                  title={item.title}
                  date={item.date}
                  url={item.url}
                  history={history}
                  width={style.width}
                  mobile={mobile}
                />
              );
          }
        })}
      </ScrollView>
      <View style={styles.bullets}>
        {bullets}
      </View>
    </View>
  )
}


export const styles = StyleSheet.create({
    statsHead: {
      paddingTop: 10,
      paddingHorizontal: 12,
    },
    container: {
      width: '100%',
      marginVertical: 10,

    },
    scrollView: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    bullets: {
      alignSelf : 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      paddingHorizontal: 10,
      paddingTop: 5,
      fontSize: 16,
      color : 'white'
    },
    bullet: {
      paddingHorizontal: 5,
      fontSize: 20,
      color : 'white'
    },
    slide: {
        paddingLeft: 20,
        paddingBottom: 10,
        paddingTop: 30,
        flexBasis: '100%',
        flex: 1,
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        height: 200,
      },
      slideText: {
        width: '100%',
        textAlign: 'left',
        fontSize: 20,
        color : 'white',
        fontFamily: 'Russo One',
        fontStyle: 'normal',
        fontSize: 20,
        lineHeight: 23,
        letterSpacing: 1,
      },
      slideDateText: {
        width: '100%',
        textAlign: 'left',
        fontSize: 20,
        color : 'white',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontSize: 10,
        marginTop: 8,
        marginBottom: 8,
        lineHeight: 23,
        letterSpacing: 1,
      },
  });
  

export default Carousel;