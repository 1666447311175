import React, {Component} from 'react';
import FavouritsMenu from "../../components/matrix/favouritsmenu.js"
import Modal from 'modal-react-native-web';


class FavouritsContainer extends Component {


  render () {
    return (
      <Modal style={{width : '100%'}} visible={this.props.visible}
        transparent={false} ariaHideApp={false} animationType={'fade'}>
      {this.props.visible ? <FavouritsMenu {...this.props} cbClose={this.props.cbClose}/> : null}
      </Modal>
    )
  }

}

export default FavouritsContainer;
