import React, {Component} from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import ButtonArrow from "../../common/ButtonArrow.js"
import Ptext from "../../common/Ptext.js"
import BlogCarusel from "../../common/blogCarusel.js"
import globalStyle from "../../styles/globalStyle.js"
import Carousel from "../../common/carusel.js"


class BlogViewPage extends Component {

  

  render () {

    return (
      <View style={[styles.page,{width: '100%', alignSelf : 'center'}]} >
        <TouchableOpacity style={[styles.header_wrapper]} onPress={() => this.props.history.push('/blog/list/')}>
          <View style={[styles.bar,{width : this.props.fullWidth > 510 ? 120 : 24}]}/>
          <Ptext style={styles.header_caption}> Блог</Ptext>
        </TouchableOpacity>
        <Carousel items={this.props.posts} style={{width: this.props.fullWidth > 510 ? 510 : this.props.fullWidth}}
          mobile={this.props.fullWidth > 510 ? false : true}
          history={this.props.history}
        />
      </View>
    );
  }
}
//
const styles = StyleSheet.create({
  page : {
    flexDirection : 'column',
    alignItems : 'center',
    alignContent: 'flex-start',
    justifyContent : 'flex-start',
    backgroundImage: 'linear-gradient(134.89deg, #5971CD -1.94%, #B35DDF 90.65%)',
  },

  contentContainer:{
    flex: 1,
    alignSelf: 'center',
    alignItems:'center',
    justifyContent: 'flex-start',
  },
  bar : {
    width : 24,
    height : 1,
    backgroundColor : 'white',
  },
  header_wrapper : {
    width : '100%',
    alignSelf : 'flex-start',
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
    height: 85,
  },
  header_caption : {
    letterSpacing: 2,
    textTransform: 'uppercase',
    fontSize : 18,
    lineHeight : 20,
    fontStyle : 'normal',
    fontWeight : 'normal',
    color: 'white',
  }
})

export default BlogViewPage;
