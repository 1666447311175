import React from 'react'
import { StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native';

export default function MenuIcon({ style = {}, notify = false, onPress = null  })
{
  return (
    <TouchableOpacity style={[styles.icon, style]} onPress={onPress}>

      <svg width="36" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.25 13.2501H3.75C3.336 13.2501 3 12.9141 3 12.5001C3 12.0861 3.336 11.7501 3.75 11.7501H20.25C20.664 11.7501 21 12.0861 21 12.5001C21 12.9141 20.664 13.2501 20.25 13.2501Z" fill="black" fill-opacity="0.3"/>
      <path d="M20.25 7.5H3.75C3.336 7.5 3 7.164 3 6.75C3 6.336 3.336 6 3.75 6H20.25C20.664 6 21 6.336 21 6.75C21 7.164 20.664 7.5 20.25 7.5Z" fill="black" fill-opacity="0.3"/>
      <path d="M20.25 19H3.75C3.336 19 3 18.664 3 18.25C3 17.836 3.336 17.5 3.75 17.5H20.25C20.664 17.5 21 17.836 21 18.25C21 18.664 20.664 19 20.25 19Z" fill="black" fill-opacity="0.3"/>
      {notify ? <circle cx="25" cy="3" r="3" stroke="#45B4E3" fill="#45B4E3"/> : null}
      </svg>
  </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  icon: {
    alignItems: 'center',
    justifyContent : 'center',
    backgroundColor : '#fff',
    marginLeft: 16,
    marginRight: 16,
  }
})
