import React, { Component } from 'react';
import { Image,View, StyleSheet, TouchableOpacity } from 'react-native';
import Ptext from "../../common/Ptext.js"
import Htext from "../../common/Htext.js"
import globalStyle from '../../styles/globalStyle.js';

/*

          <View style={{width:65,height:24}}>
            <Ptext style={styles.rectText} fontSize={12}>
              Эксперт
            </Ptext>
          </View>

*/

class MenuHeader extends Component {


  render () {
    return (
      <View style={[styles.headerMenu,this.props.style]}>
        <View style={styles.headerAvatar}>
          <TouchableOpacity onPress={this.props.onAvatarPress}>
            <Image source={this.props.avatar_url === '' ? this.props.avatar_url : this.props.debug_url+this.props.avatar_url} style={{width:64,height:64}}/>
          </TouchableOpacity>
        </View>
        <View style={styles.headerInfo}>
          <View style={{flexDirection : 'row',flex : 1,flexGrow : 1,flexShrink:1,minWidth:180, justifyContent : 'flex-start', alignItems : 'flex-start'}}>
            <Htext style={styles.headerInfoHText}>
              {this.props.hasOwnProperty('userName') && this.props.userName !== undefined ?
                this.props.userName
                :
                'Имя не задано'
              }
            </Htext>
          </View>
          <Ptext style={styles.textAboutDays}>
            Нумеролог {this.props.user.userdays} дня
          </Ptext>
        </View>
        <View style={styles.headerClose}>
          <TouchableOpacity onPress={this.props.cbCloseMenu} >
            {this.props.editMode ?
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4619 8.02218L15.903 10.4632L9.72394 16.6423L7.28425 14.2012L13.4619 8.02218ZM17.7553 7.43346L16.6666 6.34483C16.2459 5.92412 15.5628 5.92412 15.1406 6.34483L14.0978 7.38763L16.5389 9.8287L17.7553 8.61234C18.0816 8.28601 18.0816 7.75977 17.7553 7.43346ZM6.00679 17.6323C5.96237 17.8322 6.14288 18.0114 6.34283 17.9628L9.063 17.3032L6.62331 14.8622L6.00679 17.6323Z" fill="black"/>
              </svg>

              :
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
              <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
              <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
            </svg>
            }
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  headerMenu : {
    flexDirection : 'row',
    backgroundColor: '#fff',
    alignItems : 'flex-start',
    alignContent: 'center',
    justifyContent : 'flex-start',
    width : '100%',
    minHeight : 132,
    flex : 1,
  },
  headerAvatar : {
    width : '28%',
    paddingLeft : 16,
    paddingTop : 24
  },
  headerInfo : {
    height : 108,
    justifyContent : 'flex-start',
    alignContent : 'flex-start',
    alignItems : 'flex-start',
    flexDirection : 'column',
    flexGrow : 1,
    paddingTop : 24,
    paddingLeft : 5,
  },
  headerInfoHText : {
    fontSize : 20,
    flexWrap : 'wrap',
    flex : 1,
  },
  headerClose : {
    width : '19%',
    alignItems : 'flex-end',
    paddingRight: 24,
    paddingTop : 24
  },
  rectText : {
    borderRadius : 30,
    backgroundColor: '#DC6AE6',
    color : 'white',
  },
  textAboutDays : {
    color : globalStyle.colors.secondary_light,
  }

})


export default MenuHeader;
