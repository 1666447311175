import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import Ptext from './Ptext.js';
import globalStyle from '../styles/globalStyle';

class CheckBox extends Component {
  onPress = () => {
    if (this.props.onCheck)
      this.props.onCheck(!this.props.checked);
  }


  render () {
    return (
        <TouchableOpacity
                onPress={this.onPress}
                style={[styles.touchOpacity,this.props.style]}
                disabled={this.props.hasOwnProperty('disabled') ? this.props.disabled : false}
        >
           <View style={[styles.outCheckBoxRectange, {borderColor : this.props.disabled ? globalStyle.colors.secondary_disabled : globalStyle.colors.primary}]}>
              <View style={[styles.innerCheckBoxRectange, {backgroundColor : this.props.checked ? this.props.disabled ? globalStyle.colors.secondary_disabled : globalStyle.colors.primary : '#fff'}]}>

              </View>
            </View>
          <Ptext style={[styles.title,this.props.labelStyle]}>{this.props.label}</Ptext>
        </TouchableOpacity>
    )
   }
};

const styles = StyleSheet.create({
  button : {

  },
  title : {
    marginLeft : 13,
  },
  touchOpacity : {
    minHeight : 32,
    minWidth : 32,
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
  },
  outCheckBoxRectange : {
    borderWidth : 2,
    borderRadius : 1,
    width : 24,
    height : 24,
    flexDirection : 'column',
    justifyContent : 'center',
    alignItems : 'center',
  },
  innerCheckBoxRectange : {
    flexDirection : 'column',
    justifyContent : 'center',
    alignItems : 'center',
    borderRadius : 1,
    width : 10,
    height : 10,
  },
});

export default CheckBox;
