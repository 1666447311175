import React,{Component} from 'react';
import { View, StyleSheet } from 'react-native';
import Ptext from "../../common/Ptext.js"
import { NavLink } from '../router/react-router';

/*
          <Ptext style={styles.descTosInfo}> Если у Вас возникли вопросы, и Вы хотите, вы можете воспользоваться <NavLink to="/services/contact/-1">формой обратной связи</NavLink></Ptext>

*/
class IndexFooter extends Component {
  render () {
    return (

        <View style={styles.wrapper}>
          <Ptext style={styles.descTosInfo}> Используя данный сайт, вы соглашаетесь с <NavLink to="/tos/44">Правилами пользования сайтом.</NavLink></Ptext>
          <Ptext style={styles.descLawInfo}> ИП Перцева Ольга Михайловна   ОГРНИП 319619600116191</Ptext>
          <Ptext style={styles.descrLabel}> Любое использование либо копирование материалов или подборок материалов сайта,
            элементов дизайна и оформления допускается лишь с разрешения правообладателя и только со ссылкой на источник: nenumerolog.ru</Ptext>
        </View>


    )
  }


}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    flexWrap : 'wrap',
    alignSelf : 'center',
    alignItems : 'center',
    justifyContent : 'center',
    width : '100%',
    backgroundColor: '#F1F3F5',
    paddingBottom : 16,
    borderRadius : 4,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowColor: '#000',
    marginTop : 16,
    padding : 16,
    shadowOffset: { height: 1, width: 0 },
  },
  headerLabel : {
    fontSize : 20,
    fontWeight : 'bold',
    lineHeight : 24,
    textAlign : 'left'
  },
  descLawInfo : {
    paddingTop : 4,
    paddingBottom : 4,
    width : '100%',
    lineHeight : 18,
    fontSize : 14,
    fontWeight : 'bold',
    textAlign : 'left',
    color : '#B4BBC2',
  },
  descrLabel : {
    paddingTop : 4,
    paddingBottom : 4,
    width : '100%',
    lineHeight : 18,
    fontSize : 14,
    textAlign : 'left',
    color : '#B4BBC2',
  },
  descTosInfo : {
    paddingTop : 4,
    paddingBottom : 4,
    width : '100%',
    lineHeight : 18,
    fontSize : 14,
    textAlign : 'left',
    color : '#B4BBC2',
  }

});

export default IndexFooter;
