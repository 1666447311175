import React, {Component} from 'react';
import Modal from 'modal-react-native-web';


class ImagePickerModal extends Component {


  render () {
    return (
      <Modal style={{width : '100%'}} visible={this.props.visible}
        transparent={false} ariaHideApp={false} animationType={'fade'}>
      </Modal>
    )
  }
}

export default ImagePickerModal;
