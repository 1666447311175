import React, { Component } from 'react';
import {View, StyleSheet, TouchableOpacity, TextInput, Picker} from 'react-native';
import Modal from 'modal-react-native-web';
import Ptext from '../../../common/Ptext.js';
import Button from '../../../common/Button.js';





class CreateTicketPromoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value : undefined,
      form : {
        codes_cnt : 1,
        is_percent : false,
        elapsed_count : 1,
        discount : 0,
        event : this.props.eventId,

      }
    }
  }

  _formCb = (data, status) => {
    console.log(data);
    console.log(status);
    if (!status) {

      this.setState({error_desc : 'Ошибка'});
      setTimeout(() => {this.setState({error_desc: undefined})}, 4000);
    }else {
      //this.props.cbtoform();
      if (!data.hasOwnProperty('detail'))
        this.setState({done_desc : 'Сохранено'});
      else {
        this.setState({done_desc : data.detail});
      }
      //setTimeout(() => {this.setState({done_desc: undefined}); this.props.close();}, 4000);

    }

  }

  _submitForm = () => {
    let form = this.state.form;
    form.event = this.props.eventId;
    console.log(form);
    this.props.numer.do_get_create_ticket_promo({ form : form }, this._formCb);

  }

  _inputCodeCount = (value) => {
    let ppp = value.replace(/[^\d]/g, '');
    let form = this.state.form;
    form.codes_cnt = parseInt(ppp,10);
    if (isNaN(form.codes_cnt))
      form.codes_cnt = '';
    this.setState({ form : form });
  }

  _inputDiscount = (value) => {
    let ppp = value.replace(/[^\d]/g, '');
    let form = this.state.form;
    form.discount = parseInt(ppp,10);
    if (isNaN(form.discount))
      form.discount = '';
    this.setState({ form : form });
  }

  _inputElapsedCount = (value) => {
    let ppp = value.replace(/[^\d]/g, '');
    let form = this.state.form;
    form.elapsed_count = parseInt(ppp,10);
    if (isNaN(form.elapsed_count))
      form.elapsed_count = '';
    this.setState({ form : form });
  }

  render () {
    return (
      <Modal visible={this.props.visible}
        transparent={false} ariaHideApp={false} style={styles.modal} animationType={'fade'}>
        <View style={styles.header}>
          <TouchableOpacity style={styles.headerChevron} onPress={this.props.close}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4 7.4L14 6L8 12L14 18L15.4 16.6L10.8 12L15.4 7.4Z" fill="black"/>
            </svg>
            <Ptext style={styles.headerCaption}>
              Создание кодов/скидок
            </Ptext>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.props.close} style={styles.closeCross}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
            <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
            </svg>
          </TouchableOpacity>
        </View>
        <View style={styles.wholeForm}>
          {this.state.error_desc ?
          <Ptext style={styles.error}>
            {this.state.error_desc}
          </Ptext>
          :
          null }
          {this.state.done_desc ?
          <Ptext style={styles.done_desc}>
            {this.state.done_desc}
          </Ptext>
          :
          null }

          <Ptext style={styles.mainHint}>
            В процессе создания, необходимо указать количество кодов и тип скидок, сколько будет активен код( количество раз)
          </Ptext>

          <View style={styles.formItem} >
              <Ptext style={styles.itemHint}>
                Количество кодов
              </Ptext>
              <TextInput value={this.state.form.codes_cnt} placeholder={'Количество кодов'} style={styles.itemTextInput}
                    multiline={false}
                    secureTextEntry={false}
                    onChangeText={this._inputCodeCount}/>
          </View>
          <View style={styles.formItem} >
            <Ptext style={styles.itemHint}>
              Тип скидки
            </Ptext>
            <Picker selectedValue={this.state.form.is_percent}
                    style={[styles.textInput,{borderColor : 'black',
                    borderWidth : 1, borderRadius : 4}]}
                    onValueChange={(itemValue, itemIndex) => {
                      let form = this.state.form;
                      form.is_percent = (itemValue === 'true');
                    this.setState({form: form}) }}>
              <Picker.Item label={'Процентах'} value={true} />
              <Picker.Item label={'В рублях'} value={false}/>
            </Picker>
          </View>
          <View style={styles.formItem} >
            <Ptext style={styles.itemHint}>
              Объем скидки
            </Ptext>
            <TextInput value={this.state.form.discount} placeholder={'Объем скидки'} style={styles.itemTextInput}
                  multiline={false}
                  secureTextEntry={false}
                  onChangeText={this._inputDiscount}/>

          </View>
          <View style={styles.formItem} >
            <Ptext style={styles.itemHint}>
              Сколько раз действует код
            </Ptext>
            <TextInput value={this.state.form.elapsed_count} placeholder={'Сколько раз действует код'} style={styles.itemTextInput}
                  multiline={false}
                  secureTextEntry={false}
                  onChangeText={this._inputElapsedCount}/>
          </View>



          <Button title={'Создать коды'} style={styles.button}
            onPress={this._submitForm}/>
        </View>
      </Modal>

    )
  }

}

const styles=StyleSheet.create({
  modal : {
    width : '100%',
    height : '100%',
  },
  header : {
    width : '100%',
    minHeight : 65,
    justifyContent : 'flex-start',
    alignItems : 'center',
    alignSelf : 'center',
    flexDirection : 'row',
  },
  headerChevron : {
        marginLeft : 10,
        flexDirection : 'row',
        alignSelf : 'center',
        justifyContent : 'flex-start',
        alignItems : 'center',
        flex : 2,
  },
  headerCaption : {
    flex : 2,
    fontWeight : 'bold',
    fontSize : 20,
    lineHeight : 24,
    textAlign : 'left',
  },

  closeCross : {
    marginRight : 10,
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
  },
  backChevron : {
    marginLeft : 10,
  },
  wholeForm : {
    width : '90%',
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    alignSelf : 'center',
  },
  mainHint : {
    width  : '100%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
  },
  error : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : 'red',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  done_desc : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : '#90ee90',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  formItem : {
    width : '100%',
    flexDirection : 'column',
    alignSelf : 'flex-start',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  itemHint : {
    fontSize : 12,
    lineHeight : 16,
    marginBottom : 8,
  },
  itemSubHint : {
    fontSize : 12,
    lineHeight : 16,
    color : '#B4BBC2',
    textAlign : 'left',
    marginBottom : 20,
  },
  errorHint : {
    fontSize : 12,
    lineHeight : 16,
    fontWeight : 'italic',
    color : 'red',
    textAlign : 'left',
    marginBottom : 20,
  },
  itemTextInput : {
    borderWidth : 1,
    height : 40,
    minHeight : 40,
    borderRadius : 4,
    flex : 1,
    margin : 4,
    paddingLeft : 8,
    width : '98%',
    marginBottom : 20,
    borderColor : '#000',
  },
  button : {
    width : '100%',
  }

});


export default CreateTicketPromoModal;
