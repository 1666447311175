import React,{Component} from 'react';
import {View, StyleSheet,ScrollView,
    TextInput,TouchableOpacity,
     ImageBackground } from 'react-native';
import Ptext from "../../../common/Ptext.js"
import Button from "../../../common/Button.js"
import CreateChatView from "./createChatView.js"

class CreateGroupChatView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      public : true,
      name : '',
      descr : '',
      users : '',
      selection : false,
      privUsers : (new Map() : Map<string,boolean>),
      privUsersNames : (new Map() : Map<string,string>),
    };

  }

  componentDidMount (){
    this.setState({fullHeight : window.innerHeight});
  }

  _onPressItem = (index : int) => {

  }

  _createChat = () => {
    let data = {};

    if (this.state.public) {

      data.attachments = [];
      data.category = this.props.category;
      data.close = false;
      data.hide = false;
      data.pin = 0;
      data.post = this.state.descr;
      data.title = this.state.name;
      this.props.misago.create_public(data,this._createDone);
    }else {

      data.attachments = [];
      data.post = this.state.descr;
      data.title=this.state.name;
      data.to = [];
      for (let i of this.state.privUsersNames.values())
        if (i !== "")
          data.to.push(i);
      this.props.misago.create_private(data,this._createDone);
    }
  }

  _createDone = (data,status) => {
    if (status) {
        this.setState({done_desc : 'Чат создан'});
        setTimeout(() => {this.setState({done_desc : undefined}); this.props.fullclose();},2000);

        this.props.cbCreated(data,status);

    }else {
      //console.log(data);
      let ii;
      let message = '';
      console.log(data);

      for (ii in data) {
        if ((ii === 'status')||(ii === 'statusText'))
          continue;
        if (ii === 'title') {
          if (data[ii][0].includes('Заголовок темы'))
            message += data[ii][0].replace('Заголовок темы должен','Название чата должно')+'\n';
          else
            message += 'Название чата не может быть пустым\n';
        }
        if (ii === 'post') {
          message += 'Описание чата не может быть пустым\n';
        }
        if (ii === 'to') {
          if (data[ii][0].includes('Вы должны ввести'))
              message += 'Укажите пользователей закрытого чата\n';
          else
            message += data[ii][0].replace('скрытую тему','приватный чат')+'\n';
        }

      }
      this.setState({error_desc : message});
      setTimeout(() => {this.setState({error_desc : undefined})},3000);
    }

  }
  _selectPublic = () => {
    this.setState({public : true});
  }
  _selectPrivate = () => {
    this.setState({public : false});
  }

  _openSelectUser = () => {
    this.setState({selection : true});
  }

  _closeSelectUser = () => {
    this.setState({selection : false});
  }

  _setSelection = (index, name) => {
    this.setState((state)=> {
        const selected = this.state.privUsers;
        selected.set(index,!selected.get(index));
        const names = this.state.privUsersNames;
        names.set(index,selected.get(index) ? name : '');
        return {selected,names};
    });

  }

  render () {
    let w_val = this.props.fullWidth;
    if (this.props.fullWidth > 539)
      w_val = 370;
    if (this.props.fullWidth > 1023)
      w_val = 512;
    return (
      <View style={styles.wrapper}>
      {!this.state.selection ?
      <ScrollView style={styles.wrapper} contentContainerStyle={styles.wrapperChildStyles}>
        <View style={[styles.wrapper,{width : w_val, alignSelf : 'center', justifyContent : 'flex-start',alignItems : 'center'}]}>
          {/* New group chat caption and chevron left(back) */}
          <View style={styles.horizontalWrapper}>
            <TouchableOpacity onPress={this.props.closeCreateChat}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M15.4 7.4L14 6L8 12L14 18L15.4 16.6L10.8 12L15.4 7.4Z" fill="black"/>
              </svg>
            </TouchableOpacity>
            <Ptext style={styles.createChatHeader}>
              Новый групповой чат
            </Ptext>
          </View>
          {this.state.error_desc ?
          <Ptext style={styles.error}>
            {this.state.error_desc}
          </Ptext>
          :
          null }
          {this.state.done_desc ?
          <Ptext style={styles.done_desc}>
            {this.state.done_desc}
          </Ptext>
          :
          null }

          {/* Create group chat image */}
          {/* Input group name block */}
          <View style={styles.verticalWrapper}>
            <View style={styles.imageSelector}>
                <ImageBackground source={require('../../../icons/image001.png')} style={{width:'128',height:'128'}}>
                  <TouchableOpacity style={[styles.imageSelector,{clip : 'circle(64)'}]}>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8">
                    <path d="M5.33341 6.66667H9.33341L12.0001 4H20.0001L22.6667 6.66667H26.6667C27.374 6.66667 28.0523 6.94762 28.5524 7.44772C29.0525 7.94781 29.3334 8.62609 29.3334 9.33333V25.3333C29.3334 26.0406 29.0525 26.7189 28.5524 27.219C28.0523 27.719 27.374 28 26.6667 28H5.33341C4.62617 28 3.94789 27.719 3.4478 27.219C2.9477 26.7189 2.66675 26.0406 2.66675 25.3333V9.33333C2.66675 8.62609 2.9477 7.94781 3.4478 7.44772C3.94789 6.94762 4.62617 6.66667 5.33341 6.66667V6.66667ZM16.0001 10.6667C14.232 10.6667 12.5363 11.369 11.286 12.6193C10.0358 13.8695 9.33341 15.5652 9.33341 17.3333C9.33341 19.1014 10.0358 20.7971 11.286 22.0474C12.5363 23.2976 14.232 24 16.0001 24C17.7682 24 19.4639 23.2976 20.7141 22.0474C21.9644 20.7971 22.6667 19.1014 22.6667 17.3333C22.6667 15.5652 21.9644 13.8695 20.7141 12.6193C19.4639 11.369 17.7682 10.6667 16.0001 10.6667V10.6667ZM16.0001 13.3333C17.0609 13.3333 18.0784 13.7548 18.8285 14.5049C19.5787 15.2551 20.0001 16.2725 20.0001 17.3333C20.0001 18.3942 19.5787 19.4116 18.8285 20.1618C18.0784 20.9119 17.0609 21.3333 16.0001 21.3333C14.9392 21.3333 13.9218 20.9119 13.1717 20.1618C12.4215 19.4116 12.0001 18.3942 12.0001 17.3333C12.0001 16.2725 12.4215 15.2551 13.1717 14.5049C13.9218 13.7548 14.9392 13.3333 16.0001 13.3333V13.3333Z" fill="white"/>
                    </g>
                  </svg>
                </TouchableOpacity>
                </ImageBackground>
            </View>

            <Ptext>Наименование группы</Ptext>
            <TextInput style={styles.textInput}
              placeholder={'Введите имя группы'}
              value={this.state.name}
              onChangeText={(value) => this.setState({name : value})}
            />
          {/* Input group name block */}
            <Ptext>Описание</Ptext>
            <TextInput style={[styles.textInput,{height : 88}]}
                  multiline={true}
                  placeholder={'Введите описание группы'}
                  value={this.state.descr}
                  onChangeText={(value) => this.setState({descr : value})}
            />
          {/* Input group name block */}
            <Ptext>Тип группы</Ptext>
            <View style={[styles.horizontalWrapper,{width:'100%', marginBottom : 0,marginTop : 0}]}>
              <Button title={'Публичная'}
                style={[styles.selectorLeftPart,
                  {backgroundColor :
                      this.state.public ?
                        '#FFCB66'
                      : 'rgba(180, 187, 194, 0.2)'}]}
                onPress={this._selectPublic}/>

              <Button title={'Приватная'}
                style={[styles.selectorRightPart,
                  {backgroundColor :
                    !this.state.public ?
                      '#FFCB66'
                    : 'rgba(180, 187, 194, 0.2)'}]}
                onPress={this._selectPrivate}
                    />
            </View>
            <Ptext style={styles.hint}>
              Приватную группу видят только ее участники, публичную - все посетители сайта
            </Ptext>
            {!this.state.public ?
            <Button style={{width : '100%', marginBottom : 20}} onPress={this._openSelectUser}
              title={'Выбрать пользователей ('+this.state.privUsers.size +')'}
              />
              :
              null
            }


            <Button style={{width : '100%'}} onPress={this._createChat}
              title={'Создать группу'}
              />

          </View>

        </View>
      </ScrollView>
      :
      <CreateChatView closeCreateChat={this._closeSelectUser} fullWidth={this.props.fullWidth}
        misago={this.props.misago} fullHeight={window.innerHeight} selection={true} selectedid={this.state.privUsers}
        changeSelection={this._setSelection}/>
      }
      </View>
    )
  }
}

const styles=StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    width : '100%',
    flex : 1,
    backgroundColor : '#F1F3F5',
  },
  wrapperChildStyles : {
    alignItems : 'center',
    justifyContent : 'flex-start',
    alignSelf : 'center',
  },
  verticalWrapper : {
    flexDirection : 'column',
    width : '90%',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
  },
  horizontalWrapper : {
    width : '90%',
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
    marginTop : 14,
    marginBottom : 14,
  },
  createChatCaption : {
    fontSize : 16,
    lineHeight : 24,
    marginLeft : 17,
  },
  createChatHeader : {
    fontWeight : 'bold',
    fontSize : 20,
    lineHeight : 24,
    marginLeft : 17,
  },
  headerFlatList : {
    flexDirection : 'row',
    alignSelf : 'center',
    alignItems : 'center',
    justifyContent : 'flex-start',
    width : '100%',
    borderRadius : 4,
    borderColor : '#B4BBC2',
    borderWidth : 1,
    height : 40,
    padding : 12,
  },
  textInput : {
    backgroundColor : '#fff',
    borderWidth : 1,
    borderColor : '#B4BBC2',
    width : '100%',
    padding : 10,
    height : 40,
    marginBottom : 32,
    borderRadius : 4,
  },
  imageSelector : {
    width : 128,
    height : 128,
    borderColor : '#000',
    justifyContent : 'center',
    alignItems : 'center',
    alignSelf : 'center',
    clip : 'circle(64)'
  },
  hint : {
    textAlign : 'left',
    color : '#B4BBC2',
  },
  selectorLeftPart : {
    flex : 1,
    borderTopRightRadius : 0,
    borderBottomRightRadius : 0,
    borderWidth : 0,
  },
  selectorRightPart : {
    flex : 1,
    borderTopLeftRadius : 0,
    borderBottomLeftRadius : 0,
    borderWidth : 0,
  },
  error : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : 'red',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  done_desc : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : '#90ee90',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
});
export default CreateGroupChatView;
