import React, {Component} from 'react'
import {TouchableOpacity, View, StyleSheet} from 'react-native'
import Chevron from "./chevron"
import Ptext from "./Ptext"
import HTMLView from 'react-native-htmlview';


class PullDownInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }

    _openInfo = () => {
        this.setState({collapsed : true});
    }

    _closeInfo = () => {
        this.setState({collapsed : false});
    }

    _toggle = () => {
        this.setState({collapsed : !this.state.collapsed});
    }


    render () {
        return (
            <View style={[styles.wrapper,{width : this.props.fullWidth}, this.props.style]}>
                <TouchableOpacity style={styles.buttonWrapperStyle} onPress={this._toggle}>
                    {this.props.children}
                    <Ptext style={styles.buttonCaption}>
                        {this.props.header}
                    </Ptext>
                    <Chevron opened={this.state.collapsed}/>
                </TouchableOpacity >
                {
                    this.state.collapsed ? 
                    <View style={styles.infoWrapper}>
                        <HTMLView value={this.props.desc} style={styles.infoText}/>
                    </View>
                    :
                    null
                }
            </View>
        )
    }
};

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        margin: 16, 
        shadowOffset : { width : 0, height : 4},
        shadowRadius : 10,
        shadowOpacity : 1,
        shadowColor : 'rgba(0, 0, 0, 0.05)',
        backgroundColor: '#fff',
        borderRadius: 1,
    },
    buttonWrapperStyle: {
        width: '100%',
        flexDirection : 'row',
        alignItems: 'center',
        padding: 16,
        minHeight: 72,
        justifyContent: 'space-between',

    },
    buttonCaption: {
        flex: 1,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
    },
    infoWrapper: {
        marginTop: 15, 
        marginBottom: 5,
        paddingLeft: 46,
        paddingTop: 15,
        paddingBottom: 15,
        marginRight: 5,
        borderTopWidth: 1,
        borderTopColor: 'rgba(0, 0, 0, 0.05)',
        width: '95%',
        flexDirection : 'column',
    },
    infoText:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'left',
        /* or 143% */


        /* @grey_60 */

        color: 'rgba(0, 0, 0, 0.6)'
    }
});


export default PullDownInfo;