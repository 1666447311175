import React, {Component} from 'react';
import TopMenuComponent from "../../components/topmenu/TopMenuComponent.js"



class MenuContainer extends Component {
  constructor(props) {
    super(props);
    this.state={};
  }



  render () {
    return (
      <TopMenuComponent supp={this.props.supp}
        style={{flex : 1}}
        auth={this.props.auth}
        showMenu={this.props.showMenu}
        cbOpenMenu={this.props.cbOpenProfile}
        cbDimChanged={this.props.cbDimChanged}
        cbOpenMatrix={this.props.cbOpenMatrix}
        cbOpenServices={this.props.cbOpenServices}
        cbOpenBlog={this.props.cbOpenBlog}
        cbShowChats={this.props.cbShowChats}
        active={this.props.active}
        toAuthPage={this.props.cbOpenAuthPage}
        avatar={this.props.avatar}
        avatarPress={this.props.avatarPress}
        cbOpenNavigationMenu={this.props.cbOpenNavigationMenu}
        history={this.props.history}
        cbOpenMainMenu={this.props.cbOpenMainMenu}
        cbCloseMainMenu={this.props.cbCloseMainMenu}
        openedIndex={this.props.openedIndex}
        />

    )
  }

}

export default MenuContainer;
