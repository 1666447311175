import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { Dimensions } from 'react-native';

import Button from "../../common/Button.js"
import ButtonArrow from "../../common/ButtonArrow"

import Ptext from "../../common/Ptext.js"
import FormInput from '../../common/FormInput.js';
import globalStyle from "../../styles/globalStyle"


class CalculateForm extends Component
{

    constructor(props){
      super(props);
      this.state = {
        dims : {}
      };
      this.dims_changed({window : Dimensions.get("window")});
    }

    componentWillMount() {
          Dimensions.addEventListener("change", this.dims_changed);
    }

    componentWillUnmount() {
      // Important to stop updating state after unmount
        Dimensions.removeEventListener("change", this.dims_changed);
    }

    dims_changed = dims => {
      let res = this.props.supp.testRange(this.state.dims.width,dims.window.width);
      if (res.neq) {
          let s = this.state;
          switch (res.range) {
              case 0:
                  s.label = '80%';
                  s.favourits_top = true
              break;
              case 1:
              case 2:
              case 3:
              case 4:
                  s.label = '25%';
                  s.favourits_top = false
                break;
              default:

                    s.favourits_top = false
                break;
          }
          s.dims = dims.window;
          this.setState(s);
      }
    }


    render () {

/*

          {!this.state.favourits_top && showFav ? <View style={{flex : 1}}></View> : null}
          {!this.state.favourits_top && showFav ? <ItemFavouritsIcon style={{width : this.state.favourits}} onPress={this.props.openFav}/> : null }

*/

      return (
        <View style={[styles.page,{width: '100%', alignSelf : 'center', backgroundColor: 'white'}]} >
        <TouchableOpacity style={[styles.header_wrapper,{backgroundColor : 'white'}]} onPress={() => this.props.history.push('/matrix/')}>
          <View style={[styles.bar,{width : this.props.fullWidth > 510 ? 120 : 24}]}/>
          <Ptext style={styles.header_caption}> Расcчитать матрицу</Ptext>
        </TouchableOpacity>
        <View style={[styles.form,this.props.style]}>
          <FormInput onChangeText={this.props.cb.cbDateInput} value={this.props.bidValue} placeholder={'ДД.ММ.ГГГГ'}
                      keyboardType={'phone-pad'}
                      multiline={false}
                      onSubmitEditing={this.props.cb.cbCalcPress}
                      style={{width: 306}}
            />
          <Button title="расcчитать" onPress={this.props.cb.cbCalcPress}/>

        </View>
        </View>

      )
    }
/*


*/
}

const styles = StyleSheet.create({
  form : {
    flexDirection : 'column',
    backgroundColor: '#fff',
    alignItems : 'center',
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent : 'flex-start',
    flexWrap : 'wrap',
    padding : 16,
    width : '100%',
    maxWidth : 555,
  },
  header_wrapper : {
    width : '100%',
    height: 74,
    alignSelf : 'flex-start',
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
  },
  bar : {
    width : 24,
    height : 1,
    backgroundColor : 'black',
  },

  header_caption : {
    letterSpacing: 2,
    textTransform: 'uppercase',
    fontSize : 18,
    lineHeight : 20,
    fontStyle : 'normal',
    fontWeight : 'normal',
  },

  

})
export default CalculateForm
