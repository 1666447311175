import React from 'react'
import { StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native';

export default function MenuIcon({ style = {}, notify = false, onPress = null  })
{
  return (
    <TouchableOpacity style={[styles.icon, style]} onPress={onPress}>

        <svg width="36" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.3029 18.8641L5.63569 7.19683C5.34294 6.90408 5.34294 6.42891 5.63569 6.13617C5.92843 5.84342 6.4036 5.84342 6.69635 6.13617L18.3636 17.8034C18.6564 18.0962 18.6564 18.5713 18.3636 18.8641C18.0709 19.1568 17.5957 19.1568 17.3029 18.8641Z" fill="black" fill-opacity="0.3"/>
        <path d="M18.3637 7.19681L6.69646 18.8641C6.40372 19.1568 5.92854 19.1568 5.6358 18.8641C5.34306 18.5713 5.34306 18.0962 5.6358 17.8034L17.3031 6.13615C17.5958 5.84341 18.071 5.84341 18.3637 6.13615C18.6565 6.42889 18.6565 6.90407 18.3637 7.19681Z" fill="black" fill-opacity="0.3"/>
        </svg>

  </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  icon: {
    alignItems: 'center',
    justifyContent : 'center',
    backgroundColor : '#fff',
    marginLeft: 16,
    marginRight: 16,
  }
})
