import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity} from 'react-native';
import Ptext from "../../common/Ptext.js";
import Htext from "../../common/Htext.js";
import ButtonArrow from "../../common/ButtonArrow.js";
import Button from "../../common/Button.js";

class SuccessOperationView extends Component {

    render () {
        return (
            <View style={styles.mainPageWrapper}>
                <View style={styles.wrapper}>
                        <TouchableOpacity style={styles.headerCloseIcon} onPress={this.props.cbClose}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
                        <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
                        </svg>
                        </TouchableOpacity>
                </View>
                <Htext>Вы вошли</Htext>
                <Ptext>Спасибо, что вернулись</Ptext>
              
                <ButtonArrow title={'перейти на главную'} /*onPress={() => {
                        this.props.cbClose(true);
                        window.location.replace("/");
                        }}*/
                            onPress={this.props.closeToIndex}
                        />
                <Button title={'Закрыть окно'} onPress={this.props.cbClose}/>

            </View>
        )
    }

}

const styles = StyleSheet.create({
    mainPageWrapper : {
      flexDirection : 'column',
      width : '100%',
      maxWidth : 510,
      alignSelf : 'center',
      justifyContent : 'flex-start',
      alignItems : 'center',
      backgroundColor: '#fff',
      paddingBottom: 30,
    },
    wrapper : {
        flexDirection : 'column',
        alignSelf : 'center',
        justifyContent : 'center',
        alignItems : 'center',
        width : '90%',
        maxWidth : 500,
      },
      
      headerCloseIcon : {
        width : '10%',
        flex : 1,
        marginRight : '16',
        marginTop: 16,
        alignSelf : 'flex-end',
      }
  });

export default SuccessOperationView;