import React,{Component} from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Ptext from "../../common/Ptext.js"
import Button from "../../common/Button.js"

class WhatIs extends Component {
  render () {
    return (
        <View style={styles.wrapper}>
          <Image source={require('../../icons/me-img.jpg')} style={styles.imageStyle} resizeMode="cover"/>
          <View style={styles.vertWrapper}>
            <Ptext style={styles.headerLabel}>Что такое нумерология</Ptext>
            <Ptext style={styles.descrLabel}>Это методика, основанная на анализе квадрата Пифагора и доработанная мной, на основании 14 лет исследований. Нумерологический анализ личности позволяет понять:</Ptext>

          </View>
            <Button title="Почему я не могу выйти замуж" style={styles.button}/>
            <Button title="Почему ребенок не слушается" style={styles.button}/>
            <Button title="Почему муж ревнует" style={styles.button}/>
            <Button title="Почему я часто злюсь" style={styles.button}/>
            <Button title="Почему брат пьет" style={styles.button}/>
            <Button title="Где мне нужно работать" style={styles.button}/>
        </View>


    )
  }


}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'row',
    flexWrap : 'wrap',
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    width : '100%',
    backgroundColor: '#fff',
    paddingBottom : 16,
    borderRadius : 4,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowColor: '#000',
    margin : 16,
    shadowOffset: { height: 1, width: 0 },
  },
  vertWrapper : {
    flexDirection : 'column',
    width : '100%',
    padding : 10,
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  imageStyle : {
    flex : 1,
    width : 400,
    height : 400,
    resizeMode : 'cover',
  },
  headerLabel : {
    fontSize : 20,
    fontWeight : 'bold',
    lineHeight : 24,
  },
  descrLabel : {
    width : '90%',
    fontSize : 14,
    textAlign : 'left',
  },
  button : {
    marginLeft : 16,
    backgroundColor : '#F1F3F5',
    borderColor : '#F1F3F5',
  }

});

export default WhatIs;
