import React, { Component } from 'react';
import { ScrollView,View, StyleSheet, TextInput, TouchableOpacity} from 'react-native';
import Ptext from "../../common/Ptext.js";
import Button from "../../common/Button.js";
import SocialRegIcon from "./socialReg.js"



class RegisterForm extends Component {

  onPressClose = () => {
    if (this.props.cbClose)
      this.props.cbClose(this.props.auth);
  }

  render () {
      return (

        <ScrollView style={[styleAuth.wrapper,{height:this.props.scrollHeight}]} contentContainerStyle={styleAuth.wrapperContainer}>
          <View style={styleAuth.horWrapperAuth}>
            <Ptext style={styleAuth.headerCaption}>Регистрация</Ptext>
            <TouchableOpacity style={styleAuth.headerCloseIcon} onPress={this.onPressClose}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
              <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
              </svg>
            </TouchableOpacity>
          </View>
          <Ptext>Логин</Ptext>
          <TextInput placeholder={'Логин'} style={styleAuth.loginInput}
            autoCompleteType={'username'}
            textContentType={'username'}
            onChangeText={this.props.userInput}/>
          <Ptext>E-mail:</Ptext>
          <TextInput placeholder={'E-mail'} style={styleAuth.loginInput}
            autoCompleteType={'email'}
            textContentType={'emailAddress'}
            onChangeText={this.props.emailInput}/>
          <Ptext>Пароль:</Ptext>
          <TextInput placeholder={'Пароль'}
                        style={styleAuth.loginInput}
                        onChangeText={this.props.passInput}
                        autoCompleteType={'password'}
                        textContentType={'password'}
                        secureTextEntry={true}/>
          {
            this.props.question !== undefined && this.props.question.question !== undefined ?
            <Ptext>{this.props.question.question}</Ptext>
            :
            null
          }
          {
            this.props.question !== undefined && this.props.question.question !== undefined ?
            <TextInput placeholder={'Ответ'} style={styleAuth.loginInput}
              onChangeText={this.props.captchaInput}/>
            :
            null
          }
          <Button title={'Регистрация'} style={styleAuth.buttonReg}
            onPress={this.props.registerPress}/>
          <View style={styleAuth.horWrapper}>
          </View>
          <Ptext style={styleAuth.soc_caption}>Зарегистрироваться через социальные сети</Ptext>
          <View style={styleAuth.horWrapperAuth}>
            <SocialRegIcon type={'vk'}/>
            <SocialRegIcon type={'facebook'}/>
            <SocialRegIcon type={'instagramm'}/>
            <SocialRegIcon type={'twitter'}/>
            <SocialRegIcon type={'odnoklass'}/>
          </View>
          <Ptext style={{alignSelf : 'center'}}>Регистрируясь вы соглашаетесь с Политикой конфиденциальностии даете согласие на обработку персональных данных</Ptext>
          <View style={styleAuth.horWrapper}>
          </View>

          <Ptext>Уже регистрировались ранее?</Ptext>

          <Button title={'Войти'} style={styleAuth.buttonToLogin}
            onPress={this.props.toLoginPress}/>

          <View>
            <Ptext>Завлекуха</Ptext>
          </View>
        </ScrollView>

      )

  }

}

const styleAuth = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    alignSelf : 'center',

    width : '90%',
  },
  wrapperContainer : {
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  horWrapper : {
    flexDirection : 'row',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'baseline',
    width : '100%',
    padding : 0,
    borderRadius : 4,
    borderWidth : 1,
    marginTop : 10,
    borderColor : '#B4BBC2',
    marginBottom : 8
  },
  horWrapperAuth : {
    flexDirection : 'row',
    alignSelf : 'center',
    justifyContent : 'space-around',
    alignItems : 'center',
    width : '100%',
    padding : 0,
    marginTop : 10,
    marginBottom : 8
  },
  loginInput : {
    width : '100%',
    borderRadius : 4,
    borderColor : '#B4BBC2',
    height : 40,
    borderWidth : 1,
    paddingLeft : 10,
    marginTop : 10,
    marginBottom : 10,
    alignSelf : 'flex-start'
  },
  passInput : {
    flex : 1,
    borderRadius : 0,
    height : 40,
    paddingLeft : 10,
    alignSelf : 'flex-start'
  },
  loginButton : {
    margin : 0,
    marginTop : 0,
    marginBottom : 0,
    alignSelf : 'flex-end'
  },
  hint : {
    textAlign : 'left',
    alignSelf : 'flex-start',
  },
  soc_caption : {
    alignSelf : 'center',
    marginTop : 26,
  },
  buttonReg : {
    width : '100%',
  },
  headerCaption : {
    fontSize : 20,
    fontWeight : 'bold',
    textAlign : 'left',
    width : '90%',
  },
  headerCloseIcon : {
    width : '10%'
  },
  buttonToLogin : {
    width : '100%',
    backgroundColor : '#f0f1f3',
    borderColor : '#f0f1f3',
  }

});

let hint = '#Оставлять комментарии могут только авторизованные участники.# Войдите в свой аккаунт или зарегистрируйтесь.';

class RegisterPage extends Component {

  render () {
    return (
      <View style={styles.mainPageWrapper}>
        <RegisterForm
         messageHint={hint}
         cbClose={this.props.cbClose}
         emailInput={this.props.emailInput}
         auth={this.props.auth}
         toLoginPress={this.props.toLoginPage}
         userInput={this.props.userInput}
         question={this.props.question}
         passInput={this.props.passInput}
         scrollHeight={this.props.scrollHeight}
         captchaInput={this.props.captchaInput}
         registerPress={this.props.registerPress}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  mainPageWrapper : {
    flexDirection : 'column',
    width : '100%',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'flex-start'
  }
});

export default RegisterPage;
