export class Misago {
  constructor(ajax, url){
    this.ajax = ajax;
    this.debug = url;
    this.user = '/forum/api/auth';
    this.user = this.debug + this.user;
    this.debug_api = this.debug + '/forum/api';
    this.public_chats = this.debug_api + '/threads/';
    this.private_threads = this.debug_api + '/private-threads/';
    this.private_dialogs = this.debug_api+'/private-dialogs/';
    this.private_groups = this.debug_api + '/private-groups/';
  };

  do_fetch_user (cb) {
    this.ajax.get(true,this.user).then(
      (data) => {
          this.auth_data = data;
          cb(data);
      }
    )
    .catch(
      (rejection) => {
        
      }
    )
  }

  do_fetch_public_threads(cbGet) {
    this.ajax.get(true,this.public_chats).then(
      (data)=> {
        cbGet(this.do_make_chats_list(data));
      }
    )
    .catch(
        (rejection) => {
          
        }
    )
  }

  do_fetch_private_threads(cbGet) {
    this.ajax.get(true,this.private_threads).then(
      (data)=> {
        cbGet(this.do_make_chats_list_private(data));
      }
    )
    .catch(
        (rejection) => {
          
        }
    )
  }

  do_fetch_private_dialogs() {
    this.ajax.get(true,this.private_dialogs).then(
      (data)=> {
      }
    )
    .catch(
        (rejection) => {
          
        }
    )
  }

  do_fetch_private_groups() {
    this.ajax.get(true,this.private_groups).then(
      (data)=> {
        console.log(data);
      }
    )
    .catch(
        (rejection) => {
         
        }
    )
  }

  do_make_chats_list(data) {
    let new_data = [];
    for (let i in data.results){
      let res = {};
      let pub = data.results[i];
      res.chatName = pub.title;
      res.avatar_url = require('../icons/test_avatar.png');
      res.lastMessage = pub.last_poster_name + ': ' + pub.last_post_content.replace(/<[^>]*>?/gm, '');
      let dd = new Date(Date.parse(pub.last_post_on));
      res.type = 2;
      res.notify = {
        cnt : 0,
        lastPostDate : ('00'+dd.getHours()).slice(-2) + ':' + ('00'+dd.getMinutes()).slice(-2),
      };
      res.thread_api = pub.api;
      res.thread_id = pub.id;
      new_data.push(res);
    }
    return new_data;
  }
  do_make_chats_list_private(data) {
    let new_data = [];
    for (let i in data.results){
      let res = {};
      let pub = data.results[i];
      res.chatName = pub.title;
      res.avatar_url = require('../icons/test_avatar.png');
      res.lastMessage = pub.last_poster_name + ': ' + pub.last_post_content.replace(/<[^>]*>?/gm, '');
      let dd = new Date(Date.parse(pub.last_post_on));
      res.type = 1;
      res.notify = {
        cnt : 0,
        lastPostDate : ('00'+dd.getHours()).slice(-2) + ':' + ('00'+dd.getMinutes()).slice(-2),
      };
      res.thread_api = pub.api;
      res.thread_id = pub.id;
      new_data.push(res);
    }
    return new_data;
  }
  do_fetch_postlist(cb,url, user, offset) {
    this.ajax.get(true,this.debug+url+'?page='+offset+'&order_by=-id').then(
      (data)=> {

        let new_data = [];
        let d_date = 0;
        let chat_descr = {};
        let prev_poster = '';
        chat_descr.title = data.title;
        chat_descr.avatar_url = '';
        let duplicate = false;
        try {
          for (let i in data.post_set.results) {
            let post = data.post_set.results[i];
            let message = {};
            message.user = post.poster.username;
            if (post.poster.real_name !== '' && post.poster.real_name !== null)
              message.user = post.poster.real_name;
            message.is_online = post.poster.status.is_online;
            message.is_read = post.is_read;
            message.is_new = post.is_new;
            message.date = post.posted_on;
            message.service = false;
            message.avatar_url = this.debug + post.poster.avatars[4].url;
            message.content = post.content.replace(/<[^>]*>?/gm, '');
            message.attach = post.attachments;
            message.video_url = '';
            if (message.content === 'магия видео')
              message.video_url = 'https://player.vimeo.com/video/165693778';

            if (user !== undefined) {
              message.self = post.poster.id === user.id ;
            }else
              message.self = false;

            message.user_id = post.poster.id;
            message.media = null;
            d_date = new Date(Date.parse(post.posted_on));
            message.date = ('00' + d_date.getHours()).slice(-2)+':'+('00' + d_date.getMinutes()).slice(-2);
            message.is_read = post.is_read;
            message.duplicate = false;
            if (prev_poster !== 0)
                duplicate = post.poster.id === prev_poster;
            if (duplicate)
              new_data[new_data.length - 1].duplicate = true;
            prev_poster = post.poster.id;
            new_data.push(message);

          }

        }catch(e) {
          console.log(e);
        }
        cb(new_data, data.post_set.page , data.post_set.pages,chat_descr);
      }
    )
    .catch(
        (rejection) => {
         
        }
    )

  }

  do_post_message(url, message, attachments, cbData) {
    let data_post = {
      post : message,
      attachments : attachments.map((item,index,array) => item.id),
    };
    this.ajax.post(true,this.debug+url,data_post).then(
        (data) => {
          let message = {};
          message.user = data.poster.username;
          message.avatar_url = this.debug + data.poster.avatars[4].url;
          message.content = data.content.replace(/<[^>]*>?/gm, '');
          message.attach = data.attachments;
          message.video_url = '';
          message.self = true ;
          message.media = null;
          let d_date = new Date(Date.parse(data.posted_on));
          message.date = ('00' + d_date.getHours()).slice(-2)+':'+('00' + d_date.getMinutes()).slice(-2);
          message.is_read = data.is_read;
          message.user_id = data.poster.id;
          cbData(message);
        }
    )
    .catch(
      (rejection) => {
        console.log('Failed login returned rejection cause');
        alert(rejection.detail);
      }
    )
  }

  get_avatar(cbGet, user) {
      if (user === undefined || !user.hasOwnProperty('api') || !user.api.hasOwnProperty('avatar'))
        return cbGet('Не авторизован', false);
      this.ajax.get(true,this.debug+user.api.avatar).then(
        (data) => {
          cbGet(data,true);
        }
      )
      .catch(
        (rejection) => {
          cbGet(rejection.detail,false);
        }
      )
  }

  set_avatar_crop(data,user, cbGet, cbUpdateUser) {
      if (user === undefined || !user.hasOwnProperty('api') || !user.api.hasOwnProperty('avatar'))
        return cbGet('Не авторизован', false);
      this.ajax.post(true,this.debug+user.api.avatar, data).then(
        (data) => {
          cbGet(data,true);
          cbUpdateUser();
        }
      )
      .catch(
        (rejection) => {
          cbGet(rejection,false);
        }
      )
  }
  set_avatar(data,user, cbGet, progress) {
      if (user === undefined || !user.hasOwnProperty('api') || !user.api.hasOwnProperty('avatar'))
        return cbGet('Не авторизован', false);
      this.ajax.upload(true,this.debug+user.api.avatar, data, progress).then(
        (data) => {
          cbGet(data,true);
        }
      )
      .catch(
        (rejection) => {
          cbGet(rejection.detail,false);
        }
      )
  }

  upload_attach(data, user, cbGet, progress) {
    if (user === undefined)
      return cbGet('Не авторизован', false);

    this.ajax.upload(true,this.debug+'/forum/api/attachments/', data, progress).then(
        (data) => {
          cbGet(data,true);
        }
      )
      .catch(
        (rejection) => {
          cbGet(rejection.detail,false);
        }
      )

  }

  changeUserPassword (user,form, cb) {

    if (user === undefined || user.hasOwnProperty('is_authenticated') === false || user.is_authenticated === false) {
      cb({detail : 'Вы не вошли в систему'}, false);
      return;
    }

    this.ajax.post(true,this.debug+user.api.change_password,form).then(
      (data) => {
        cb(data,true);
      }
    ).catch(
      (rejection) => {
        cb(rejection, false);
      }
    )
  }

  updateExtUserInfo (info,data, cbUpdate) {
    let ii = '';
    let jj = '';
    console.log(info);
    console.log(data);
    for (ii in data.groups) {
      for (jj in info) {
        if (data.groups[ii].name === info[jj].name) {
          let field_it = 0;
          for (field_it in data.groups[ii].fields) {
            info[jj].fields[field_it].initial = data.groups[ii].fields[field_it].hasOwnProperty('text') ? data.groups[ii].fields[field_it].text : data.groups[ii].fields[field_it].html;
          }
        }
      }
    }
    console.log(info);
    cbUpdate(info);
  }

  changeUserName (user,form,extInfo, cb, cbUpdate, cbUpdateUser) {
    if (user === undefined || user.hasOwnProperty('is_authenticated') === false || user.is_authenticated === false) {
      cb({detail : 'Вы не вошли в систему'}, false);
      return;
    }
    let c_data = {};
    for (let i in extInfo) {
      for (let j in extInfo[i].fields) {
        c_data[extInfo[i].fields[j].fieldname] = extInfo[i].fields[j].initial;
        if (extInfo[i].fields[j].input.hasOwnProperty('choices')) {
          let ch_it = 0;
          for (ch_it in extInfo[i].fields[j].input.choices) {
            if (extInfo[i].fields[j].input.choices[ch_it].label === c_data[extInfo[i].fields[j].fieldname])
              c_data[extInfo[i].fields[j].fieldname] = extInfo[i].fields[j].input.choices[ch_it].value;
          }
        }
      }
    }
    c_data['real_name'] = form[0].value + ' ' + form[1].value;
    this.ajax.post(true,this.debug+user.api.edit_details,c_data,cb)
    .then(
      (data) => {
        this.updateExtUserInfo(extInfo,data,cbUpdate);
        cbUpdateUser();
        cb(data, true);
      }
    )
    .catch(
      (rejection) => {
        cb(rejection, false);
      }
    )


  }

  changeUserBio (user, bio, extInfo, cb, cbUpdate) {
    if (user === undefined || user.hasOwnProperty('is_authenticated') === false || user.is_authenticated === false) {
      cb({detail : 'Вы не вошли в систему'}, false);
      return;
    }
    let c_data = {};
    for (let i in extInfo) {
      for (let j in extInfo[i].fields) {
        c_data[extInfo[i].fields[j].fieldname] = extInfo[i].fields[j].initial;
        if (extInfo[i].fields[j].input.hasOwnProperty('choices')) {
          let ch_it = 0;
          for (ch_it in extInfo[i].fields[j].input.choices) {
            if (extInfo[i].fields[j].input.choices[ch_it].label === c_data[extInfo[i].fields[j].fieldname])
              c_data[extInfo[i].fields[j].fieldname] = extInfo[i].fields[j].input.choices[ch_it].value;
          }
        }
      }
    }
    c_data['bio'] = bio;
    this.ajax.post(true,this.debug+user.api.edit_details,c_data,cb)
    .then(
      (data) => {
        this.updateExtUserInfo(extInfo,data,cbUpdate);
        cb(data, true);
      }
    )
    .catch(
      (rejection) => {
        cb(rejection, false);
      }
    )
  }

  getUserEditDetails( user, cb) {

    if (user === undefined || user.hasOwnProperty('is_authenticated') === false || user.is_authenticated === false) {
      cb(undefined);
      return;
    }

    this.ajax.get(true,this.debug+user.api.edit_details)
    .then(
      (data) => {

        cb(data);
      }
    )
    .catch(
      (rejection) => {
        cb(undefined);
      }
    )
  }

  create_public (data, cb) {
    this.ajax.post(true,this.public_chats,data)
    .then (
      (data) => {
        cb(data,true);
      }
    )
    .catch(
      (rejection) => {
        cb(rejection,false);
      }
    )
  }

  create_private (data, cb) {
    this.ajax.post(true,this.private_threads,data)
    .then (
      (data) => {
        cb(data,true);
      }
    )
    .catch(
      (rejection) => {
        cb(rejection,false);
      }
    )
  }

  do_fetch_user_lists ( cb,offset ) {
    this.ajax.get(true, this.debug + '/forum/api/users/?rank=2&page='+offset)
    .then(
      (data) => {
        cb(data,true);
      }
    )
    .catch(
      (rejection) => {
        cb(rejection,false);
      }
    )
  }

  get_url(url,cb) {
    this.ajax.get(true, url)
    .then(
      (data) => {
        cb(data,true);
      }
    )
    .catch(
      (rejection) => {
        cb(rejection,false);
      }
    )
  }
  changePassword(url,data,cb) {
    this.ajax.post(true,this.user + '/change-password'+url,data)
    .then (
      (data) => {
        cb(data,true);
      }
    )
    .catch(
      (rejection) => {
        cb(rejection,false);
      }
    )
  }

}
