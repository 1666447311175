import React, { Component, Suspense, lazy } from 'react';
import { AppRegistry, StyleSheet,  View, ActivityIndicator } from 'react-native';

import AdaptiveSupport from "./support/adaptive.js"
import MenuContainer from "./containers/menu/menuContainer.js"
import SideProfileContainer from "./containers/profile/sideprofilecontainer.js"
import FavouritsContainer from "./containers/matrix/favouritscontainer.js"
import LoginPageContainer from "./containers/login/loginPageContainer.js"
import RegisterContainer from "./containers/register/registerContainer.js"

import {Ajax} from "./services/ajax.js"
import {Wagtail} from "./support/wagtail.js"
import {Misago} from "./support/misago.js"
import {Numer} from "./support/numer.js"
import CommentsPage from "./components/comments/commentsPage.js"

import CreateChatPageContainer from "./containers/chats/createChat.js"
import ImagePickerModal from "./components/imagepicker/imagepickermodal.js"
import EditForm from "./forms/editform.js"
import ProfileViewOrder from "./components/profile/view/order.js"

//import SchoolPublic from "./components/school/schoolPublic.js"
import BlogModalMenu from "./components/blog/blogModalMenu.js"
import ChangeAvatarModal from "./components/profile/changeAvatarModal.js"


import CreateFlowModal  from "./components/school/modal/createFlowModal.js"
import CreatePromoModal  from "./components/school/modal/createPromoModal.js"

import CreateEventModal  from "./components/events/modal/createEventModal.js"
import CreateTicketPromoModal  from "./components/events/modal/createTicketPromoModal.js"
import IndexPage from "./pages/main/index.js"


import CreateExpertModal from "./components/admin/modal/createExpertModal.js"
import ProfileForm from "./components/profile/profileForm.js"

import ChatUnderContructionPage from "./components/chat/constrPage.js"
import MenuModal from "./components/topmenu/MenuModal"

/*
  Router section
*/ 

import { Router, Route, Switch } from "./components/router/react-router";
import { YMInitializer } from 'react-yandex-metrika';



import './App.css';

//let debug_url = 'https://new.nenumerolog.ru';
let debug_url = '';
//let debug_url = 'http://numerolog.privatebot.info:8080'

const ServiceContainer = lazy(() => import ("./containers/services/serviceContainer.js"));
const MatrixPageContainer = lazy(() => import ("./containers/pages/matrixPageContainer.js"));
const BlogPage = lazy(() => import ("./components/blog/blogPage.js"));
const ChatRouter = lazy(() => import ("./components/chat/chatRouter.js"));
const SchoolContent = lazy(() => import ("./components/school/school.js"));
const EventsContent = lazy(() => import ("./components/events/events.js"));
const ExpertsRouter = lazy(() => import ("./components/admin/expertsRouter.js"));
const ClientsList = lazy(() => import ("./components/admin/clientsList.js"));
const SchoolPublicLoaded  = lazy(() => import ("./components/school/schoolPublicLoaded.js"));
const TicketSaleInfo = lazy(() => import ("./components/events/ticketsSaleInfo.js"));
const TicketSale = lazy(() => import ("./components/events/sale/ticketSale.js"));
const ProfilePage = lazy(() => import ("./components/profile/profilePage.js"));
const PayViewRouter = lazy(() => import ("./components/pay/payViewRouter.js"));
const ExpertClientPay = lazy(() => import ( "./components/pay/expertClientPay.js"));
const  OptionsPage = lazy(() => import ("./components/options/optionsPage.js"));
const ConsultTos = lazy(() => import ( "./pages/tos/consult_tos.js"));
const ForgottenPassword = lazy(() => import ("./components/options/forgottenPassword.js"));
const PhoneAuth = lazy(() => import ("./components/auth/phoneAuth.js"));
const OpSuccessView = lazy(() => import ("./components/successview/opsuccessview.js"));
const OpServiceSuccessView = lazy(() => import ("./components/successview/opsuccessview_service.js"));
const PCSuccessView = lazy(() => import ("./components/successview/pcsuccessview.js"));
const LogOutSuccessView = lazy(() => import ("./components/successview/logoutsuccessview.js"));
const FaqPageContainer = lazy(() => import ("./containers/pages/faqPageContainer.js"));
const ReviewPageContainer = lazy(() => import ("./containers/pages/reviewPageContainer.js"));
const ServiceItemView = lazy(() => import ("./components/services/serviceItemView.js"));
const ServiceAdminMainRouter = lazy(() => import ("./components/services/admin/servicesAdminMainRouter.js"));
const CheckExpertView = lazy(() => import ("./components/expert/checkExpertView"));


class App extends Component {
  adapt_support = new AdaptiveSupport();
  constructor(props) {
    super(props);
    this.state = {

      scrollHeight : window.innerHeight,
//      favs : fav_debug,
      favs : [],
      auth : false,
      hide_menu : false,
      view : {
            showMatrix : false,
            showChat : false,
            showServices : false,
            showBlog : false,
            sideProfileOpened : false,
            matrixFavouritsOpened : false,
            authOpened : false,
            newChatOpened : false,
            registerOpened : false,
            postPage : false,
            showProfile : true,
            imagePicker : false,
            showEnterForm : false,
            showOrderStatus : false,
            blogModalMenu : false,
            changeAvatarModal : false,
            showCreateFlow : false,
            showExpertModal : false,
            showCreatePromo : false,
            showCreateEvent : false,
            showCreateTicketPromo : false,
            showCreateTicketTemplate : false,
            leftNavigationMenuOpened : false,
            mainMenuModalOpened: false,
            showExpertCheck : false,
      },
      services : {
        show : 0,
        key : -1,
        showHint : true,
        selectedMain : false,
        showHeader : true,
        selExpert : 0,
        selectManual : false,
        lastShow : -1,
        askId : -1,
        urgent : false,
        form : {
          comment : '',
          form : [],
        }
      },
      price : {
        kid : 1000,
        adult : 2500,
        family : 4500,
        consult : 2500,
        impr_chld : 1000,
        comp_parent : 1000,
        expr_rate : 1.5,
      },
      schoolForm : {
          first_name : undefined,
          middle_name : undefined,
          last_name : undefined,
          phone : undefined,
          email : undefined,
          instagramm : undefined,
          bid : undefined,
          payed_by : undefined,
          payed_outline : undefined,
          email_ans : undefined,
          email_valid : false,
          accepted_toss : [],
          amount : undefined,
      },

      blogConf : {
        text : true,
        video : true,
        stories : false,
        comments_fav : true,
        comments_like : true,
        comments_reply : true,
        comments_reply_like : true,
      },
      indexPage : {
        bidValue : '',
      },
      user : {
        avatars : [
          {
            url : ''
          },
          {
            url : ''
          },
          {
            url : ''
          },
          {
            url : ''
          },
          {
            url : ''
          },
          {
            url : ''
          },
        ]
      },
      search_text : '',
      fdata_loading : true,
      fdata : {},
      fdata_search : {},
      list_offset : 2000,
      createPromoFlowId : -1,
      createPromoEventId : -1,
      menuOpenedIndex: -1,
    };
    this.ajax = new Ajax();
    this.ajax.init('csrftoken');
    this.wagtail = new Wagtail(this.ajax,debug_url);
    this.misago = new Misago(this.ajax,debug_url);

    this.numer = new Numer(this.ajax,debug_url);

  }

  componentDidMount() {
    

    this.wagtail.debug_fetch(this._debugBlogData);
    this.wagtail.debug_fetch_services(this._debugServiceData);
    this.numer.do_get_faq(this._setfaq);
    this.numer.do_get_review(this._setreview);

  }

  _debugBlogData = (data) => {
    let fd = this.state.fdata;
    if (!fd.hasOwnProperty('links')) {
      fd.links = {};
      fd.results = [];
    }
    fd.links = data.links;
    fd.results = [...fd.results,...data.results];
    this.setState({fdata : fd, fdata_loading : false});
  }

  _setfaq = (data) => {
    this.setState({faq : data[0].faq});
  }

  _setreview = (data) =>{
    this.setState({review : data[0].review});
  }

  _debugBlogSearchData = (data) => {
    let fd = this.state.fdata_search;
    if (!fd.hasOwnProperty('links')) {
      fd.links = {};
      fd.results = [];
    }
    fd.links = data.links;
    fd.results = [...fd.results,...data.results];
    this.setState({fdata_search : fd, fdata_loading : false});
  }
  _formItemsSubmitted = (value) => {
  }

  _formEditItemClose = (formData) => {
    let c = this.state.view;
    c.showEnterForm = false;
    this.setState({view : c,formData : formData});
  }

  _debugServiceData = (data_e, data_o) => {
    this.setState({sdata_e : data_e,sdata_o : data_o});
  }

  debugSearch = (search) => {
    let c = String(search);
    if (c.length > 2)
      this.wagtail.debug_search(this._debugBlogSearchData,search);
    else {
      this.setState({fdata_search : {}})
    }
    this.setState({search_text : search});
  }

  _resetSearch = () => {
    this.setState({fdata_search : {}, search_text : ''})

  }

  sideProfileOpen = () => {
    let c = this.state.view;
    c.sideProfileOpened = true;
    this.setState({view : c});
  }
  sideProfileClose = () => {
    let c = this.state.view;
    c.sideProfileOpened = false;
    this.setState({view : c});
  }


  leftNavigationMenuOpen = () => {
    let c = this.state.view;
    c.leftNavigationMenuOpened = true;
    this.setState({view : c});
  }
  leftNavigationMenuClose = () => {
    let c = this.state.view;
    c.leftNavigationMenuOpened = false;
    this.setState({view : c});
  }
  

  mainMenuModalOpenedOpen = (ops, index) => {
    let c = this.state.view;
    c.mainMenuModalOpened = true;
    this.setState({view : c, menuModalOps: ops, menuOpenedIndex : index});
  }
  mainMenuModalOpenedClose = () => {
    let c = this.state.view;
    c.mainMenuModalOpened = false;
    this.setState({view : c, menuOpenedIndex: -1});
  }

  favouritsCloseMenu = () => {
    let c = this.state.view;
    c.matrixFavouritsOpened = false;
    this.setState({view : c});
  }
  favouritsOpenMenu = () => {
    if (this.state.auth) {
      let c = this.state.view;
      c.matrixFavouritsOpened = true;
      this.setState({view : c});
    }else {
      let c = this.state.view;
      c.authOpened = true;
      this.setState({loginPageMessageHint : 'Для сохранения или использования списка избранных расчётов, войдите в систему',view : c});

      //alert('Войдите в систему для сохранения или просмотра сохранённых расчетов');
    }
  }

  _unshiftFavs = (data) => {
    let s = this.state.favs;
    s.unshift(data);
    this.setState({favs : s});
  }


  favouritsAdd = (obj) => {
    if (this.state.auth)
      this.numer.do_addNew_favs(obj,this._unshiftFavs);
    else {
      let c = this.state.view;
      c.authOpened = true;
      this.setState({loginPageMessageHint : 'Для сохранения или использования списка избранных расчётов, войдите в систему',view : c});
    }
  }

  _handleLoadMore = ( ) => {
    if (this.state.search_text.length < 3) {
        if (this.state.fdata.hasOwnProperty('links')) {
          if (this.state.fdata.links.next !== null ) {
            this.setState({fdata_loading : true});
            this.wagtail.debug_fetch_url(this._debugBlogData,this.state.fdata.links.next);
            return;
          }
        }
    }else {
      if (this.state.fdata_search.hasOwnProperty('links')) {
        if (this.state.fdata_search.links.next !== null ) {
          this.setState({fdata_loading : true});
          this.wagtail.debug_fetch_url(this._debugBlogSearchData,this.state.fdata_search.links.next);
          return;
        }
      }
    }
  }

  favouritsRemove = (index) => {
    let s = this.state.favs;

    this.numer.do_delete_favs(s[index].id);
    s.splice(index,1);
    this.setState({favs : s});
  }
  favouritsOnSelect = (item) => {
    this.setState({ calcItem : item });
  }

/*
  favClose = () => {
    this.setState({favOpenState : false})
  }

  favOpen = () => {
    this.setState({favOpenState : true})
  }
*/
  dimsChanged = (all_size,all_width) => {
    this.setState({scrollHeight : all_size, scrollWidth : all_width});
  }

  openServices = () => {
    let c = this.state.view;
    for (let k in c){
      c[k] = false;
    }
    c['showServices'] = true;
    this.setState({view : c});
  }

  openMatrix = () => {
    let c = this.state.view;
    for (let k in c){
      c[k] = false;
    }
    c['showMatrix'] = true;
    this.setState({view : c});
  }



  openChats = () => {
    let c = this.state.view;
    for (let k in c){
      c[k] = false;
    }
    c['showChat'] = true;
    this.setState({view : c});
  }

  cbOpenAuthPageOpen = () => {
    let c = this.state.view;
    /**/
    c.authOpened = true;
    this.setState({view : c});
  }

  cbOpenAuthPageClose = (result) => {
    let c = this.state.view;
    c.authOpened = false;
    this.setState({view : c, auth : result, loginPageMessageHint : ''});
  }

  openAvatarModal = () => {
    let c = this.state.view;
    c.changeAvatarModal = true;
    this.setState({view : c});
  }

  cbCloseAvatarModal = () => {
    let c = this.state.view;
    c.changeAvatarModal = false;
    this.setState({view : c});
  }



  setUserInfo = (data) => {
    let c = this.state;
    c.user = data;
    c.auth = data.is_authenticated;
    this.setState(c);

    if (c.auth)
      this.numer.do_get_favs_list(this._setFavsList);
  }

  _setFavsList = (favs) => {
    this.setState({favs : favs});
  }

  logOut = () => {
    let c = this.state;
    c.user = {};
    c.auth = false;
    this.setState(c);
  }

  _showCreateChat = (cbCreated) => {
    let c = this.state.view;
    c.newChatOpened = true;
    this.setState({view : c,chat_category : 13, chat_cb : cbCreated});
  }
  _closeCreateChat = () => {
    let c = this.state.view;
    c.newChatOpened = false;
    this.setState({view : c});
  }

  _openImagePicker = () => {
    this.openAvatarModal();
    /*let c = this.state.view;
    c.imagePicker = true;
    this.setState({view : c});*/
  }
  _receiveValidate = (status, data) => {
  }

  _toValidate = (data) => {
    this.numer.do_getvalidate_url(data,this._receiveValidate);
  }

  _likeCbHandler = (id,data) => {
    let c = this.state.fdata;
    for (let i in c.results) {
      let post = c.results[i];
      if (post.id == data.post) {
        if (post.likes == null)
          c.results[i].likes = {
            cnt : data.cnt,
            liked : true
          }
        else {
          c.results[i].likes.cnt=data.cnt;
          c.results[i].likes.liked = true;
        }
      }
    }
    this.setState({fdata : c});

  }


  _favsCbHandler = (id,data) => {
    let c = this.state.fdata;
    for (let i in c.results) {
      let post = c.results[i];
      if (post.id === data.post) {
        if (post.fav == null)
          c.results[i].fav = {favs : data.favs};
        else {
          c.results[i].fav.favs = data.favs;
        }
      }
    }
    this.setState({fdata : c});

  }

  _openBlogMenu = () => {
    let c = this.state.view;
    c.blogModalMenu = true;
    this.setState({view : c});
  }

  _blogConfChange = (param) => {
    this.setState({blogConf : param});
  }

  _blogModalClose = () => {
    let c = this.state.view;
    c.blogModalMenu = false;
    this.setState({view : c});
  }


  cbOpenPostPageOpen = (id) => {
    let c = this.state.view;
    /**/
    c.postPage = true;
    this.setState({view : c,postId : id});
  }

  cbOpenPostPageClose = () => {
    let c = this.state.view;
    c.postPage = false;
    this.setState({view : c});
  }

  cbOpenComments = () => {
    let c = this.state.view;
    let sp = c.postPage;
    c.commentPage = true;
    c.postPage = false;
    this.setState({view : c, wasOpenedPost : sp});
  }


  cbCloseComments = () => {
    let c = this.state.view;
    c.commentPage = false;
    c.postPage = this.state.wasOpenedPost;

    this.setState({view : c, wasOpenedPost : false});
  }

  _servicesSetState = (obj) => {
    let c = this.state.services;
    for (let i in obj){
      c[i] = obj[i];
    }
    this.setState({services : c});
  }

  _setIndexPageState = (obj) => {
    let c = this.state.indexPage;
    for (let i in obj){
      c[i] = obj[i];
    }
    this.setState({services : c});
  }

  componentWillMount = () => {
    this._updateUserDetails();
  }

  _updateUserDetails = () => {
    this.misago.do_fetch_user((data)=>{this.setUserInfo(data)});
  }

  _changeUserPassword = (form, cb) => {
    this.misago.changeUserPassword(this.state.user,form,cb);
  }

  _changeUserName = (form, cb) => {
    this.misago.changeUserName(this.state.user,form,this.state.userExtInfo,cb, this._setExtUserInfo, this._updateUserDetails);
  }
  _changeBio = (bio, cb) => {
    this.misago.changeUserBio(this.state.user,bio,this.state.userExtInfo,cb, this._setExtUserInfo);
  }

  _openEditUserFullName = (formData) => {
    let c = this.state.view;
    formData.cbBack = this._formEditItemClose;
    c.showEnterForm = true;
    this.setState({view : c, formData : formData});
  }

  _retriveExtUserInfo = () => {
    this.misago.getUserEditDetails(this.state.user,this._setExtUserInfo);
  }

  _setExtUserInfo = (data) => {
    this.setState({userExtInfo : data});
  }

  _showCreateFlow = (cb) => {
    let view = this.state.view;
    view.showCreateFlow = true;
    this.setState({view : view, cbShowCreateFlow : cb});
  }

  _closeCreateFlow = () => {
    let view = this.state.view;
    view.showCreateFlow = false;
    this.setState({view : view, cbShowCreateFlow : undefined});
  }

  _showCreatePromo = (cb, id) => {
    let view = this.state.view;
    view.showCreatePromo = true;
    this.setState({view : view, cbShowCreatePromo : cb, createPromoFlowId : id});
  }

  _closeCreatePromo = () => {
    let view = this.state.view;
    view.showCreatePromo = false;
    this.setState({view : view, cbShowCreatePromo : undefined});
  }

  _showCreateEvent = (cb) => {
    let view = this.state.view;
    view.showCreateEvent = true;
    this.setState({view : view, cbShowCreateEvent : cb});
  }

  _closeCreateEvent = () => {
    let view = this.state.view;
    view.showCreateEvent = false;
    this.setState({view : view, cbShowCreateEvent : undefined});
  }

  _showCreatePromoTicket = (cb, id) => {
    let view = this.state.view;
    view.showCreateTicketPromo = true;
    this.setState({view : view, cbShowCreatePromoTicket : cb, createPromoEventId : id});
  }

  _closeCreatePromoTicket = () => {
    let view = this.state.view;
    view.showCreateTicketPromo = false;
    this.setState({view : view, cbShowCreatePromoTicket : undefined, createPromoEventId : undefined});
  }



_showCreateTicketTemplate = (cb, id) => {
  let view = this.state.view;
  view.showCreateTicketTemplate = true;
  this.setState({view : view, cbShowCreateTicketTemplate : cb, createTicketEvent : id});
}

_closeCreateTicketTemplate = () => {
  let view = this.state.view;
  view.showCreateTicketTemplate = false;
  this.setState({view : view, cbShowCreateTicketTemplate : undefined, createTicketEvent : undefined});
}

  _showCreateExpert = (cb) => {
    let view = this.state.view;
    view.showExpertModal = true;
    this.setState({view : view, cbShowCreateExpert : cb});
  }

  _closeCreateExpert = () => {
    let view = this.state.view;
    view.showExpertModal = false;
    this.setState({view : view, cbShowCreateExpert : undefined});
  }

  validate_email_req = (cb) => {
    cb({},true);
  }

  validate_email_ans = (cb) => {
    cb({},true);
  }

  _openCheckExpert = () => {
    let view = this.state.view;
    view.showExpertCheck = true;
    this.setState({view : view});
    
  }

  _closeCheckExpert = () => {
    let view = this.state.view;
    view.showExpertCheck = false;
    this.setState({view : view});
    
  }


  _renderBlog = ({history, match, location}) => {
    
    return (
         <BlogPage data={this.state.search_text.length > 2 ?  this.state.fdata_search : this.state.fdata}
      scrollWidth={this.state.scrollWidth}
      showFullPost={this.cbOpenPostPageOpen}
      openComments={this.cbOpenComments}
      wagtail={this.wagtail}
      t_height = {this.state.scrollHeight}
      search={this.debugSearch}
      history={history}
      match={match}
      location={location}
      reset_search={this._resetSearch}
      conf={this.state.blogConf}
      user={this.state.user}
      auth={this.state.auth}
      openBlogMenu={this._openBlogMenu}
      loading={this.state.fdata_loading}
      handleLoadMore={this._handleLoadMore}
      likeCb={this._likeCbHandler}
      favsCb={this._favsCbHandler}

      search_text={this.state.search_text}
      login={this.cbOpenAuthPageOpen}
      offset={this.state.offset}
      style={[styles.mainContent,{height : this.state.scrollHeight,width : this.state.scrollWidth}]}/>
      
 

    )
    /*return (
      <BlogViewPage fullWidth={this.state.scrollWidth} history={history}/>
    )*/
  }

  _renderMatrix = ({history, match, location}) => {
    return (
      <MatrixPageContainer
        supp={this.adapt_support}
        openFav={this.favouritsOpenMenu}
        onSelect={this.favouritsOnSelect}
        onAddFav={this.favouritsAdd}
        item={this.state.calcItem}
        history={history}
        match={match}
        fullWidth={this.state.scrollWidth}
        location={location}

        t_height={this.state.scrollHeight}
        style={[styles.mainContent,{width : this.state.scrollWidth}]}
        />
    ) 
  }

  _renderService = ({history, match, location}) => {
    /*
         */
    return (
      <ServiceContainer
      history={history}
      match={match}
      location={location}
      style={styles.mainContent}
      serv_e={this.state.sdata_e}
      serv_o={this.state.sdata_o}
      price={this.state.price}
      auth={this.state.auth}
      user={this.state.user}
      services={this.state.services}
      setParentState={this._servicesSetState}
      scrollWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
      t_height={this.state.scrollHeight}
      fullWidth={this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      review={this.state.review}
      numer={this.numer}
      toValidate={this._toValidate}/>
      
    )
/*    return (
      <ChatUnderContructionPage/>
    )
*/
  }

  _renderChat = ({history, match, location}) => {
    /*if (this.state.showChatList)
      return (
        <ContactList fullWidth={this.state.scrollWidth} fullHeight={this.state.scrollHeight} showChat={this._showChat}
            showCreateChat={this._showCreateChat} misago={this.misago}
            history={history}
            match={match}
            location={location}
            />
    )
    else return (
      <MessageList fullWidth={this.state.scrollWidth} misago={this.misago} fullHeight={this.state.scrollHeight} showChatList={this._showChatList} chat={this.state.chat_api}
          user={this.state.user} login={this.cbOpenAuthPageOpen}
          history={history}
          match={match}
          location={location}
          />
    )*/
    
    return (
       <ChatUnderContructionPage/>
    )
    /*
    return (
      <ChatRouter
        misago={this.misago}
        fullHeight={this.state.scrollHeight}
        fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
        chat={this.state.chat_api}
        user={this.state.user}
        login={this.cbOpenAuthPageOpen}
        showCreateChat={this._showCreateChat}
        history={history}
        match={match}
        location={location}
        />
      
    )*/
  }

  _renderProfile = ({history, match, location}) => {
    return (
      <ProfilePage match={match} history={history} location={location} fullHeight={this.state.scrollHeight} openImagePicker={this._openImagePicker}
      cbOpenEditForm={this._openEditUserFullName} changeUserPassword={this._changeUserPassword} changeUserName={this._changeUserName}
      userInfo={this.state.user}
      retriveExtUserInfo={this._retriveExtUserInfo}
      extInfo={this.state.userExtInfo}
      changeBio={this._changeBio}
      debug_url={debug_url}
      numer={this.numer}
      api_url={{'orders' : '/numer/api/user/orders','works' : '/numer/api/user/works'}}
      fullWidth={this.state.scrollWidth}
      />
    )
  }
  _renderConsultTos =  ({history, match, location}) => {
    return (
      <ConsultTos history={history}
            match={match}
            location={location}
            wagtail={this.wagtail}
            fullHeight={this.state.scrollHeight}
            fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
            />
    )
  }

  _renderSchool = ({history, match, location}) => {
    //;//{this.state.scrollHeight}
    return (
      <SchoolContent match={match}
          history={history}
          location={location}
          fullHeight={this.state.scrollHeight}
          fullWidth={this.state.scrollWidth}
          showCreateFlow={this._showCreateFlow}
          showCreatePromo={this._showCreatePromo}
          numer={this.numer}
          wagtail={this.wagtail}/>
    )
  }

  _renderEvents = ({history, match, location}) => {
    //;//{this.state.scrollHeight}
 
    return (
      <EventsContent match={match}
          history={history}
          location={location}
          fullHeight={window.innerHeight}
          fullWidth={this.state.scrollWidth}
          showCreateEvent={this._showCreateEvent}
          showCreatePromoTicket={this._showCreatePromoTicket}
          showCreateTicketTemplate={this._showCreateTicketTemplate}
          numer={this.numer}
          wagtail={this.wagtail}/>
    )
  }

  _renderAdminServices = ({history, match, location}) => {
    //;//{this.state.scrollHeight}
 
    return (
      <ServiceAdminMainRouter match={match}
          history={history}
          location={location}
          fullHeight={window.innerHeight}
          fullWidth={this.state.scrollWidth}
          numer={this.numer}
          wagtail={this.wagtail}/>
    )
  }
  /*
  _renderPublicSchool = ({history, match, location}) => {
    if (!this.state.hide_menu)
      this.setState({hide_menu : true});

    return (
      <SchoolPublic match={match}
          history={history}
          location={location}
          fullHeight={this.state.scrollHeight}
          fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
          numer={this.numer}
          form={this.state.schoolForm}
          validate_email_req={this.validate_email_req}
          validate_email_ans={this.validate_email_req}


          setForm={(value)=>{this.setState({schoolForm : value})}}
          />
    )
  }*/

  _renderPublicSchool = ({history, match, location}) => {
    window.location.replace("/school/");
    return (
      <SchoolPublicLoaded match={match}
          history={history}
          location={location}
          fullHeight={this.state.scrollHeight}
          fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
          numer={this.numer}
          auth={this.state.auth}
          form={this.state.schoolForm}
          wagtail={this.wagtail}
          user={this.state.user}
          validate_email_req={this.validate_email_req}
          validate_email_ans={this.validate_email_req}
          setForm={(value)=>{this.setState({schoolForm : value})}}
          />
    )
  }
  _renderPublicSchool1 = ({history, match, location}) => {
    return (
      <SchoolPublicLoaded match={match}
          history={history}
          location={location}
          fullHeight={this.state.scrollHeight}
          fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
          numer={this.numer}
          auth={this.state.auth}
          form={this.state.schoolForm}
          wagtail={this.wagtail}
          user={this.state.user}
          validate_email_req={this.validate_email_req}
          validate_email_ans={this.validate_email_req}
          setForm={(value)=>{this.setState({schoolForm : value})}}
          />
    )
  }


  _renderIndexPage = ({history, match, location}) => {
    return (
    <IndexPage
          supp={this.adapt_support}
          conf={this.state.indexPage}
          posts={this.state.fdata}
          faq={this.state.faq}
          review={this.state.review}
          openFav={this.favouritsOpenMenu}
          fullWidth={this.state.scrollWidth }
          fullHeight={this.state.scrollHeight}
          setParentState={this._setIndexPageState}
          onCalcPress={this.favouritsOnSelect}
          services={this.state.sdata_e}
          history={history}
          />      
    )
    /*
  return (
      <MessageList misago={this.misago}/>
    )*/
  }

  _renderOptions = ({history, match, location}) => {
      return (
        <OptionsPage fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
          history={history}
          match={match}
          location={location}
          misago={this.misago}
          />

      )
  }

  _renderForgottenPassword = ({history, match, location}) => {
      return (
        <ForgottenPassword fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
          history={history}
          match={match}
          location={location}
          misago={this.misago}
          />

      )
  }

  _renderPay = ({history, match, location}) =>{
    return (
      <PayViewRouter fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
       fullHeight={this.state.scrollHeight}
        history={history}
        match={match}
        auth={this.state.auth}
        userInfo={this.state.user}
        location={location}
        numer={this.numer}/>
    )
  }

  _renderAdminExperts = ({history, match, location}) =>{
    return (
      <ExpertsRouter fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
        fullHeight={this.state.scrollHeight}
        showCreateExpert={this._showCreateExpert}
        history={history}
        match={match}
        location={location}
        numer={this.numer}/>
    )
  }

  _renderExpert = ({history, match, location}) =>{
    return (
      <ClientsList fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
        fullHeight={this.state.scrollHeight}
        history={history}
        match={match}
        id={match.params.id}
        exp_id={match.params.id}
        self={true}
        location={location}
        numer={this.numer}/>
    )
  }

  _renderClientPay = ({history, match, location}) => {
    return (
      <ExpertClientPay fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
        fullHeight={this.state.scrollHeight}
        history={history}
        match={match}
        form={this.state.schoolForm}
        setForm={(value)=>{this.setState({schoolForm : value})}}
        slug={match.params.slug}
        location={location}
        numer={this.numer}/>
    )
  }

  _renderServiceItem = ({history, match, location}) => {
    return (
      <ServiceItemView fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      history={history}
      match={match}
      order_id={match.params.id}
      location={location}
      auth={this.state.auth}
      userInfo={this.state.user}
      numer={this.numer}/>
    )
  }

  _renderTickets = ({history, match, location}) => {
    return (
      <TicketSaleInfo fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
        fullHeight={this.state.scrollHeight}
        history={history}
        match={match}
        location={location}
        numer={this.numer}/>
    )
  }
  _renderTicketsSale = ({history, match, location}) => {

    return (
      <TicketSale fullWidth={this.state.scrollWidth > 1024 ? 1024 : this.state.scrollWidth}
        fullHeight={this.state.scrollHeight}
        history={history}
        match={match}
        location={location}
        numer={this.numer}/>
    )
  }

  _renderAuth = ({history, match, location}) => {
    return (
      <PhoneAuth fullWidth={this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      auth={this.state.auth}
      history={history}
      match={match}
      setUserInfo = {this.setUserInfo}
      location={location}
      cbOpenAuthPage={this.cbOpenAuthPageOpen}
      numer={this.numer}/>
    )
  }

  _renderOpSuccess = ({history, match, location}) => {
    return (
      <OpSuccessView fullWidth={this.state.scrollWidth > 510 ? 510 : this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      auth={this.state.auth}
      history={history}
      match={match}
      location={location}
      numer={this.numer}/>
    )
  }

  _renderOpSuccessService = ({history, match, location}) => {
    return (
      <OpServiceSuccessView fullWidth={this.state.scrollWidth > 510 ? 510 : this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      auth={this.state.auth}
      history={history}
      match={match}
      location={location}
      numer={this.numer}/>
    )
  }


  _renderPCSuccess= ({history, match, location}) => {
    return (
      <PCSuccessView fullWidth={this.state.scrollWidth > 510 ? 510 : this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      auth={this.state.auth}
      history={history}
      match={match}
      location={location}
      numer={this.numer}/>
    )
  }

  _renderLogOutSuccess= ({history, match, location}) => {
    return (
      <LogOutSuccessView fullWidth={this.state.scrollWidth > 510 ? 510 : this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      auth={this.state.auth}
      history={history}
      match={match}
      location={location}
      numer={this.numer}/>
    )
  }



  _renderUserInfo = ({history, match, location}) => {
    return (
      <ProfileForm fullWidth={this.state.scrollWidth > 510 ? 510 : this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      auth={this.state.auth}
      history={history}
      match={match}
      user={this.state.user}
      setUserInfo = {this.setUserInfo}
      location={location}
      numer={this.numer}/>
    )
  }

  _renderFaq = ({history, match, location}) => {
    return (
      <FaqPageContainer fullWidth={this.state.scrollWidth}
      scrollHeight={this.state.scrollHeight}
      auth={this.state.auth}
      history={history}
      match={match}
      faq={this.state.faq}
      user={this.state.user}
      location={location}
      numer={this.numer}/>
    )
    
  }

  _renderReview = ({history, match, location}) => {
    return (
      
      <ReviewPageContainer fullWidth={this.state.scrollWidth}
      scrollHeight={this.state.scrollHeight}

      auth={this.state.auth}
      history={history}
      match={match}
      review={this.state.review}
      user={this.state.user}
      location={location}
      numer={this.numer}/>
    )
  }

  _renderCheckExpert = ({history, match, location}) => {
    return (
      <CheckExpertView 
      fullWidth={this.state.scrollWidth}
      fullHeight={this.state.scrollHeight}
      numer={this.numer}
      history={history}
      match={match}
      location={location}/>
    )
   
  } 


  render() {

    return (

      <Router style={styles.wrapper}>
            <View style={styles.wrapper}>
              {
                debug_url === '' ? 
              <YMInitializer 
                accounts={[64751164]} 
                options={{
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true,
                  trackHash: true,
                }}
                version="2"
                />
                :
                null
                }
                <MenuContainer
                  style={{ position: 'absolute',
                          left: 0,
                          right: 0,
                          top: 0,
                          zIndex: 10}}
                  auth={this.state.auth}
                  supp={this.adapt_support}
                  showMenu={!this.state.hide_menu}
                  cbDimChanged={this.dimsChanged}
                  cbOpenProfile={this.sideProfileOpen}
                  cbOpenServices={this.openServices}
                  cbOpenMatrix={this.openMatrix}
                  cbShowChats={this.openChats}
                  cbOpenAuthPage={this.cbOpenAuthPageOpen}
                  cbOpenMainMenu={this.mainMenuModalOpenedOpen}
                  cbCloseMainMenu={this.mainMenuModalOpenedClose}
                  avatar={this.state.auth ? this.state.user.avatars[4].url : ''}
                  avatarPress={this.openAvatarModal}
                  openedIndex={this.state.menuOpenedIndex}
                  />


                <SideProfileContainer cbCloseProfile={this.sideProfileClose}
                  ajax={this.ajax}
                  opened={this.state.view.sideProfileOpened}
                  logOut = {this.logOut}
                  user = {this.state.user}
                  debug_url={debug_url}
                  openImagePicker={this._openImagePicker}
                  scrollHeight = {this.state.scrollHeight}
                  fullWidth = {this.state.scrollWidth > 510 ? 510 : this.state.scrollWidth}
                  />
                <FavouritsContainer visible={this.state.view.matrixFavouritsOpened} cbClose={this.favouritsCloseMenu} data={this.state.favs} onDelete={this.favouritsRemove}
                  onSelect={this.favouritsOnSelect}/>

              {this.state.view.registerOpened ?
                <RegisterContainer ajax={this.ajax}
                  setUserInfo={this.setUserInfo}
                  url={debug_url}
                  style={{ position: 'absolute',left:0,right:0,top:0,bottom:0,zIndex:1000}}
                  />
                :
                null
              }
              <CreateChatPageContainer visible={this.state.view.newChatOpened} closeCreateChat={this._closeCreateChat}
                fullWidth={this.state.scrollWidth} misago={this.misago} category={this.state.chat_category} cbCreated={this.state.chat_cb}/>

              <LoginPageContainer visible={this.state.view.authOpened} onBackdropPressed={this.cbOpenAuthPageClose} cbClose={this.cbOpenAuthPageClose}
                  ajax={this.ajax}
                  setUserInfo={this.setUserInfo}
                  scrollHeight = {this.state.scrollHeight}
                  fullWidth = {this.state.scrollWidth}
                  messageHint = {this.state.loginPageMessageHint}
                  url={debug_url}
              />

              <ChangeAvatarModal visible={this.state.view.changeAvatarModal} closeWindow={this.cbCloseAvatarModal}
                ajax={this.ajax}
                misago={this.misago}
                user={this.state.user}
                fullWidth={this.state.scrollWidth}
                  updateUser={this._updateUserDetails}
                />

              <ImagePickerModal visible={this.state.view.imagePicker} ajax={this.ajax}/>
              {this.state.view.showEnterForm ?
              <EditForm visible={this.state.view.showEnterForm} form={this.state.formData}/>
              :
              null
              }

              <ProfileViewOrder visible={this.state.view.showOrderStatus} order={{state : 'test'}}/>

              <BlogModalMenu visible={this.state.view.blogModalMenu}
                    conf={this.state.blogConf} confChange={this._blogConfChange}
                    close={this._blogModalClose}
                    onBackdropPressed={this._blogModalClose}/>

             

              <CreateFlowModal visible={this.state.view.showCreateFlow}
                numer={this.numer}
                close={this._closeCreateFlow}
                onBackdropPressed={this._closeCreateFlow}/>

              <CreatePromoModal visible={this.state.view.showCreatePromo}
                  numer={this.numer}
                  flow_id={this.state.createPromoFlowId}
                  cbtoform={this.state.cbShowCreatePromo}
                  close={this._closeCreatePromo}
                  onBackdropPressed={this._closeCreatePromo}/>

              <MenuModal visible={this.state.view.mainMenuModalOpened}
                cbClose={this.mainMenuModalOpenedClose}
                ops={this.state.menuModalOps} 
                cbProps={this.state.menuModalOps}
              />


              <CreateEventModal visible={this.state.view.showCreateEvent}
                numer={this.numer}
                close={this._closeCreateEvent}
                onBackdropPressed={this._closeCreateEvent}/>

              <CreateTicketPromoModal visible={this.state.view.showCreateTicketPromo}
                  numer={this.numer}
                  eventId={this.state.createPromoEventId}
                  cbtoform={this.state.cbShowCreatePromo}
                  close={this._closeCreatePromoTicket}
                  onBackdropPressed={this._closeCreatePromoTicket}/>


              <CreateExpertModal visible={this.state.view.showExpertModal}
                  numer={this.numer}
                  close={this._closeCreateExpert}
                  onBackdropPressed={this._closeCreateExpert}/>


              {this.state.view.commentPage ?
                <CommentsPage
                  closePage={this.cbCloseComments}
                  t_height = {this.state.scrollHeight}
                  scrollWidth={this.state.scrollWidth}
                  style={{alignSelf : 'center',zindex : 100}}
                  />
                :
                null
              }
                  <View style={{width:'100%', flex:1}}>
                    <Suspense fallback={  <ActivityIndicator size="large" color="#0000ff" />} style={{width:'100%', height:'100%'}}>
                      <Switch style={{width:'100%',  height:'100%'}}>
                        <Route path="/opsuccess/school/create/" render={this._renderOpSuccess}/>
                        <Route path="/opsuccess/service/create/" render={this._renderOpSuccessService}/>
                        <Route path="/opsuccess/payment/cancel/" render={this._renderPCSuccess}/>
                        <Route path="/logout" render={this._renderLogOutSuccess}/>
                        <Route path="/userInfo/" render={this._renderUserInfo}/>
                        <Route path="/faq/" render={this._renderFaq}/>
                        <Route path='/review/' render={this._renderReview}/>
                        <Route path="/tos" render={this._renderConsultTos}/>
                        <Route path="/options" render={this._renderOptions}/>
                        <Route path="/forgotten-password" render={this._renderForgottenPassword}/>
                        <Route path="/pay" render={this._renderPay}/>
                        <Route path="/serv_pay/:slug" render={this._renderClientPay}/>
                        <Route path="/schoolp" render={this._renderPublicSchool1}/>
                        <Route path="/school" render={this._renderPublicSchool}/>
                        <Route path="/admin/school" render={this._renderSchool}/>
                        <Route path="/admin/services" render={this._renderAdminServices}/>
                        <Route path="/admin/events" render={this._renderEvents}/>
                        <Route path="/admin/experts" render={this._renderAdminExperts}/>
                        <Route path="/matrix" render={this._renderMatrix}/>
                        <Route path="/auth/" render={this._renderAuth}/>
                        <Route path="/profile" render={this._renderProfile}/>
                                                
                      </Switch>
                    </Suspense>
                </View>
            </View>
      </Router>
    );
  }
}
/*
                        <Route path="/blog" render={this._renderBlog}/>
                        <Route path="/services" render={this._renderService}/>
                        <Route path="/chat" render={this._renderChat}/>
                        <Route path="/expert/:id" render={this._renderExpert}/>
                        <Route path="/tickets" render={this._renderTickets}/>
                        <Route path="/sale/ticket" render={this._renderTicketsSale}/>
                        <Route path="/service/:id" render={this._renderServiceItem}/>
                        <Route path='/checkexpert/' render={this._renderCheckExpert}/>
                        <Route exact path="/" render={this._renderIndexPage}/>

*/
//
const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'flex-start',
    alignItems : 'center',
    flexDirection : 'column',
    width: '100%',
    minHeight : 320,
    backgroundColor : '#F1F3F5',
    flex:1,
    height:'100%',
  },
  mainbox: {
    justifyContent: 'flex-start',
    alignItems : 'center',
    flexDirection : 'column',
    width: '100%',
    flexWrap : 'wrap',
    overflow : 'hidden',
    flex : 1,
    backgroundColor : '#F1F3F5',
  },
  footer: {
    height: 40,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#8BC34A'
  },
  mainContent: {
    justifyContent: 'center',
    alignItems : 'center',
    flexDirection : 'column',
    backgroundColor : '#F1F3F5',
    width: '100%',
    flexWrap : 'wrap'
  },
  text: { fontWeight: 'bold' },
  flat : {
    width : '100%'
  }
});


AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });
export default App;
