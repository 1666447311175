import React, {Component} from 'react'
import {View, StyleSheet} from 'react-native'
import Carusel from "./carusel"


class BlogCarusel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        }
    }

    _openInfo = () => {
        this.setState({collapsed : true});
    }

    _closeInfo = () => {
        this.setState({collapsed : false});
    }

    _toggle = () => {
        this.setState({collapsed : !this.state.collapsed});
    }


    render () {
        return (
            <View style={[styles.wrapper]}>
                
            </View>
        )
    }
};

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        backgroundColor: 'transparent',

    },
    buttonWrapperStyle: {
        width: '100%',
        flexDirection : 'row',
        alignItems: 'center',
        padding: 16,
        minHeight: 72,
        justifyContent: 'space-between',

    },
    buttonCaption: {
        flex: 1,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
    },
    infoWrapper: {
        paddingLeft: 46,
        borderTopWidth: 1,
        borderTopColor: 'rgba(0, 0, 0, 0.05)',
        width: '100%',
        flexDirection : 'column',
    },
    infoText:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'left',
        /* or 143% */


        /* @grey_60 */

        color: 'rgba(0, 0, 0, 0.6)'
    }
});


export default BlogCarusel;