import React,{ Component } from 'react'
import {View,StyleSheet, ScrollView, Image, TouchableOpacity} from 'react-native'
import Modal from 'modal-react-native-web';
import Ptext from "../../common/Ptext.js";
import Button from "../../common/Button.js";
import UploadItems from "../../support/upload.js"


class ChangeAvatarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image : {},
      preview : '',
      progress : 0,
      i_width : 4,
      i_height : 4,
      i_hor : true,
      i_degree : 0,
      show_edit : false,
      show_select : true,
      has_changes : false,
      not_fetched : true,
      x_cut : 0,
      y_cut : 0,
      avatar_src : '',
    };
    this.upload = new UploadItems();
  }

  _set_avatar_data = (data, status) => {
    if (status)
      this.setState({avatar : data});
    else
      this.setState({caption : data});
  }

  /*
  <ScrollView style={{width : this.props.fullWidth, height : this.props.fullWidth}} horizontal={true}>
    <ScrollView style={{width : this.state.i_width, height : this.props.fullWidth}}>
      <Image style={[styles.avatarImageBig,{width : this.state.i_width, height : this.state.i_height}]} source={require('../../icons/me-img.jpg')}/>
    </ScrollView>
  </ScrollView>
  */
  backgroundMask = () => {
    return (
      <svg>
        <defs>
          <linearGradient id="gradient" x1="0" y1="00%" x2 ="0" y2="100%">
            <stop stop-color="black" offset="0"/>
            <stop stop-color="white" offset="1"/>
          </linearGradient>

          <mask id="masking" maskUnits="objectBoundingBox" maskContentUnits="objectBoundingBox">
            <rect y="0.3" width="1" height=".7" fill="url(#gradient)" />
            <circle cx=".5" cy=".5" r=".35" fill="white" />
          </mask>
        </defs>
      </svg>
    )
  }

    _zoomIn = () => {
      if (this.state.i_hor) {
        if ((this.state.i_height*1.05 < this.state.orig_height)&&(this.state.i_width*1.05 < this.state.orig_width))
          this.setState({i_height : this.state.i_height*1.05,i_width : this.state.i_width*1.05});
        else {
          this.setState({i_height : this.state.orig_height,i_width : this.state.orig_width});
        }
      }
    }
    _zoomOut = () => {

      if ((this.state.i_height*0.95 > this.props.fullWidth)&&(this.state.i_width*0.95>this.props.fullWidth))
        this.setState({i_height : this.state.i_height*0.95,i_width : this.state.i_width*0.95});
    }
    _rotate = () => {
      this.setState({i_degree : (this.state.i_degree-90)%360, i_height : this.state.i_width, i_width : this.state.i_height});
    }

    handleScroll_hor = (event : Object) => {
      this.setState({x_cut : event.nativeEvent.contentOffset.x})
    }
    handleScroll_vert = (event : Object) => {
      this.setState({y_cut : event.nativeEvent.contentOffset.y})
    }


    dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        console.log("type "+mimeString);
        return new Blob([ia], {type:mimeString});
    }

    _avatarUpload  = (data, status ) => {
      if (status) {
        this.setState({
          preview : data.crop_tmp.url,
          progress : 0,
          show_edit : true,
          show_select : false,
          avatar_src : 'crop_tmp',
        });
      }
    }

  _image_loaded = ( img_el, url, img) =>
  {
    console.log(img_el);
    let image = this.upload.scaleImage(img_el);

    Image.getSize(image, (width, height)=>{this.setState({ i_width : width, i_height : height, orig_width : width, orig_height : height})});



    let data = new FormData();
    let blob = this.dataURItoBlob(image);
    console.log("converted size" + blob.size);

    this.setState({
      progress : 0,
    });


    data.append("avatar","upload");
    data.append("image",blob);
    data.append("image",blob,"converted_.jpg")

    this.props.misago.set_avatar(data, this.props.user, this._avatarUpload, progress => {this.setState({progress})});

  }


  _imageSelected = (obj) => {
      if ( obj.files.length < 1 )
        return;
      let image = obj.files[0];
      let url = URL.createObjectURL(image);
      console.log(image);
      if (image.size > 1500000) {
        var image_element = document.createElement('img');
        image_element.src = url;
        console.log("image to big size is "+image.size);
        image_element.onload = (image, url) => {
          this._image_loaded(image_element, url, image);
        }

      }else {
        Image.getSize(url, (width, height)=>{this.setState({ i_width : width, i_height : height, orig_width : width, orig_height : height})});

        this.setState({
          preview : url,
          progress : 0,
          show_edit : true,
          show_select : false,
          avatar_src : 'crop_tmp',
        });

        let data = new FormData();
        data.append("avatar", "upload");
        data.append("image", image);

        this.props.misago.set_avatar(data, this.props.user, this._avatarUpload,progress => {this.setState({progress})});

      }



  }

  _imageSelected_url = (url_in) => {

      Image.getSize(url_in, (width, height)=>{this.setState({ i_width : width*this.props.fullWidth/400, i_height : height*this.props.fullWidth/400, orig_width : width*this.props.fullWidth/400, orig_height : height*this.props.fullWidth/400})});

      this.setState({
        preview : url_in,
        progress : 0,
        show_edit : true,
        show_select : false,
        avatar_src : 'crop_src',
      });
/*
      let data = new FormData();
      data.append("avatar", "upload");
      data.append("image", image);
*/
  }

  upload_new = () => {
    let input = this.upload.upload_create(this._imageSelected,"image/*");
    input.click();
    this.upload.remove(input);
  }

  crop_image = () => {
    this._imageSelected_url(this.state.avatar.crop_src.url);
  }

  _resultCrop = (data, status) => {
    console.log(status);
    console.log(data);
    if (status) {
      this.setState({done_desc : data.detail, avatar_src : 'crop_src'});
      setTimeout(() => {this.setState({done_desc: undefined})}, 4000);
    }
    else {
      this.setState({caption : data.detail});
      setTimeout(() => {this.setState({caption: undefined})}, 4000);
    }
  }

  save_changes = () => {
    let post_data = {};
    post_data.avatar = this.state.avatar_src;
    post_data.crop = {};
    post_data.crop.zoom = this.state.i_width / this.state.orig_width;
    post_data.crop.offset = {};
    post_data.crop.offset.x = -1*this.state.x_cut*400/this.props.fullWidth;
    post_data.crop.offset.y = -1*this.state.y_cut*400/this.props.fullWidth;
    console.log(post_data);
    this.setState({not_fetched : true});
    this.props.misago.set_avatar_crop(post_data, this.props.user,this._resultCrop, this.props.updateUser);
  }

/*
  <TouchableOpacity onPress={this._rotate}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20Z" stroke="#B4BBC2"/>
    <path d="M15.34 14.4098L8.85999 20.8998L15.35 27.3798L21.84 20.8998L15.34 14.4098ZM11.69 20.8998L15.35 17.2398L19 20.8998L15.34 24.5598L11.69 20.8998ZM27.36 14.6398C25.61 12.8798 23.3 11.9998 21 11.9998V8.75977L16.76 12.9998L21 17.2398V13.9998C22.79 13.9998 24.58 14.6798 25.95 16.0498C28.68 18.7798 28.68 23.2198 25.95 25.9498C24.58 27.3198 22.79 27.9998 21 27.9998C20.03 27.9998 19.06 27.7898 18.16 27.3898L16.67 28.8798C18 29.6198 19.5 29.9998 21 29.9998C23.3 29.9998 25.61 29.1198 27.36 27.3598C30.88 23.8498 30.88 18.1498 27.36 14.6398Z" fill="#B4BBC2"/>
    </svg>

  </TouchableOpacity>
*/
  decline = () => {
    URL.revokeObjectURL(this.state.preview);
    this.setState({ show_select : true, show_edit : false, preview : undefined, image : undefined});
  }

  _show_edit = () => {
    let curr_width = this.props.fullWidth;
    if (curr_width > 510)
    curr_width = 510;
    return (
      <View style={{width : curr_width}}>
          <View style={[styles.mask, {width : curr_width, height : curr_width, borderRadius : curr_width/2.0}]}>
            <ScrollView style={{width : curr_width, height : curr_width, alignSelf : 'center'}} horizontal={true}
              contentContainerStyle={{alignItems : 'center', justifyContent : 'center'}}
              onScroll={this.handleScroll_hor}>
                <ScrollView style={{width : this.state.i_width, height : curr_width, alignSelf : 'center'}} contentContainerStyle={{alignItems : 'center', justifyContent : 'center'}}
                  onScroll={this.handleScroll_vert}>
                  <Image style={[styles.avatarImageBig,{width : this.state.i_width, height : this.state.i_height, transform :  [{ rotate : this.state.i_degree+'deg'}]}]} source={this.state.preview} resizeMode="cover"
                  />
                </ScrollView>
            </ScrollView>
          </View>
          <View style={{flexDirection : 'row', justifyContent : 'space-between', alignSelf : 'center', width : 168, paddingTop : 24}}>
            <TouchableOpacity onPress={this._zoomOut}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20Z" stroke="#B4BBC2"/>
              <rect x="14" y="22" width="2" height="12" rx="1" transform="rotate(-90 14 22)" fill="#B4BBC2"/>
              </svg>
            </TouchableOpacity>
            <TouchableOpacity onPress={this._zoomIn}>
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20Z" stroke="#B4BBC2"/>
              <rect x="19" y="14" width="2" height="12" rx="1" fill="#B4BBC2"/>
              <rect x="14" y="21" width="2" height="12" rx="1" transform="rotate(-90 14 21)" fill="#B4BBC2"/>
              </svg>
            </TouchableOpacity>
          </View>
          <View style={{flexDirection : 'row', justifyContent : 'space-between', alignSelf : 'center', width : 240, paddingTop : 24}}>
            <Button title="Сохранить" style={styles.button_menu_accept} onPress={this.save_changes}/>
            <Button title="Закрыть" style={styles.button_menu_decline} onPress={this.decline}/>
          </View>
      </View>
    )
  }

  _show_select = () => {
    if (this.state.not_fetched) {
      this.setState({not_fetched : false});
      this.props.misago.get_avatar(this._set_avatar_data, this.props.user);
    }
    return (
      <View style={{width : this.props.fullWidth >510 ? 510 : '95%', alignSelf : 'center', alignItems : 'center', justifyContent : 'flex-start', paddingTop : 20}}>
        {
          this.state.hasOwnProperty('avatar') ?
          <Image source={this.state.avatar.avatars[1].url} style={{width:200,height:200}}/>
          :
          null
        }

        <Button title="Загрузить новое" onPress={this.upload_new} style={styles.buttons_menu} theme={'dark'}/>
        <Button title="Обрезать изображение" onPress={this.crop_image} style={styles.buttons_menu}/>
        <Button title="Сгенерировать аватар" onPress={this.generate_gravitar} style={styles.buttons_menu}/>

      </View>
    )
  }


  render () {
    return (
      <Modal style={[styles.wholeWindow, {width : this.props.fullWidth > 500 ? 500 :  this.props.fullWidth}]} visible={this.props.visible}
        transparent={false} ariaHideApp={false} animationType={'fade'}>
        <TouchableOpacity onPress={this.props.closeWindow} style={styles.topMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4 7.4L14 6L8 12L14 18L15.4 16.6L10.8 12L15.4 7.4Z" fill="black"/>
            </svg>
            <Ptext style={styles.topMenuCaption}>
            Фото профиля
            </Ptext>
        </TouchableOpacity>
        {this.state.hasOwnProperty('caption') && this.state.caption !== '' ?
            <Ptext style={{backgroundColor : 'red'}}>{this.state.caption}</Ptext> : null}
        {this.state.done_desc ?
          <Ptext style={styles.done_desc}>
            {this.state.done_desc}
          </Ptext>
          :
          null
        }

        {this.state.show_select && this.props.user.is_authenticated ? this._show_select() : null }
        {this.state.show_edit && this.props.user.is_authenticated ? this._show_edit() : null}
    </Modal>
    )
  }

}

const styles = StyleSheet.create({
  wholeWindow : {
    backgroundColor : 'white',
    width : '100%',
  },
  topMenu : {
    width : '100%',
    flexDirection : 'row',
    minHeight : 24,
    paddingTop : 24,
    paddingLeft : 16,
    paddingRight : 16,
    paddingBottom : 25,
  },
  topMenuCaption : {
    fontSize : 20,
    fontWeight : 'bold',
    textAlign : 'left',
    paddingLeft : 19,
    lineHeight : 24,
  },
  avatarImageBig : {
    alignSelf : 'center',
  },
  mask : {
    borderWidth : 0,
    overflow : 'hidden',
    alignItems : 'center',
    justifyContent : 'center'
  },
  button_menu_accept : {
    width : 100,
    borderColor : '#DEF7D2',
    backgroundColor : '#DEF7D2',
  },
  button_menu_decline : {
    width : 100,
    borderColor : '#F5222D',
    backgroundColor : '#F5222D',
  },
  done_desc : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : '#90ee90',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },

});
export default ChangeAvatarModal;
