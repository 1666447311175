import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import Ptext from './Ptext.js';
import globalStyle from '../styles/globalStyle';

class Button extends Component {
  render () {
    let _style = this.props.hasOwnProperty('theme') ? this.props.theme : 'primary';

    switch (_style) {
      case 'light':
        _style = 'primary';
        break;
      case 'dark':
        _style = 'secondary';
        break;
      default:
        _style = 'primary';
    }

    let colors = {
      backgroundColor : globalStyle.colors[_style],
      borderColor : globalStyle.colors[_style],
    }
    if (this.props.disabled)
      for (let t in colors)
        colors[t] = globalStyle.colors[_style+'_disabled'];
 
    return (
      <TouchableOpacity
                style={[styles.button,colors,this.props.style]}
                onPress={this.props.onPress}
                disabled={this.props.disabled}
                underlayColor='#fff'>
                <Ptext style={[styles.title,this.props.styleTitle,{margin : 0,padding : 0,
                color : this.props.disabled ? globalStyle.colors.font_disabled : globalStyle.colors.font_main},this.props.title_style]}>{this.props.title}</Ptext>
       </TouchableOpacity>
    )
   }
};

const styles = StyleSheet.create({
  button : {
    marginTop : 12,
    marginBottom : 12,
    paddingTop: 12,
    paddingBottom : 12,
    borderWidth : 1,
    minHeight : 60, 
    alignItems : 'center',
    justifyContent : 'center',
    width : 306,
    alignSelf : 'center',
  },
  title : {
    textAlign : 'center',
    paddingLeft : 12,
    paddingRight : 12,
    margin : 2,
    letterSpacing: 2,
    textTransform: 'uppercase',
  
  }
});

export default Button;
