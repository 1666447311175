
import React, {Component} from 'react';
import { Animated, Easing, StyleSheet} from 'react-native';


class Chevron extends Component {
    constructor(props){
        super(props);
        this.spinValue = new Animated.Value(0);

        this.spin = this.spinValue.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', '180deg']
          });
        

    }

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.opened) {
            Animated.timing(
                this.spinValue,
              {
                toValue: 1,
                duration: 300,
                easing: Easing.linear,
             //   useNativeDriver: true,  // To make use of native driver for performance
              }
            ).start();
        }else {
            Animated.timing(
                this.spinValue,
              {
                toValue: 0,
                duration: 300,
                easing: Easing.linear,
             //   useNativeDriver: true,  // To make use of native driver for performance
              }
            ).start();
        }
    }
    
    render() {
        return (
            <Animated.View
                style={[styles_item.container, { transform: [{rotate: this.spin }] },{backgroundColor:'transparent', borderBottomWidth: 0}, this.props.style]}
                >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M6.19181 15.4076L6.58488 15.8034C6.70859 15.927 6.87318 15.9951 7.04908 15.9951C7.22489 15.9951 7.38967 15.927 7.51338 15.8034L11.9973 11.3193L16.4864 15.8084C16.6099 15.932 16.7747 16 16.9505 16C17.1263 16 17.2912 15.932 17.4148 15.8084L17.808 15.415C18.064 15.1592 18.064 14.7425 17.808 14.4867L12.4632 9.12268C12.3397 8.99916 12.1751 8.91214 11.9977 8.91214L11.9957 8.91214C11.8198 8.91214 11.6552 8.99926 11.5317 9.12268L6.19181 14.4722C6.0681 14.5957 6.00019 14.7653 6 14.9411C6 15.117 6.0681 15.2842 6.19181 15.4076Z" fill="#FF7C77"/>
                        </svg>
                </Animated.View>
            
        )
    }
}

export const LIST_ITEM_HEIGHT = 54;

const styles_item = StyleSheet.create({
    container: {
      backgroundColor: 'transparent',
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingVertical: 8,
      paddingHorizontal: 16,
      borderBottomWidth: 1,
      borderBottomColor: 'rgba(0, 0, 0, 0.1)',
      height: LIST_ITEM_HEIGHT
    },
    name: {
      fontSize: 16,
      paddingLeft: 16,
    },
    pointsContainer: {
      borderRadius: 8,
      backgroundColor: "#44c282",
      padding: 8
    },
    points: {
      color: "white",
      fontWeight: "bold"
    }
  });


export default Chevron;