import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity} from 'react-native';
import Ptext from "../../common/Ptext.js";
import Htext from "../../common/Htext.js";
import Button from "../../common/Button.js";
import ButtonArrow from "../../common/ButtonArrow.js";
import SocialAuthIcon from "./socialAuth.js"
import FormInput from "../../common/FormInput";



class AuthForm extends Component {



  onPressClose = () => {
    if (this.props.cbClose)
      this.props.cbClose(this.props.auth);
  }

  render () {
      return (

        <View style={styleAuth.wrapper}>
          <View style={styleAuth.horWrapperAuth}>
            <Htext style={styleAuth.headerCaption}>Вход в аккаунт</Htext>
            <TouchableOpacity style={styleAuth.headerCloseIcon} onPress={this.onPressClose}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
              <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
              </svg>
            </TouchableOpacity>
          </View>
          {
            this.props.messageHint !== undefined && this.props.messageHint !== '' ?
            <View style={styleAuth.hintWrapper}>
            <Ptext style={styleAuth.hint}>{this.props.messageHint}</Ptext>
            </View>
            : null
          }
          <FormInput value={this.props.email} onChangeText={this.props.emailInput}
                    autoCompleteType={'email'}
                    textContentType={'emailAddress'}
                    placeholder={'E-mail'}
                    style={styleAuth.forminput_margins}
                    />
          

          <FormInput value={this.props.passwd} onChangeText={this.props.passInput}
                    autoCompleteType={'password'}
                    textContentType={'password'}
                    placeholder={'Пароль'}
                    secureTextEntry={true}
                    style={styleAuth.forminput_margins}
                    />

          
          <Button title='Войти'
                onPress={this.props.loginPress}/>

          <ButtonArrow onPress={this.props.restorePress} title={'Забыли пароль?'}/>
         { 
	  false ? 
	  <>
          <Ptext style={styleAuth.soc_caption}>Войти через социальные сети</Ptext>
          <View style={styleAuth.horWrapperAuth}>
            <SocialAuthIcon type={'vk'}/>
            <SocialAuthIcon type={'facebook'}/>
            <SocialAuthIcon type={'instagramm'}/>
            <SocialAuthIcon type={'twitter'}/>
            <SocialAuthIcon type={'odnoklass'}/>
          </View>
          <View style={styleAuth.horWrapper}>
          </View>
	  </>
	  :
	   null
	  }
        </View>

      )

  }

}

const styleAuth = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    alignSelf : 'center',
    justifyContent : 'center',
    alignItems : 'center',
    width : '90%',
    maxWidth : 500,
  },
  horWrapper : {
    flexDirection : 'row',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'baseline',
    width : '100%',
    padding : 0,
    borderRadius : 4,
    borderWidth : 1,
    marginTop : 10,
    borderColor : '#B4BBC2',
    marginBottom : 8
  },
  horWrapperAuth : {
    flexDirection : 'row',
    alignSelf : 'center',
    justifyContent : 'space-around',
    alignItems : 'center',
    width : '100%',
    padding : 0,
    marginTop : 10,
    marginBottom : 8
  },
  forminput_margins : {
    marginBottom : 12,
    width: 306,
  },
  loginInput : {
    width : '100%',
    borderRadius : 4,
    borderColor : '#B4BBC2',
    height : 40,
    borderWidth : 1,
    paddingLeft : 10,
    marginTop : 10,
    marginBottom : 10,
    alignSelf : 'flex-start'
  },
  passInput : {
    flex : 1,
    borderRadius : 0,
    height : 40,
    paddingLeft : 10,
    alignSelf : 'flex-start'
  },
  loginButton : {
    margin : 0,
    marginTop : 0,
    marginBottom : 0,
    alignSelf : 'flex-end'
  },
  hint : {
    textAlign : 'left',
    alignSelf : 'flex-start',
    fontStyle : 'italic',
    backgroundColor : '#f0f1f3',
  },
  hintWrapper : {
    alignSelf : 'flex-start',
    justifyContent : 'center',
    alignItems : 'center',
    backgroundColor : '#f0f1f3',
    padding : 15,
  },
  forgot_password : {
    alignSelf : 'center',
    marginTop : 26,
  },
  forgot_password_caption : {
    fontSize : 16,
  },
  soc_caption : {
    alignSelf : 'center',
    marginTop : 26,
  },
  buttonReg : {
    backgroundColor : '#f0f1f3',
    borderColor : '#f0f1f3',
    width : '100%',
  },
  headerCaption : {
    textAlign : 'left',
    width : '90%',
  },
  headerCloseIcon : {
    width : '10%'
  }

});

//let hint = '#Оставлять комментарии могут только авторизованные участники.# Войдите в свой аккаунт или зарегистрируйтесь.';

class LoginPage extends Component {

  render () {
    return (
      <View style={styles.mainPageWrapper}>
        <AuthForm
         messageHint={this.props.messageHint}
         cbClose={this.props.cbClose}
         email={this.props.email}
         passwd={this.props.passwd}
         emailInput={this.props.emailInput}
         auth={this.props.auth}
         loginPress={this.props.loginPress}
         passInput={this.props.passInput}
         restorePress={this.props.restorePress}
         registerPress={this.props.registerPress}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  mainPageWrapper : {
    flexDirection : 'column',
    width : '100%',
    maxWidth : 510,
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    backgroundColor: '#fff',
  }
});

export default LoginPage;
