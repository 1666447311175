import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import Ptext from './Ptext.js';
import globalStyle from '../styles/globalStyle';

class ButtonBorderArrow extends Component {
  render () {
    let _style = this.props.hasOwnProperty('theme') ? this.props.theme : 'primary';

    switch (_style) {
      case 'light':
        _style = 'primary';
        break;
      case 'dark':
        _style = 'secondary';
        break;
      default:
        _style = 'primary';
    }



    return (
      <TouchableOpacity
                style={[styles.button,this.props.style]}
                onPress={this.props.onPress}
                disabled={this.props.disabled}
                underlayColor='#fff'>
                
                <svg width="178" height="44" viewBox="0 0 178 44" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft: 15, marginRight: 15}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M177.354 3.32843L174.172 0.146447C173.976 -0.0488155 173.66 -0.0488155 173.464 0.146447C173.269 0.341709 173.269 0.658291 173.464 0.853553L175.793 3.18198H0V3.68198V4.18198V4.68198V42.682V43.682H1H126H127V42.682V12.682H126V42.682H1V12.682H0.846667V4.18198H175.793L173.464 6.51041C173.269 6.70567 173.269 7.02225 173.464 7.21751C173.66 7.41278 173.976 7.41278 174.172 7.21751L177.354 4.03553C177.549 3.84027 177.549 3.52369 177.354 3.32843Z" fill="white"/>
                </svg>
                <Ptext style={[styles.title,this.props.styleTitle]}>{this.props.title}</Ptext>
                              
                
       </TouchableOpacity>
    )
   }
};

const styles = StyleSheet.create({
  button : {
    marginTop : 12,
    marginBottom : 12,
    paddingTop: 12,
    paddingBottom : 12,
    minHeight : 68, 
    minWidth: 215,
    flexDirection: 'row',
    alignItems : 'center',
    justifyContent : 'center',
    backgroundColor : 'black'
  },
  title : {
    textAlign : 'left',
    position: 'absolute',
    right: 102,
    top: 29,
    color : 'white',
    fontFamily: 'Prosto One',
    fontSize: 10, 
    letterSpacing: 2,
    lineHeight: 4,
    textTransform: 'uppercase',
  
  }
});

export default ButtonBorderArrow;
