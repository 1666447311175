import React,{Component} from 'react';
import { View, StyleSheet, Image, ImageBackground } from 'react-native';
import Ptext from "../../common/Ptext.js"
import ButtonPromo from "../../common/ButtonPromo.js"

class PromoAutoDescription extends Component {
  render () {
    return (
        <View style={styles.wrapper}>
          {this.props.fullWidth < 996 ? 
          <View style={{width:this.props.fullWidth, height : 332, justifyContent:'center',alignItems:'center'}}>
            <ImageBackground source={require('../../icons/Android/offer_mb.png')} style={{width: 320, height: 332, resizeMode: 'center'}}>
            <ButtonPromo title={'Купить за 299₽'} style={{position: 'absolute', left: 160-85, top: 332/2-10, width:174, minHeight:62,}} styleTitle={{fontSize:16}} onPress={() => this.props.history.push('/services/desc/185')}/>
            </ImageBackground>

          </View>
          :
          <View style={{width:this.props.fullWidth, height : 774, justifyContent:'center',alignItems:'center'}}>
            <ImageBackground source={require('../../icons/Desktop/offer_ds.png')} style={{width: 996, height: 614, resizeMode: 'center', paddingTop:80}}>
            <ButtonPromo title={'Купить за 299₽'} style={{position: 'absolute', left: 996/2-430/2, top: 387-148,width:387, minHeight: 133, padding: 20}} styleTitle={{fontSize:30}} onPress={() => this.props.history.push('/services/desc/185')}/>
            </ImageBackground>
        
          </View>
          }
      </View>

    )
  }


}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    flexWrap : 'wrap',
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    width : '100%',
    backgroundColor: '#fff',
  },
  captionAbout:{
    borderWidth: 1,
    borderColor: '#FFFFFF',
    backgroundColor: '#ffffff',
    shadowRadius: 10,
    shadowOffset: { height: 0, width: 3 },
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 1,
    padding: 30,
  },
  vertWrapper : {
    flexDirection : 'column',
    width : '100%',
    padding : 10,
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  imageStyle : {
    flex : 1,
    width : 400,
    height : 400,
    resizeMode : 'cover',
  },
  headerLabel : {
    fontSize : 24,
    fontFamily: 'Russo One',
    lineHeight : 24,
  },
  descrLabel : {
    width : '90%',
    fontSize : 14,
    textAlign : 'center',
    fontFamily: 'Roboto',
    color: 'rgba(0, 0, 0, 0.6)',

  },
  button : {
    marginLeft : 16,
    backgroundColor : '#F1F3F5',
    borderColor : '#F1F3F5',
  }

});

export default PromoAutoDescription;
