import React from 'react'
import { NavLink } from '../../router/react-router';

export default function BlogIcon({ title = 'Blog', style = {} , active = false, onPress = null, fontSize = 14, to={}})
{
  return (
    <NavLink to={to} style={style}
        activeStyle={{
        fontWeight: "bold",
        color: '#FF7C77',
      }}>

        {title}

    </NavLink>
  )
}
