import React, { Component } from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import Ptext from './Ptext.js';
import globalStyle from '../styles/globalStyle';

class ButtonPromo extends Component {
  render () {
    return (
      <TouchableOpacity
                style={[styles.button,this.props.style]}
                onPress={this.props.onPress}
                disabled={this.props.disabled}
                underlayColor='#fff'>
                <View style={styles.wrapperTitle}>
                  <Ptext style={[styles.title,{color : globalStyle.colors.font_main},this.props.styleTitle]}>{this.props.title}</Ptext>
                </View>
       </TouchableOpacity>
    )
   }
};

const styles = StyleSheet.create({
  button : {
    padding: 8,
    minHeight : 62, 
    alignItems : 'center',
    justifyContent : 'center',
    width : 174,
    alignSelf : 'center',
    backgroundColor: '#FFA7A4',
    opacity: 0.7,
    //
    borderRadius: 10,
  },
  wrapperTitle: {
    justifyContent: 'center',
    width: '100%',
    backgroundImage: 'linear-gradient(180deg, #FF566D 0%, #FF8C7B 100%)',
    opacity: 1,
    borderRadius: 10,
    flex : 1,
  },
  title : {
    textAlign : 'center',
    margin : 2,
    letterSpacing: 2,
    textTransform: 'uppercase',
    width : '100%'
  }
});

export default ButtonPromo;
