import $ from "jquery";
export class Ajax {
  constructor() {
    this._cookieName = null
    this._csrfToken = null
    this._locks = {}
    this._sessionId = null
  }

  init(cookieName) {
    this._cookieName = cookieName
  }

  getCsrfToken() {
    if (document.cookie.indexOf(this._cookieName) !== -1) {
      let cookieRegex = new RegExp(this._cookieName + "=([^;]*)")
      let cookie = document.cookie.match(cookieRegex)[0]
      return cookie ? cookie.split("=")[1] : null
    } else {
      return null
    }
  }

  request(method, cred ,url, data) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let xhr = {
        url: url,
        method: method,
        headers: {
          "X-CSRFToken": self.getCsrfToken(),
        },
        data: data ? JSON.stringify(data) : null,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        xhrFields: { withCredentials: cred },
        success: function(data, status, xhr) {
          resolve(data)
        },

        error: function(jqXHR) {
          let rejection = jqXHR.responseJSON || {}

          rejection.status = jqXHR.status

          if (rejection.status === 0) {
            rejection.detail = "Lost connection with application.";
          }

          if (rejection.status === 404) {
            if (!rejection.detail || rejection.detail === "NOT FOUND") {
              rejection.detail = "Action link is invalid.";
            }
          }

          if (rejection.status === 500 && !rejection.detail) {
            rejection.detail = "Unknown error has occured.";
          }

          rejection.statusText = jqXHR.statusText

          reject(rejection)
        }
      }

      $.ajax(xhr)
    })
  }

  request_u(method, cred ,url, data) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let xhr = {
        url: url,
        method: method,
        headers: {
          "X-CSRFToken": self.getCsrfToken(),
        },
        data: data ? data : null,
        contentType: false,
        processData: false,
        xhrFields: { withCredentials: cred },
        success: function(data, status, xhr) {
          resolve(data)
        },

        error: function(jqXHR) {
          let rejection = jqXHR.responseJSON || {}

          rejection.status = jqXHR.status

          if (rejection.status === 0) {
            rejection.detail = "Lost connection with application.";
          }

          if (rejection.status === 404) {
            if (!rejection.detail || rejection.detail === "NOT FOUND") {
              rejection.detail = "Action link is invalid.";
            }
          }

          if (rejection.status === 500 && !rejection.detail) {
            rejection.detail = "Unknown error has occured.";
          }

          rejection.statusText = jqXHR.statusText

          reject(rejection)
        }
      }

      $.ajax(xhr)
    })
  }

  get(cred, url, params, lock) {
    if (params) {
      url += "?" + $.param(params)
    }

    if (lock) {
      let self = this

      // update url in existing lock?
      if (this._locks[lock]) {
        this._locks[lock].url = url
      }

      // immediately dereference promise handlers without doing anything
      // we are already waiting for existing response to resolve
      if (this._locks[lock] && this._locks[lock].waiter) {
        return {
          then: function() {
            return
          }
        }

        // return promise that will begin when original one resolves
      } else if (this._locks[lock] && this._locks[lock].wait) {
        this._locks[lock].waiter = true

        return new Promise(function(resolve, reject) {
          let wait = function(url) {
            // keep waiting on promise
            if (self._locks[lock].wait) {
              window.setTimeout(function() {
                wait(url)
              }, 300)

              // poll for new url
            } else if (self._locks[lock].url !== url) {
              wait(self._locks[lock].url)

              // ajax backend for response
            } else {
              self._locks[lock].waiter = false
              self.request("GET", cred ,self._locks[lock].url).then(
                function(data) {
                  if (self._locks[lock].url === url) {

                    resolve(data)
                  } else {
                    self._locks[lock].waiter = true
                    wait(self._locks[lock].url)
                  }
                },
                function(rejection) {
                  if (self._locks[lock].url === url) {
                    reject(rejection)
                  } else {
                    self._locks[lock].waiter = true
                    wait(self._locks[lock].url)
                  }
                }
              )
            }
          }

          window.setTimeout(function() {
            wait(url)
          }, 300)
        })

        // setup new lock without waiter
      } else {
        this._locks[lock] = {
          url,
          wait: true,
          waiter: false
        }

        return new Promise(function(resolve, reject) {
          self.request("GET", cred, url).then(
            function(data) {
              self._locks[lock].wait = false
              if (self._locks[lock].url === url) {
                resolve(data)
              }
            },
            function(rejection) {
              self._locks[lock].wait = false
              if (self._locks[lock].url === url) {
                reject(rejection)
              }
            }
          )
        })
      }
    } else {
      return this.request("GET", cred, url)
    }
  }

  post(cred, url, data) {
    return this.request("POST", cred, url, data)
  }

  patch(cred, url, data) {
    return this.request("PATCH", cred, url, data)
  }

  put(cred, url, data) {
    return this.request("PUT", cred, url, data)
  }

  delete(cred, url, data) {
    return this.request("DELETE", cred, url, data)
  }

  put_upload(cred, url, data) {
    return this.request_u("PUT", cred, url, data)
  }

  upload(cred, url, data, progress) {
    let self = this
    return new Promise(function(resolve, reject) {
      let xhr = {
        url: url,
        method: "POST",
        headers: {
          "X-CSRFToken": self.getCsrfToken()
        },

        data: data,
        contentType: false,
        processData: false,

        xhr: function() {
          let xhr = new window.XMLHttpRequest()
          xhr.upload.addEventListener(
            "progress",
            function(evt) {
              if (evt.lengthComputable) {
                progress(Math.round((evt.loaded / evt.total) * 100))
              }
            },
            false
          )
          return xhr
        },

        success: function(response) {
          resolve(response)
        },

        error: function(jqXHR) {
          let rejection = jqXHR.responseJSON || {}

          rejection.status = jqXHR.status

          if (rejection.status === 0) {
            rejection.detail = "Lost connection with application.";
          }

          if (rejection.status === 413 && !rejection.detail) {
            rejection.detail = (
              "Upload was rejected by server as too large."
            )
          }

          if (rejection.status === 404) {
            if (!rejection.detail || rejection.detail === "NOT FOUND") {
              rejection.detail = "Action link is invalid.";
            }
          }

          if (rejection.status === 500 && !rejection.detail) {
            rejection.detail = "Unknown error has occured.";
          }

          rejection.statusText = jqXHR.statusText

          reject(rejection)
        }
      }

      $.ajax(xhr)
    })
  }
}

export default new Ajax()
