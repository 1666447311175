import React, {Component} from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import ButtonArrow from "../../common/ButtonArrow.js"
import Ptext from "../../common/Ptext.js"
import PullDownInfo from "../../common/pullDownInfo"

import globalStyle from "../../styles/globalStyle.js"
import { ScrollView } from 'react-native-web';

class QuestionIcon extends Component {
    render() {
        return (
            <svg width="28" height="49" viewBox="0 0 28 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5729 0.70166C16.9277 0.70166 20.4358 1.68956 23.0971 3.66537C25.7584 5.60085 27.089 8.24198 27.089 11.5888C27.089 14.8952 25.6979 17.6976 22.9156 19.996C20.1737 22.2944 16.4237 23.9678 11.6656 25.0162V33.8468H10.456V24.4718C13.6011 23.1009 15.7181 21.4879 16.8068 19.6331C17.8955 17.738 18.4398 15.4396 18.4398 12.738C18.4398 9.06859 17.7745 6.34682 16.4439 4.57263C15.1132 2.79843 13.2987 1.91134 11.0003 1.91134C9.87128 1.91134 8.8229 2.05247 7.85515 2.33472C6.92773 2.57666 6.22209 2.95972 5.73822 3.48392C7.99628 4.1694 9.12531 5.64118 9.12531 7.89924C9.12531 9.14924 8.68177 10.1976 7.79467 11.0444C6.90757 11.8509 5.75838 12.2541 4.34709 12.2541C3.09709 12.2541 2.06886 11.8509 1.26241 11.0444C0.45596 10.238 0.0527344 9.25005 0.0527344 8.08069C0.0527344 6.91134 0.355154 5.92343 0.959992 5.11698C1.56483 4.27021 2.41161 3.50408 3.50032 2.8186C5.87935 1.40731 8.90354 0.70166 12.5729 0.70166ZM11.1213 39.5323C12.6939 39.5323 13.9439 39.9557 14.8713 40.8025C15.7987 41.6089 16.2624 42.7379 16.2624 44.1896C16.2624 45.6412 15.7987 46.7904 14.8713 47.6371C13.9439 48.4436 12.6939 48.8468 11.1213 48.8468C9.5487 48.8468 8.2987 48.4436 7.37128 47.6371C6.44386 46.7904 5.98015 45.6412 5.98015 44.1896C5.98015 42.7379 6.44386 41.6089 7.37128 40.8025C8.2987 39.9557 9.5487 39.5323 11.1213 39.5323Z" fill="#FF7C77"/>
            </svg>
        )
    }
}

class FaqPageView extends Component {

  

  render () {
    
    return (
      <View style={[styles.page,{width: '100%', alignSelf : 'center'}]} >
        <TouchableOpacity style={[styles.header_wrapper]} onPress={() => this.props.history.push('/faq/')}>
          <View style={[styles.bar,{width : this.props.fullWidth > 510 ? 120 : 24}]}/>
          <Ptext style={styles.header_caption}> Частые вопросы</Ptext>
        </TouchableOpacity>
        <View style={[styles.faqWrapper,{paddingHorizontal : this.props.fullWidth > 510 ? 124 : 10}]}>
        {this.props.faq !== undefined ?

            this.props.faq.map((item, index,arr)=> {
                return (
                <PullDownInfo icon={QuestionIcon}
                    fullWidth={'100%'}
                    key={index}
                    header={item.header}
                    desc={item.infoText}
                    style={{marginTop: 10}}
                >
                
                    <QuestionIcon/>
                </PullDownInfo>
                )
            })
        :
        null
        }
        </View>
        <ButtonArrow title={'Все вопросы'} onPress={() => this.props.history.push('/faq/')}/>
      </View>
    );
  }
}
//
const styles = StyleSheet.create({
  page : {
    flexDirection : 'column',
    backgroundColor: globalStyle.colors.matrix_page_background,
    alignItems : 'center',
    alignContent: 'flex-start',
    justifyContent : 'flex-start'
   
  },
  faqWrapper: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    width: '100%',
  },
  contentContainer:{
    flex: 1,
    alignSelf: 'center',
    alignItems:'center',
    justifyContent: 'flex-start',
  },
  bar : {
    width : 24,
    height : 1,
    backgroundColor : globalStyle.colors.secondary,
  },
  header_wrapper : {
    width : '100%',
    height: 74,
    alignSelf : 'flex-start',
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
    backgroundColor: globalStyle.colors.matrix_page_background,
  },
  header_caption : {
    letterSpacing: 2,
    textTransform: 'uppercase',
    fontSize : 18,
    lineHeight : 20,
    fontStyle : 'normal',
    fontWeight : 'normal',
  }
})

export default FaqPageView;
