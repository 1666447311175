import React from 'react'
import {StyleSheet} from 'react-native'
import Ptext from "../../../common/Ptext.js"
import { TouchableOpacity } from 'react-native';

export default function LoginIcon({ size = 40, title = 'Войти', style = {} , active = false, cbOnClick = null  })
{
  return (
    <TouchableOpacity style={styles.view} onClick={cbOnClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M8.24976 -0.00012207C6.99865 -0.00012207 6.00268 1.01341 6.00268 2.24693V8.24841C6.00268 9.2711 7.50269 9.2711 7.50269 8.24841V2.24693C7.50269 1.81847 7.84179 1.49986 8.24976 1.49986H21.7541C22.1826 1.49986 22.5012 1.84776 22.5012 2.24693V21.7528C22.5012 22.1578 22.1826 22.4999 21.7541 22.4999H8.24976C7.82128 22.4999 7.50269 22.1578 7.50269 21.7528V15.7513C7.50269 14.7584 6.00268 14.7437 6.00268 15.7513V21.7528C6.00268 23.001 7.01623 23.9999 8.24976 23.9999H21.7541C22.9877 23.9999 24.0012 23.001 24.0012 21.7528V2.24693C24.0012 1.01341 23.0023 -0.00012207 21.7541 -0.00012207H8.24976ZM11.4724 8.786L13.9363 11.2499H0.74829C-0.250732 11.2499 -0.250732 12.7499 0.74829 12.7499H13.9407L11.4724 15.2137C10.7634 15.9227 11.8269 16.9862 12.5359 16.2772L16.2844 12.5287C16.5761 12.237 16.5761 11.7627 16.2844 11.4711L12.5359 7.72253C11.7841 7.03779 10.8041 8.05829 11.4724 8.786Z" fill="#FF7C77"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </TouchableOpacity>
  )
}
const styles = StyleSheet.create({
  view:{
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight : 16,
    marginLeft : 20,
    height: 56,
  },
});
