import React, {Component} from 'react';
import SideMenu from "../../components/menu/sidemenu.js"
import Modal from 'modal-react-native-web';



class SideProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ajax = props.ajax;
  }

  logOut = () => {
    this.ajax.post(true,this.props.debug_url+"/forum/logout/",{})
    .then(
      (data) => {
        this.props.logOut();
        this.props.cbCloseProfile();
      }
    )
    .catch(
      (rejection) => {
        if (rejection.status !== undefined) {
          if (rejection.status === 200){
            this.props.logOut();
            this.props.cbCloseProfile();
          } else {
            console.log(rejection);
            this.props.logOut();
            this.props.cbCloseProfile();

          }
        }else {
          console.log(rejection);
          alert(rejection.detail);
          this.props.logOut();
          this.props.cbCloseProfile();
        }
      }
    )
  };

  _closeTab = () =>  {
    //// TODO: SUPER UGLY PLACE
    this.props.cbCloseProfile();
  }

  render () {

    return (
      <Modal style={{width : '100%'}} visible={this.props.opened}
        transparent={false} ariaHideApp={false} animationType={'fade'}>
      <SideMenu cbCloseMenu={this.props.cbCloseProfile}
        logOut={this.logOut} closeTab={this._closeTab}
        user={this.props.user} fullWidth={this.props.fullWidth}
        debug_url={this.props.debug_url}
        openImagePicker={this.props.openImagePicker}
        />
      </Modal>
    )
  }

}

export default SideProfileContainer;
