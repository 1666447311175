import React,{Component} from 'react';
import { View, StyleSheet, Image, ImageBackground } from 'react-native';
import Ptext from "../../common/Ptext.js"
import Button from "../../common/Button.js"

class MainBanner extends Component {
  render () {
    return (
        <View style={styles.wrapper}>
          {this.props.fullWidth < 800 ? 
          <View style={{width:this.props.fullWidth, height : 565}}>
            <ImageBackground source={require('../../icons/Android/Background_01.png')} style={{width: this.props.fullWidth, height: 390, resizeMode: 'contain'}}>
            <Image source={require('../../icons/Android/Background_02.png')} style={{width: 190, height: 390, position: 'absolute', top:0, left:((this.props.fullWidth/2)-103)}}/>
            <Image source={require('../../icons/Android/Main_image_black_mob.png')} style={{width: 229, height: 390, position: 'absolute', top:0, left: this.props.fullWidth/2-120}}/>
            </ImageBackground>
            <View style={[styles.captionAbout,{position: 'absoulte',top:-40, left: this.props.fullWidth/2-(this.props.fullWidth > 380 ? 360: this.props.fullWidth-20)/2, width: this.props.fullWidth-20, maxWidth: 360}]}>
              <Ptext style={styles.headerLabel}>Ольга Перцева</Ptext>
              <Ptext style={styles.descrLabel}>Нумеролог,</Ptext>
              <Ptext style={styles.descrLabel}>Коуч – тренер личностного роста,</Ptext>
              <Ptext style={styles.descrLabel}>Автор уникальной методики статистического психоанализа.</Ptext>

            </View>
          </View>
          :
          <View style={{width:this.props.fullWidth, height : 774}}>
            <ImageBackground source={require('../../icons/Desktop/Background_01.png')} style={{width: this.props.fullWidth, height: 774, resizeMode: 'contain'}}>
            <Image source={require('../../icons/Desktop/Background_02.png')} style={{width: this.props.fullWidth > 1140 ? 1140 : this.props.fullWidth, height: 774, position: 'absolute', top:0, left:0}}/>
            <Image source={require('../../icons/Desktop/Main_image_black_desk.png')} style={{width: 442, height: 774, position: 'absolute', top:0, left: this.props.fullWidth/2-120}}/>
            </ImageBackground>
            <View style={[styles.captionAbout,{position: 'absoulte',top:-530, left: this.props.fullWidth/2-550+27, width: this.props.fullWidth-20, maxWidth: 550, height : 270, padding: 50}]}>
              <Ptext style={[styles.headerLabel, {fontSize : 46, marginBottom:20}]}>Ольга Перцева</Ptext>
              <Ptext style={[styles.descrLabel,{fontSize: 16}]}>Нумеролог,</Ptext>
              <Ptext style={[styles.descrLabel,{fontSize: 16}]}>Коуч – тренер личностного роста,</Ptext>
              <Ptext style={[styles.descrLabel,{fontSize: 16}]}>Автор уникальной методики статистического психоанализа.</Ptext>
            </View>
          </View>
          }
      </View>

    )
  }


}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    flexWrap : 'wrap',
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    width : '100%',
    backgroundColor: '#fff',
  },
  captionAbout:{
    borderWidth: 1,
    borderColor: '#FFFFFF',
    backgroundColor: '#ffffff',
    shadowRadius: 10,
    shadowOffset: { height: 0, width: 3 },
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 1,
    padding: 30,
  },
  vertWrapper : {
    flexDirection : 'column',
    width : '100%',
    padding : 10,
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  imageStyle : {
    flex : 1,
    width : 400,
    height : 400,
    resizeMode : 'cover',
  },
  headerLabel : {
    fontSize : 24,
    fontFamily: 'Russo One',
    lineHeight : 24,
  },
  descrLabel : {
    width : '90%',
    fontSize : 14,
    textAlign : 'center',
    fontFamily: 'Roboto',
    color: 'rgba(0, 0, 0, 0.6)',

  },
  button : {
    marginLeft : 16,
    backgroundColor : '#F1F3F5',
    borderColor : '#F1F3F5',
  }

});

export default MainBanner;
