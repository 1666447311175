import React, { PureComponent } from 'react';
import { View, Image, StyleSheet } from 'react-native';
  
import Ptext from "../../common/Ptext.js"
import Button from "../../common/Button.js"
import HTMLView from 'react-native-htmlview';
import { NavLink } from '../router/react-router';


/*
https://medium.com/the-react-native-log/custom-scrolling-carousel-in-react-native-15ee129e7e68
*/
class ImageCarousel extends PureComponent {

    render () {
      if (this.props.items !== null)
      return (
        <Image source={this.props.items.file}
          style={{width:this.props.scrollWidth,height:this.props.scrollWidth}}
        />
      )
      else return (
        <View/>
      )
    }
}

/*
class ItemActivityButtons extends PureComponent{

  like = () => {
    if (this.props.auth)
      this.props.wagtail.debug_add_post_like(this.props.post_id,this.props.likeCb);
    else
      this.props.login();
  }
  comment = () => {
    this.props.history.push('/blog/view/'+this.props.post_id+'/comments');
  }
  share = () => {
    console.log('share'  + this.props.post_id);
  }
  favorits = () => {
    if (this.props.auth)
      this.props.wagtail.debug_add_fav_post(this.props.post_id,this.props.favsCb);
    else
      this.props.login();
  }

  render () {
    return (
      <View style={styles.wrapperHorizontal}>
        <TouchableOpacity onPress={this.like} style={{marginLeft:6,marginRight:6}}>
          {(this.props.like == null || !this.props.like.liked) ?
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1 18.55L12 18.65L11.89 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 6 11.07 7.36H12.93C13.46 6 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55ZM16.5 3C14.76 3 13.09 3.81 12 5.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5C2 12.27 5.4 15.36 10.55 20.03L12 21.35L13.45 20.03C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3Z" fill="#B4BBC2"/>
            </svg>
            :
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 12.27 18.6 15.36 13.45 20.03L12 21.35Z" fill="red"/>
            </svg>
          }
        </TouchableOpacity>
        <TouchableOpacity onPress={this.comment} style={{marginLeft:6,marginRight:6}}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 22C8.73478 22 8.48043 21.8946 8.29289 21.7071C8.10536 21.5196 8 21.2652 8 21V18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V4C2 2.89 2.9 2 4 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4V16C22 16.5304 21.7893 17.0391 21.4142 17.4142C21.0391 17.7893 20.5304 18 20 18H13.9L10.2 21.71C10 21.9 9.75 22 9.5 22H9ZM10 16V19.08L13.08 16H20V4H4V16H10Z" fill="#B4BBC2"/>
          </svg>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.share} style={{marginLeft:6,marginRight:6}}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C18.7956 8 19.5587 7.68393 20.1213 7.12132C20.6839 6.55871 21 5.79565 21 5C21 4.20435 20.6839 3.44129 20.1213 2.87868C19.5587 2.31607 18.7956 2 18 2C17.2044 2 16.4413 2.31607 15.8787 2.87868C15.3161 3.44129 15 4.20435 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12C3 12.7956 3.31607 13.5587 3.87868 14.1213C4.44129 14.6839 5.20435 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.34C15.11 18.55 15.08 18.77 15.08 19C15.08 20.61 16.39 21.91 18 21.91C19.61 21.91 20.92 20.61 20.92 19C20.92 18.2256 20.6124 17.4829 20.0648 16.9352C19.5171 16.3876 18.7744 16.08 18 16.08Z" fill="#B4BBC2"/>
          </svg>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.favorits} style={{marginLeft:6,marginRight:6}}>
        {(this.props.fav == null || !this.props.fav.favs) ?
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.798 22L12 17.8774L5.202 22L6.995 14.23L1 9.0021L8.909 8.31684L12 1L15.091 8.31684L23 9.0021L16.994 14.23L18.798 22ZM15.708 17.4545L12 15.2058L8.292 17.4545L9.27 13.2164L6 10.3648L10.314 9.991L12 6L13.686 9.991L18 10.3648L14.724 13.2164L15.708 17.4545Z" fill="#B4BBC2"/>
          </svg>
          :
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.798 22L12 17.8774L5.202 22L6.995 14.23L1 9.0021L8.909 8.31684L12 1L15.091 8.31684L23 9.0021L16.994 14.23L18.798 22Z" fill="orange"/>
          </svg>
        }
        </TouchableOpacity>
      </View>

    )
  }
}

class ItemActivityButtonsVert extends PureComponent{


  like = () => {
    if (this.props.auth)
      this.props.wagtail.debug_add_post_like(this.props.post_id,this.props.likeCb);
    else
      this.props.login();
  }
  comment = () => {
    this.props.history.push('/blog/view/'+this.props.post_id+'/comments');
  }
  share = () => {
    console.log('share ' + this.props.post_id);
  }
  favorits = () => {

    if (this.props.auth)
      this.props.wagtail.debug_add_fav_post(this.props.post_id,this.props.favsCb);
    else
      this.props.login();

  }

  render () {
    return (
      <View style={[styles.wrapperVertical,
          {width : '100%',
          alignSelf:'flex-start',
          shadowOpacity: 0,
          shadowRadius: 0,
          shadowColor: '#000',
          shadowOffset: { height: 0, width: 0 }}]}>
        <TouchableOpacity onPress={this.like} style={{margin:6,flexDirection:'row'}}>
          {(this.props.like == null || !this.props.like.liked) ?
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1 18.55L12 18.65L11.89 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 6 11.07 7.36H12.93C13.46 6 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55ZM16.5 3C14.76 3 13.09 3.81 12 5.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5C2 12.27 5.4 15.36 10.55 20.03L12 21.35L13.45 20.03C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3Z" fill="#B4BBC2"/>
            </svg>
            :
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 12.27 18.6 15.36 13.45 20.03L12 21.35Z" fill="red"/>
            </svg>
          }
          <Ptext> Нравится</Ptext>
        </TouchableOpacity>

        <TouchableOpacity onPress={this.share} style={{margin:6,flexDirection:'row'}}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C18.7956 8 19.5587 7.68393 20.1213 7.12132C20.6839 6.55871 21 5.79565 21 5C21 4.20435 20.6839 3.44129 20.1213 2.87868C19.5587 2.31607 18.7956 2 18 2C17.2044 2 16.4413 2.31607 15.8787 2.87868C15.3161 3.44129 15 4.20435 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12C3 12.7956 3.31607 13.5587 3.87868 14.1213C4.44129 14.6839 5.20435 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.34C15.11 18.55 15.08 18.77 15.08 19C15.08 20.61 16.39 21.91 18 21.91C19.61 21.91 20.92 20.61 20.92 19C20.92 18.2256 20.6124 17.4829 20.0648 16.9352C19.5171 16.3876 18.7744 16.08 18 16.08Z" fill="#B4BBC2"/>
          </svg>
          <Ptext> Поделится</Ptext>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.favorits} style={{margin:6,flexDirection:'row'}}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.798 22L12 17.8774L5.202 22L6.995 14.23L1 9.0021L8.909 8.31684L12 1L15.091 8.31684L23 9.0021L16.994 14.23L18.798 22ZM15.708 17.4545L12 15.2058L8.292 17.4545L9.27 13.2164L6 10.3648L10.314 9.991L12 6L13.686 9.991L18 10.3648L14.724 13.2164L15.708 17.4545Z" fill="#B4BBC2"/>
          </svg>
          <Ptext> В избранное</Ptext>
        </TouchableOpacity>
      </View>

    )
  }
}
*/

class BlogListItemIndex extends PureComponent {


  showFullPost = () => {
    if (this.props.showFullPost)
      this.props.showFullPost(this.props.id);
  }
  _openComments = () => {
      this.props.history.push('/blog/view/'+this.props.post.id+'/comments');
  }

  _like_handler = (data) => {
    this.props.likeCb(this.props.post.id, data);
  }
  _favs_handler = (data) => {
    console.log(data);
    console.log(this.props);
    this.props.favsCb(this.props.post.id, data);
  }

  render () {
    if (this.props.post === undefined)
      return null;
    if (this.props.post.hasOwnProperty('body') === false)
      return null;
    var trimmedString = this.props.post.body;
    trimmedString += '</div>';
    trimmedString = '<div>'+trimmedString;
    var yourString = this.props.post.body; //replace with your string.
    var maxLength = 400; // maximum number of characters to extract

    //trim the string to the maximum length
    trimmedString = yourString.substr(0, maxLength);

    //re-trim if we are in the middle of a word
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    trimmedString += '...';

    if (this.props.scrollWidth<720)
    return (


      <View style={styles.wrapperVertical}>
        <Ptext style={styles.mainTitle}>{this.props.post.title}</Ptext>
        <ImageCarousel items={this.props.post.header_image} scrollWidth={this.props.scrollWidth}/>
        <View style={styles.contentWrapper}>
          <View style={styles.wrapperHorizontal}>
            <Ptext style={styles.title}>{this.props.post.title}</Ptext>
            <Ptext style={styles.time}>{this.props.post.last_published_at}</Ptext>
          </View>

          {this.props.post.likes !== null ?
            <Ptext style={styles.liketitle}>Нравится: {this.props.post.likes.cnt}</Ptext>
            :
            null}
          <HTMLView value={trimmedString} style={styles.post}/>
            <NavLink style={{textDecoration : 'none'}} to={'/blog/list'}>
              <Button title={'Перейти в блог'} style={styles.button}/>
            </NavLink>

        </View>
      </View>


    )
    else
    // !!!!!!!!!! WIDTH >= 720 ADAPTIVE
    return (
      <View style={[styles.wrapperHorizontal,{alignSelf : 'center',
        width : '100%',flexWrap : 'wrap','backgroundColor':'#fff'}]}>
        <View style={[styles.wrapperVertical,
            { alignItems : 'stretch',
              alignSelf : 'flex-start',
              justifyContent : 'flex-start',
              paddingTop : 24,
              width:'35%',
              paddingLeft : 12,
              shadowOpacity: 0,
            }]}>
          <ImageCarousel items={this.props.post.header_image} scrollWidth={216}/>
          {this.props.post.likes !== null ?
              <Ptext style={styles.liketitle}>Нравится: {this.props.post.likes.cnt}</Ptext>
              :
              null}
        </View>

        <View style={[styles.contentWrapper,
            { paddingTop : 24,flex : 1}]}>
          <Ptext style={styles.title}>{this.props.post.title}</Ptext>
          <HTMLView value={trimmedString} style={styles.post}/>

          <NavLink style={{textDecoration : 'none'}} to={'/blog/list'}>
            <Button title={'Перейти в блог'} style={styles.button}/>
          </NavLink>

        </View>
        <View style={{width:'10%',
          height:'100%',
          backgroundColor :'#fff',
          flexDirection : 'row',
          paddingTop:24}}>
          <Ptext style={[styles.time]}>{this.props.post.last_published_at}</Ptext>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  wrapperVertical : {
    flexDirection : 'column',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    width : '100%',
    backgroundColor : '#fff',
    paddingTop : 0,
    borderBottomLeftRadius : 4,
    borderBottomRightRadius : 4,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowColor: '#000',
    marginBottom : 4,
    shadowOffset: { height: 1, width: 0 },
  },
  wrapperHorizontal : {
    flexDirection : 'row',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    width : '100%',
    marginTop : 16,
  },
  contentWrapper : {
    flexDirection : 'column',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    flex : 1,
    backgroundColor : '#fff',
    width:'95%',
  },
  title : {
    flex : 1,
    fontSize : 20,
    lineHeight : 24,
    textAlign : 'left',
    fontWeight : 'bold',
  },
  mainTitle : {
    fontSize : 20,
    lineHeight : 24,
    textAlign : 'left',
    fontWeight : 'bold',
    padding : 16,
  },
  liketitle : {
    fontSize : 14,
    lineHeight : 24,
    textAlign : 'left',
    fontWeight : 'bold',
    marginTop : 16,
    height : 24,
  },
  post : {
    flex : 1,
    fontSize : 14,
    lineHeight : 24,
    textAlign : 'left',
    marginTop : 16,

  },
  time : {
    textAlign : 'right',
    fontSize : 12,
    lineHeight : 16,
    color : '#B4BBC2'
  },
  button : {
    backgroundColor : '#fff',
    borderColor : '#b4bbc2',
    margin : 0,
    marginTop : 16,
    minWidth : 95,
    height : 32,
  }

})

export default BlogListItemIndex;
