import React, {Component} from 'react'
import {ScrollView, StyleSheet} from 'react-native'
import List from "./menuItemMobile"




class LeftMenu extends Component {


    render () {
        return (
            <ScrollView style={[styles.wrapper,{height: this.props.scrollHeight}]}
                contentContainerStyle={styles.scrollContainer}>
                {
                    this.props.menu.map((item) => {
                        return (
                            <List items={item.items} title={item.title} url={item.url} style={{width:'100%'}} closeMenu={this.props.closeMenu} history={this.props.history}/>
                        )
                    })
                
                }
            </ScrollView>
        )
    }
}

const styles=StyleSheet.create({
    wrapper: {
        width: '100%',
        backgroundColor : '#F2F2F2',
    },
    scrollContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    }

});


export default LeftMenu;