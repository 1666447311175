import React, {Component} from 'react';
import {View, Animated, TouchableWithoutFeedback, StyleSheet, Easing} from 'react-native';
import Ptext from "../../common/Ptext"
import { TouchableOpacity } from 'react-native-web';
import { NavLink } from '../../components/router/react-router';
import Chevron, {LIST_ITEM_HEIGHT} from "../../common/chevron"









class ListItem extends Component{
  
  render () {
    const bottomRadius = this.props.isLast ? 8 : 0;
    return (
        <View
        style={[
            styles_item.container,
            {
            borderBottomLeftRadius: bottomRadius,
            borderBottomRightRadius: bottomRadius,
            marginLeft: 15,
            }
        ]}
        >
        
            <NavLink to={this.props.item.url } style={{textDecoration : 'none'}}>
            <TouchableOpacity onPress={() => {setTimeout(this.props.closeMenu, 300);}}>
            <Ptext style={styles.title}>
            {this.props.item.name}
            </Ptext>
            </TouchableOpacity>
            </NavLink>        
            
       

        </View>
    )
  }
};


const styles_item = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
    height: LIST_ITEM_HEIGHT
  }
});


class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opened : true,
            height : 0,
        }
    }

    componentDidMount() {
        this.setState({opened:false});
    }

    _open = () => {
        if (this.props.items !== undefined && this.props.items.length > 1) {
            var height = ((this.state.opened ? 0 : 1)*this.props.items.length)*56;
            this.setState({opened: !this.state.opened, height : height}); 
        }else {
            console.log(this.props);
            //this.props.history.push(this.props.url);
            setTimeout(this.props.closeMenu, 300);

        }
    }

    render () {
        return (
        <>
        <TouchableWithoutFeedback onPress={this._open}>
            <Animated.View
            style={[
                styles.container,
                {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
                }
            ]}
            >
            
            {this.props.items !== undefined && this.props.items.length > 1 ? 
            <>
            <Ptext style={styles.title}>{this.props.title}</Ptext>
            <Chevron opened={!this.state.opened} />
            </>
            :
            <NavLink to={this.props.url } style={{textDecoration : 'none'}}>
            <Ptext style={styles.title}>
            {this.props.title}
            </Ptext>
            </NavLink>   
            }
            </Animated.View>
        </TouchableWithoutFeedback>
        <View style={[styles.items, { height : this.state.height }]}>
        
        {this.props.items !== undefined && this.props.items.length > 1 && this.state.opened  ? 
        this.props.items.map((item, key) => (
          <ListItem {...{ item, key }} isLast={key === this.props.items.length - 1} closeMenu={this.props.closeMenu}/>
        ))
        :
        null
        
        }
        </View>
        </>
        )   
    }
}

const styles = StyleSheet.create({
   wrapper:{

   },
   container:{
    flexDirection: 'row',
    alignItems : 'center',
    justifyContent : 'space-between',
    alignSelf : 'center',
    height : 48,
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)',
    borderBottomWidth: 1,
   },
   title:{
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    letterSpacing: 2,
    textTransform: 'uppercase',
    fontSize: 14,
   },
   items: {
       width : '100%',
       height : 0,
   }
});


export default List;