import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import Ptext from "../../common/Ptext.js"

/*

        <View style={styles.colAccount}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 3C3.89 3 3 3.9 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V16.72C21.59 16.37 22 15.74 22 15V9C22 8.26 21.59 7.63 21 7.28V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5ZM5 5H19V7H13C12.4696 7 11.9609 7.21071 11.5858 7.58579C11.2107 7.96086 11 8.46957 11 9V15C11 15.5304 11.2107 16.0391 11.5858 16.4142C11.9609 16.7893 12.4696 17 13 17H19V19H5V5ZM13 9H20V15H13V9ZM16 10.5C15.6022 10.5 15.2206 10.658 14.9393 10.9393C14.658 11.2206 14.5 11.6022 14.5 12C14.5 12.3978 14.658 12.7794 14.9393 13.0607C15.2206 13.342 15.6022 13.5 16 13.5C16.3978 13.5 16.7794 13.342 17.0607 13.0607C17.342 12.7794 17.5 12.3978 17.5 12C17.5 11.6022 17.342 11.2206 17.0607 10.9393C16.7794 10.658 16.3978 10.5 16 10.5Z" fill="black"/>
          </svg>
          <Ptext fontSize='16' style={{fontWeight : 'bold'}}>
          0
          </Ptext>
          <Ptext fontSize='14' >
          на счёте
          </Ptext>

        </View>

*/

class MenuStatistic extends Component {
  render () {
    return (
      <View style={[styles.statMain,this.props.style]}>
        <View style={styles.colBlog}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{align : 'center'}}>
          <path d="M13 7.5H18V9.5H13V7.5ZM13 14.5H18V16.5H13V14.5ZM19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19ZM19 19V5H5V19H19ZM11 6V11H6V6H11ZM10 10V7H7V10H10ZM11 13V18H6V13H11ZM10 17V14H7V17H10Z" fill="black"/>
          </svg>
          <Ptext fontSize='16' style={{fontWeight : 'bold'}}>
          {this.props.comment_cnt ? this.props.comment_cnt : 'нет'}
          </Ptext>
          <Ptext fontSize='14' >
          комментариев
          </Ptext>
        </View>
        <View style={styles.colChat}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.0833 22.1667C11.8402 22.1667 11.6071 22.0701 11.4352 21.8982C11.2632 21.7263 11.1667 21.4931 11.1667 21.25V18.5H7.5C7.01377 18.5 6.54745 18.3068 6.20364 17.963C5.85982 17.6192 5.66667 17.1529 5.66667 16.6667V7.5C5.66667 7.01377 5.85982 6.54745 6.20364 6.20364C6.54745 5.85982 7.01377 5.66667 7.5 5.66667H20.3333C20.8196 5.66667 21.2859 5.85982 21.6297 6.20364C21.9735 6.54745 22.1667 7.01377 22.1667 7.5V16.6667C22.1667 17.1529 21.9735 17.6192 21.6297 17.963C21.2859 18.3068 20.8196 18.5 20.3333 18.5H16.575L13.1833 21.9008C13 22.0658 12.78 22.1667 12.5417 22.1667H12.0833ZM13 16.6667V19.49L15.8233 16.6667H20.3333V7.5H7.5V16.6667H13ZM3.83333 14.8333H2V3.83333C2 3.3471 2.19315 2.88079 2.53697 2.53697C2.88079 2.19315 3.3471 2 3.83333 2H18.5V3.83333H3.83333V14.8333ZM9.33333 9.33333H18.5V11.1667H9.33333V9.33333ZM9.33333 13H16.6667V14.8333H9.33333V13Z" fill="black"/>
          </svg>
          <Ptext fontSize='16' style={{fontWeight : 'bold'}}>
          {this.props.message_cnt ? this.props.comment_cnt : 'нет'}
          </Ptext>
          <Ptext fontSize='14' >
          сообщений
          </Ptext>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  statMain :{
    flexDirection : 'row',
    alignItems : 'center',
    alignContent : 'center',
    justifyContent : 'space-around',
    height : 121,
    width : '100%',
    backgroundColor : '#F1F3F5'
  },
  colAccount : {
    width : "33.3%",
    height : 70,
    flexDirection : 'column',
    justifyContent : 'space-around',
    alignItems : 'center'
  },
  colBlog : {
    width : "33.3%",
    height : 70,
    flexDirection : 'column',
    justifyContent : 'space-around',
    alignItems : 'center'
  },
  colChat : {
    width : "33.3%",
    height : 70,
    flexDirection : 'column',
    justifyContent : 'space-around',
    alignItems : 'center'
  }
})

export default MenuStatistic;
