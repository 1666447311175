import React, { Component } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import FavouritsList from "./FavouritsList.js"
import Htext from "../../common/Htext.js"

import { Dimensions } from 'react-native';


class FavouritsMenu extends Component {
  state = {
    s_width : '100%',
  };
  constructor(props){
    super(props);

    let dims_w = Dimensions.get("screen");

    if (dims_w.width < 540)
      this.state.s_width = dims_w.width;
    else
      this.state.s_width = 320;

  }

  dims_changed = dims => {
    let wd = '';
    if (dims.screen.width < 540){
        wd = dims.screen.width;
    }
    else {
        wd = 320;
    }
    this.setState({s_width : wd});
  }

  componentWillMount() {
        Dimensions.addEventListener("change", this.dims_changed);

  }

  componentWillUnmount() {
    // Important to stop updating state after unmount
      Dimensions.removeEventListener("change", this.dims_changed);

  }




  render () {
    let {fadeAnim} = this.state;
    return (
      <View style={[this.props.style,styles.sidemenu,{width : this.state.s_width, opacity:fadeAnim}]}>
        <View style={styles.header}>
          <Htext style={styles.header_text}>Сохраненные</Htext>
          <View style={styles.headerClose}>
            <TouchableOpacity onPress={this.props.cbClose} >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
            <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
            </svg>
          </TouchableOpacity>
          </View>
        </View>
        <FavouritsList style={styles.listStyle} {...this.props}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  sidemenu :{
    right : 0,
    top : 0,
    bottom : 0,
    position : 'absolute',
    justifyContent: 'flex-start',
    alignItems : 'center',
    flexDirection : 'column',
    shadowOpacity: 0.5,
    shadowRadius: 5,
    minHeight : 320,
    backgroundColor : '#fff',
    zIndex : 7,
    shadowColor: '#A4ACB3',
    shadowOffset: { height: 3, width: 0 },
  },
  header : {
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
    width : '100%',
    padding : 12,
    margin : 12,
    shadowColor: '#A4ACB3',

  },
  header_text : {
    fontSize : 20,
    width : '90%',
    shadowColor: '#A4ACB3',
    textAlign : 'left',
    paddingLeft : 12,
  },
  listStyle : {
    width : '100%',
    padding: 10,
    shadowColor: '#A4ACB3',
  },
  headerClose : {

  }

})

export default FavouritsMenu;
