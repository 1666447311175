import React,{ Component } from 'react'
import RegisterPage from "../../components/register/registerPage.js"


class RegisterContainer extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.form = {
      privacy_policy : null,
      terms_of_service : null,
    };
    this.state.captcha = {};
    this.renewCaptchaPress();
  }


  passInput = (value) => {
    let s = this.state;
    s.form.password = value;
    this.setState(s);
  }
  emailInput = (value) => {
    let s = this.state;
    s.form.email = value;
    this.setState(s);
  }
  captchaInput = (value) => {
    let s = this.state;
    s.form.captcha = value;
    this.setState(s);
  }
  userInput = (value) => {
    let s = this.state;
    s.form.username = value;
    this.setState(s);
  }

  renewCaptchaPress = () => {
    this.props.ajax.get(true,this.props.url+'/forum/api/captcha-question/',{})
    .then(
        (data) => {
          let s = this.state;
          console.log(data);
          s.captcha = data;
          this.setState(s);
          }
    )
    .catch(
      (rejection) => {
        console.log('Failed renew captcha rejection cause');
        let s = this.state;
        s.captcha = {};
        console.log(rejection);
        this.setState(s);
      }
    )
  }


  registerPress = () => {
    this.props.ajax.post(true,this.props.url+'/forum/api/users/',this.state.form,null)
    .then(
      (data) => {
        console.log(data);
        return this.props.ajax.get(true,this.props.url+'/forum/api/auth/');
      }
    )
    .then(
      (data) => {
        console.log(data);
        this.props.setUserInfo(data);
      }
    )
    .catch(
      (rejection) => {
        alert(rejection);
      }
    )
  }

  render () {
    return (
      <RegisterPage passInput={this.passInput}
        userInput={this.userInput}
        emailInput={this.emailInput}
        captchaInput={this.captchaInput}
        question={this.state.captcha}
        auth={this.state.auth}
        cbClose={this.props.cbClose}
        loginPress={this.renewCaptchaPress}
        registerPress={this.registerPress}/>

    )
  }


}

export default RegisterContainer;
