import React, {Component} from 'react'
import {TouchableOpacity, View, StyleSheet} from 'react-native'
import Ptext from "../../common/Ptext"


class ReviewItem extends Component {



    render () {
        return (
            <View style={[styles.wrapper,this.props.style,{width: this.props.fullWidth}]}>
                <Ptext style={styles.buttonCaption}>
                    {this.props.header}
                </Ptext>
                <Ptext style={styles.reviewText}> 
                    {this.props.text}
                </Ptext>
            </View>
        )
    }
};

const styles = StyleSheet.create({
    wrapper: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'center',
        paddingTop: 16,
        paddingLeft: 29,
        paddingBottom: 34,
        backgroundColor: 'rgba(0, 0, 0, 0.03);',
        borderRadius: 1,
        marginTop: 10,
        marginBottom: 10,
    },
    buttonWrapperStyle: {
        width: '100%',
        flexDirection : 'row',
        alignItems: 'center',
        padding: 16,
        minHeight: 72,
        justifyContent: 'space-between',

    },
    buttonCaption: {
        flex: 1,
        fontFamily: 'Russo One',
        fontStyle: 'normal',
        fontSize: 18,
        textAlign: 'left',
        alignSelf: 'flex-start',
        letterSpacing: 3,
        color: '#232323',
    },
    infoWrapper: {
        paddingLeft: 46,
        borderTopWidth: 1,
        borderTopColor: 'rgba(0, 0, 0, 0.05)',
        width: '100%',
        flexDirection : 'column',
    },
    reviewText:{
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'left',
        /* or 143% */


        /* @grey_60 */

        color: 'rgba(0, 0, 0, 0.6)'
    }
});


export default ReviewItem;