import React, { Component } from 'react';
import { View, StyleSheet,TouchableOpacity } from 'react-native';
import Ptext from "../../../common/Ptext.js"
 
import { NavLink } from '../../router/react-router';


class ActionLogout extends Component {
  render () {
      return (
        <NavLink to="/logout" style={{textDecoration : 'none',color : '#000'}}>

        <TouchableOpacity onPress={this.props.onPress}>
        <View style={styles.actionColumn}>
          <View style={styles.actionColumnIcon}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3H5C3.89 3 3 3.89 3 5V9H5V5H19V19H5V15H3V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 3.89 20.1 3 19 3ZM10.08 15.58L11.5 17L16.5 12L11.5 7L10.08 8.41L12.67 11H3V13H12.67L10.08 15.58Z" fill="black"/>
            </svg>
          </View>
          <View style={styles.actionLabel}>
            <Ptext style={styles.actionCaptionLabel}>
            Выйти из аккаунта
            </Ptext>
            <View style={styles.actionEventsLabel}>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      </NavLink>
      )
    }
}

const styles = StyleSheet.create({
  actionColumn : {
    width : '100%',
    height : 48,
    flexDirection : 'row'
  },
  actionColumnIcon : {
    width : 64,
    paddingLeft : 24,
    paddingTop : 12,
  },
  actionLabel : {
    flex : 1,
    flexDirection : 'row',
    alignItems : 'center',
    justifyContent : 'flex-start',
    paddingLeft : 5
  },
  actionCaptionLabel : {
    fontSize : 16
  },
  actionEventsView : {
    flex : 1,
  },
  actionEventsText : {
    borderRadius : 10,
    borderWidth : 1,
    borderColor : '#45B4E3',
    color : '#45B4E3',
    borderStyle : 'solid',
    textAlign : 'left',
    paddingLeft : 8,
    paddingRight : 8,
    marginLeft : 8,
    marginRight : 8,
    fontSize : 16
  }
})
export default ActionLogout;
