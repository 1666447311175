import React,{Component} from 'react';
import {StyleSheet, View} from 'react-native';
import CommentsFull from './commentsFull.js';



class CommentsPage extends Component {

  _backPage = () => {
    this.props.history.goBack();
  }
/*
import BlogPostStageHeader from '../blog/BlogPostStageHeader.js';
      <BlogPostStageHeader stage={2} onBackPressed={this._backPage}/>

*/
  render () {
    return(
      <View style={[styles.wrapper,{backgroundColor : '#F1F3F5',width : this.props.scrollWidth, height : this.props.t_height}]}>
      <CommentsFull t_height={this.props.t_height-64}
      wagtail={this.props.wagtail}
      post_id={this.props.post_id}
      login={this.props.login}
      auth={this.props.auth}
      user={this.props.user}
      fullWidth={this.props.scrollWidth}
      style={{alignSelf : 'flex-end'}}
      />
      </View>
    )
}
}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    alignSelf : 'center',
    alignItems : 'flex-end',
    justifyContent: 'flex-start'
  },
  container : {
    justifyContent : 'flex-start',
    alignItems : 'flex-start'
  }

});

export default CommentsPage;
