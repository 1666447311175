import React, {Component} from 'react';
import { StyleSheet} from 'react-native';
import Modal from 'modal-react-native-web';
import { NavLink } from '../router/react-router';
import { TouchableOpacity } from 'react-native';

class MenuModal extends Component {


    render() {
        return (
            <Modal style={{width : (this.props.fullWidth > 510 ? 510 : '100%')}} visible={this.props.visible}
            transparent={false} ariaHideApp={false} onBackdropPress={this.props.cbClose}
            animationType={'fade'}
            userStyle={{
              backgroundColor : 'black',
              top: 74,
              left: this.props.ops !== undefined ? this.props.ops.obj.px : 0,
              maxWidth : 200,
              height : this.props.ops !== undefined ? 54*this.props.ops.items.length : 0,
              zIndex: 1000,
              borderRadius : 1,
            }}>
            {
                this.props.ops !== undefined ? 
                this.props.ops.items.map((item, index, arr)=> {
                    return (
                        <TouchableOpacity onPress={() => setTimeout(() => this.props.cbClose(),300)} style={{height : 54}}>
                            <NavLink to={item.url} key={index} style={{fontSize:14,fontWeight:'normal', height : 54, padding : 16, color : 'white', textDecoration : 'none', letterSpacing : 2, }}>
                                {item.name}
                            </NavLink>
                        </TouchableOpacity>
                    )
                })
                :
                null
            }

            </Modal>
        )
    }
};


const styles=StyleSheet.create({
    main:{

    },

});

export default MenuModal;
