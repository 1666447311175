import React,{Component} from 'react'
import {View,StyleSheet, TextInput, TouchableOpacity} from 'react-native'
import Ptext from "./Ptext.js"
import globalStyle from '../styles/globalStyle';
/*

<!--            <TouchableOpacity onPress = {() => {window.location.href='https://t.me/annakarpova14';}}><Ptext style={styles.link}> или Telegram </Ptext></TouchableOpacity> -->

*/

class FormInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active : false,
        };
    }


    render () {
        return (
            <View style={this.props.style}>
            <View style={[styles.formTextInput, this.props.style,
                 {borderColor : this.props.hint ? this.props.valid ? 'green' :  globalStyle.colors.primary : this.state.active ? 'black' : globalStyle.colors.from_textInputBorder}]}>
                {
                this.props.value !== undefined && this.props.value !== '' ?
                <Ptext style={styles.placeholderHint}>{this.props.placeholder}</Ptext>
                :
                null
                }
                <TextInput {...this.props} style={[ styles.textInput, 
                    { marginTop: this.props.value !== undefined && this.props.value !== '' ? 0 : 16 },this.props.tStyle ]}
                        onFocus={() =>{this.setState({active:true})}}
                        onBlur={() =>{this.setState({active:false})}}
                        outlineWidth = {0}
                    
                    />
                
            </View>
            {this.props.hint ? <Ptext style={[styles.itemHintError, this.props.valid ? {color : 'green'} : {}]}>{this.props.hint}. Если ошибка повторяется, свяжитесь с администратором школы.
            <TouchableOpacity onPress = {() => {window.location.href='https://wa.me/79854252339';}}><Ptext style={styles.link}>+79854252339</Ptext></TouchableOpacity> 
            </Ptext>  : null}
            </View>
        )
    }
};

const styles = StyleSheet.create({
    formTextInput : {
        borderRadius : 1,
        borderWidth : 2,
        borderColor : globalStyle.colors.from_textInputBorder,
        minWidth : 306,
        alignSelf : 'center',
        justifyContent : 'flex-start',
        alignItems : 'center',
        minHeight : 56,
        padding: 0,   
    },
    placeholderHint: {
        fontSize: 12,
        color: globalStyle.colors.from_textInputBorder,
        lineHeight: 11,
        height: 16,
        alignSelf: 'flex-start',
        marginLeft: 13,
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 5,
    },
    textInput : {

        width : '100%',
        height : 20,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontSize: 16,
        fontWeight: 'Bold',
        lineHeight: 19,
        textAlign : 'center',
        outline: "none",
    },
    itemHintError : {
        fontSize: 13,
        lineHeight: 15,
        textAlign: 'left',
        letterSpacing: 2,
        textTransform: 'lowercase',
        color : globalStyle.colors.primary,
    }
});

export default FormInput;