


const globalStyle = {
    grayWrapper : {
        backgroundColor : '#666666', // @grey_60
        width : '100%',
    },
    colors : {
        primary : '#FF7C77',// @primary_pink
        primary_light : '#FFA7A4',// @light_pink
        primary_dark : '#ED726E',// @darck_pink
        primary_disabled : '#f2f2f2',// grey_05

        secondary : '#000000', // @primary_black
        secondary_light : '#666666', // @grey_60
        secondary_dark : '#232323', // @primary_
        secondary_disabled : '#e6e6e6',// @grey_10
        
        font_main : '#ffffff',// @primary_white
        font_disabled : '#999999', // @grey_40
        font_primary : '#000000',
        font_secondary : '#ffffff',

        matrix_page_background : '#f7f7f7', // @grey_03
         
        chat_messageList_background : '#ffffff',
        chat_messageItem_background : '#ffffff',
        chat_messageItem_out_background : '#f2f2f2',// grey_05

        chat_messageItem_borderColor : '#e6e6e6',
        chat_messageItem_ShadowColor : '#f2f2f2',
        chat_messageItem_textColor : '#666666',

        chat_sendMessage_bg : '#000000',
        chat_chatlist_bg : '#f2f2f2',// grey_05

        from_textInputBorder: 'rgba(0,0,0,0.2)',

    }
};


export default globalStyle;