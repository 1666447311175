import React, { Component } from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import MainIcon from "./icons/MainIcon.js";
import BlogIcon from "./icons/BlogIcon.js";
import LoginIcon from "./icons/LoginIcon.js";
import MenuIcon from "./icons/MenuIcon.js";
import MenuCloseIcon from "./icons/MenuCloseIcon.js";
import SearchIcon from "./icons/searchIcon"
import ProfileNewIcon from "./icons/ProfileNewIcon"
import LeftMenu from "./leftMenu"
import MenuArrowIcon from "./icons/menuArrowIcon"
import Ptext from "../../common/Ptext"
import { Dimensions } from 'react-native';


/*
const navigationMenu=[
  {
      title: 'Блог',
      url: '/blog/list/',
      items: [
          {
          name: 'Список',
          url: '/blog/list/',
          },
          {
              name: 'Записи',
              url: '/blog/mozaik/',
          },
          
      ]
  },
  {
      title: 'Школа',
      url: '/school/',
      items: [
          {
          name: 'Запись на обучение',
          url: '/school/',
          },
          
          
      ]
  },
  {
      title: 'Услуги',
      url: '/services/',
      items: [
          {
          name: 'Список услуг',
          url: '/services/',
          },
          
      ]
  },
  //{
   //   title: 'Чаты',
    //  url: '/chat/list/',
    //  items: [
    //      {
    //      name: 'Все чаты',
    //      url: '/chat/list/',
    //      },
    //      
    //  ]
  //},
  {
      title: 'Матрица',
      url: '/matrix/',
      items: [
          {
          name: 'Расчет',
          url: '/matrix/',
          },
      ]
  },
  {
      title: 'Проверь эксперта',
      url: '/checkexpert/',
      items: [
          {
          name: 'Проверь Эксперта',
          url: '/checkexpert/',
          },
      ]
  },
]
*/
const navigationMenu=[
  {
    title: 'Матрица',
    url: '/matrix/',
    items: [
        {
        name: 'Расчет',
        url: '/matrix/',
        },
    ]
  },
  {
    title: 'Школа',
    url: '/school/',
    items: [
        {
        name: 'Запись на обучение',
        url: '/school/',
        },
        
        
    ]
},
];

class TopMenuComponent extends Component
{
    state = {
      dims : {width : -1},
      showed : true,
      navOpened : false,
      scrollref : undefined,
      contentWidth : undefined,
      needArrow : false,
      showedArrow : 0,
    };
    constructor(props){
      super(props);

      this.dims_changed({screen : Dimensions.get("window")});

    }

    _openNav = () => {
      this.setState({navOpened : true});
    }

    _closeNav = () => {
      this.setState({navOpened : false});
    }

    //dims_changed = dims => console.log(dims," ",this);
    dims_changed = dims => {
      let res = this.props.supp.testRange(this.state.dims.width,dims.screen.width);
      if (res.neq) {
          let s = this.state;
          switch (res.range) {
            case 0:
                  s.mainWidth = '80%';
                  s.profileWidth = 24;
                  s.gambmenuWidth = '10%';
                  s.blogWidth = '20%';
                  s.serviceWidth = '20%';
                  s.matrixWidth = '20%';
                  s.chatWidth = '20%';
                  s.schoolWidth = '20%';
                  s.matrix_title_visible = false;
                  s.notify_visible = false;
                  s.messages_visible = false;
                  s.menu_height = 56;
                  s.text_size = 14;
                  s.text_main_size = 16;
              break;
            case 1:
                s.mainWidth = '22%';
                s.blogWidth = '13%';
                s.serviceWidth = '13%';
                s.matrixWidth = '13%';
                s.chatWidth = '13%';
                s.schoolWidth = '13%';
                s.profileWidth = 24;
                s.gambmenuWidth = '6.5%';
                s.matrix_title_visible = false;
                s.notify_visible = false;
                s.messages_visible = false;
                s.menu_height = 56;
                s.text_size = 14;
                s.text_main_size = 16;
              break;
              case 2:
                  s.mainWidth = '22%';
                  s.blogWidth = '13%';
                  s.serviceWidth = '13%';
                  s.matrixWidth = '13%';
                  s.chatWidth = '13%';
                  s.schoolWidth = '13%';
                  s.profileWidth = 32;
                  s.gambmenuWidth = '6.5%';
                  s.matrix_title_visible = false;
                  s.notify_visible = false;
                  s.messages_visible = false;
                  s.menu_height = 56;
                  s.text_size = 16;
                  s.text_main_size = 20;
                break;
                case 3:
                    s.mainWidth = '26%';
                    s.blogWidth = '12%';
                    s.serviceWidth = '12%';
                    s.matrixWidth = '12%';
                    s.chatWidth = '12%';
                    s.schoolWidth = '12%';
                    s.profileWidth = 32;
                    s.gambmenuWidth = '3.5%';
                    s.messagesWidth = '3.5%';
                    s.notifyWidth = '3.5%';
                    s.matrix_title_visible = true;
                    s.notify_visible = true;
                    s.messages_visible = true;
                    s.menu_height = 74;
                    s.text_size = 16;
                    s.text_main_size = 20;
                  break;
                  case 4:
                    s.mainWidth = '26%';
                    s.blogWidth = '12%';
                    s.serviceWidth = '12%';
                    s.matrixWidth = '12%';
                    s.chatWidth = '12%';
                    s.schoolWidth = '12%';
                    s.profileWidth = 32;
                    s.gambmenuWidth = '3.5%';
                    s.messagesWidth = '3.5%';
                    s.notifyWidth = '3.5%';
                    s.matrix_title_visible = true;
                    s.notify_visible = true;
                    s.messages_visible = true;
                    s.menu_height = 74;
                    s.text_size = 16;
                    s.text_main_size = 20;
                    break;
                    default:
                    s.mainWidth = '26%';
                    s.blogWidth = '12%';
                    s.serviceWidth = '12%';
                    s.matrixWidth = '12%';
                    s.chatWidth = '12%';
                    s.schoolWidth = '12%';
                    s.profileWidth = 32;
                    s.gambmenuWidth = '3.5%';
                    s.messagesWidth = '3.5%';
                    s.notifyWidth = '3.5%';
                    s.matrix_title_visible = true;
                    s.notify_visible = true;
                    s.messages_visible = true;
                    s.menu_height = 56;
                    s.text_size = 16;
                    s.text_main_size = 20;
                    break;
          }
          dims.screen.height = window.innerHeight;
          dims.screen.width = window.innerWidth;
          s.dims = dims.screen;
          this.setState(s);
          let menu_height = s.menu_height > 58 ? 37 : 56;

          this.props.cbDimChanged(dims.screen.height - (this.props.showMenu ? menu_height*2 : 0),dims.screen.width);
      }else {
        dims.screen.height = window.innerHeight;
        dims.screen.width = window.innerWidth;
        let menu_height = this.state.menu_height > 58 ? 37 : 56;
        if ((this.state.dims.height !== dims.screen.height)||(!this.props.showMenu))
          this.props.cbDimChanged(dims.screen.height - (this.props.showMenu ? menu_height*2 : 0),dims.screen.width);
      }
    }

    _setRef = (ref) => {
      if (this.state.scrollref === undefined) {
        this.setState({scrollref : ref});
      }
    }

    _initWidth = (w,h) => {
      if (this.state.contentWidth === undefined) {
        this.setState({contentWidth : w, needArrow: w > this.state.dims.width});
      }
    }

    _onScroll = (data) => {
      console.log(data);
      if ((this.state.needArrow === undefined)||(this.state.needArrow === false))
        return;
      
      let scroll_width = this.state.needArrow ? this.state.dims.width-45 : this.state.dims.width;
      if (data.nativeEvent.contentOffset.x === 0) {
        if (this.state.showedArrow !== 0) {
          this.setState({showedArrow : 0})
        }
      }
      if (scroll_width+data.nativeEvent.contentOffset.x === data.nativeEvent.contentSize.width) {
        if (this.state.showedArrow !== 1) {
          this.setState({showedArrow : 1});
        }
        return;
      }
      

    }

    _resize_window = (e) => {
      this.dims_changed({screen : Dimensions.get("window")});
    }


    componentWillMount() {
          Dimensions.addEventListener("change", this.dims_changed);
          window.addEventListener('resize',this._resize_window);
    }

    componentWillUnmount() {
      // Important to stop updating state after unmount
        Dimensions.removeEventListener("change", this.dims_changed);
    }

    _pressRightMenu = () => {
      if (this.state.scrollref !== undefined) {
        let x_offset = this.state.contentWidth - this.state.dims.width+45;
        this.state.scrollref.scrollTo({x: x_offset, y: 0, animated: true})
      }
    }

    _pressLeftMenu = () => {
      if (this.state.scrollref !== undefined) {
        this.state.scrollref.scrollTo({x: 0, y: 0, animated: true})
        
      }
    }


/*
{this.props.showMenu ?
        <View style={[styles.menu,{height : this.state.menu_height}]}>
          <MenuIcon style={{width:this.state.gambmenuWidth,textDecoration : 'none'}} notify={true} onPress={this.props.cbOpenMenu}/> 
          <MainIcon style={{width: this.state.mainWidth, textDecoration : 'none'}} fontSize={this.state.text_main_size}/>
          <SearchIcon />
          

          {this.state.mainWidth === '80%' && this.props.auth?
              <ProfileIcon avatar={this.props.avatar} style={{width: this.state.profileWidth,
                                  height: this.state.profileWidth,textDecoration : 'none'}} onPress={this.props.avatarPress}/>
              : null }
            {this.state.mainWidth === '80%' && !this.props.auth?
                  <LoginIcon style={{width: this.state.profileWidth,
                                      height: this.state.profileWidth,textDecoration : 'none'}}
                              cbOnClick={this.props.toAuthPage}/>
                  : null }
          {this.state.mainWidth === '80%' && this.props.auth ? <MenuIcon style={{width:this.state.gambmenuWidth,textDecoration : 'none'}} onPress={this.props.cbOpenMenu}/> : null }
          <BlogIcon title='Блог'
              style={{width : this.state.blogWidth,textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size}} />
          <ServiceIcon title='Услуги'
            style={{width : this.state.serviceWidth,textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size}}/>
          <MatrixIcon title_visible={this.state.matrix_title_visible} title='Матрица'
            style={{width : this.state.matrixWidth,textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size}}/>
          <ChatIcon title='Чаты'
            style={{width : this.state.chatWidth,textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size}}/>

          <SchoolIcon title='Школа'
            style={{width : this.state.schoolWidth,textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size}}/>

          {this.state.mainWidth !== '80%' && this.props.auth ? <ProfileIcon avatar={this.props.avatar} onPress={this.props.avatarPress} style={{width: this.state.profileWidth,height: this.state.profileWidth,textDecoration : 'none'}}/>: null }
          {this.state.mainWidth !== '80%' && !this.props.auth ? <LoginIcon style={{width: this.state.profileWidth,height: this.state.profileWidth,textDecoration : 'none'}} cbOnClick={this.props.toAuthPage}/>: null }
          {this.state.notify_visible && this.props.auth ? <NotifyIcon style={{width: this.state.notifyWidth,textDecoration : 'none'}} notify={true} /> : null}
          {this.state.messages_visible && this.props.auth ? <MessagesIcon style={{width: this.state.messagesWidth,textDecoration : 'none'}} notify={true} /> : null}
          
        </View>
        :
        null }

*//*
font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 13px;
line-height: 15px;
letter-spacing: 2px;
text-transform: uppercase;
*/

    render () {
      if (this.state.showed && !this.props.showMenu) {
        this.setState({showed : false});
        this.dims_changed({screen : Dimensions.get("screen")});
      }
      return (

        <View style={styles.wholeMenu}>
        <View style={[styles.menu,{height : this.props.showMenu ? this.state.menu_height : 0}]}>
          {this.state.dims.width < 1200 ?
            !this.state.navOpened ?
            <MenuIcon onPress={this._openNav}/>
            :
            <MenuCloseIcon onPress={this._closeNav}/>
            
          :
          null
          }
          <MainIcon wid={this.state.dims.width}/>
          {this.state.dims.width > 1200 ? 
        <View style={[styles.menu,{height : this.props.showMenu ? this.state.menu_height : 0, justifyContent: 'space-around', flex : 1}]}>
          {
            navigationMenu.map((item,index,arr)=> {
              return (
                <MenuArrowIcon title={item.title} to={item.url} key={index} cbOpenMainMenu={this.props.cbOpenMainMenu} items={item.items}
                cbCloseMainMenu={this.props.cbCloseMainMenu} opened={this.props.openedIndex === index} index={index}
                style={{textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size,
                fontFamily: 'Roboto', fontStyle: 'normal', fontWeight : 'normal', letterSpacing: 2, textTransform: 'uppercase'}} />
              )
            }) 
          }
        </View>
        :
        null}

          <SearchIcon/>
          {
            this.props.auth ? 
            <ProfileNewIcon onPress={this.props.cbOpenMenu}/>
            :
            <LoginIcon cbOnClick={this.props.toAuthPage}/>
          }
        </View>
        {this.state.dims.width < 1200 ? 
        !this.state.navOpened ? 
        <View style={{width:this.state.dims.width, height : this.props.showMenu ? this.state.menu_height : 0, alignItems: 'flex-start',
        justifyContent: 'flex-start',flexDirection:'row'}}>
        {
          this.state.needArrow && this.state.showedArrow === 1 ? 
          <TouchableOpacity style={{width:45, height : this.state.menu_height, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white'}}
            onPress={this._pressLeftMenu}
            >
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 1L1 7L7 13" stroke="black" stroke-linecap="square"/>
            </svg>

          </TouchableOpacity>
          :
          null
        }
        <ScrollView style={[styles.menuScroll_without_width,{height : this.props.showMenu ? this.state.menu_height : 0, 
          width: this.state.needArrow ? this.state.dims.width-45 : this.state.dims.width}]} 
          horizontal={true}
          contentContainerStyle={{flexDirection: 'row',alignItems : 'center', justifyContent : 'space-around', flexGrow: 1}}
          pagingEnabled={true}
          disableIntervalMomentum={true}
          onScroll={this._onScroll}
          ref={this._setRef}
          onContentSizeChange={this._initWidth}
          >

          {
            navigationMenu.map((item,index,arr)=> {
              return (
                <BlogIcon title={item.title} to={item.url}
                style={{textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size,
                fontFamily: 'Roboto', fontStyle: 'normal', fontWeight : 'normal', letterSpacing: 2, textTransform: 'uppercase', marginLeft: 15, marginRight : 15}} />
              )
            }) 
          }
        </ScrollView>
        {
          this.state.needArrow && this.state.showedArrow == 0 ? 
          <TouchableOpacity style={{width:45, height : this.state.menu_height, justifyContent: 'center', alignItems: 'center', backgroundColor: 'white'}}
          onPress={this._pressRightMenu}
          >
           <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L1 13" stroke="black" stroke-linecap="square"/>
            </svg>

          </TouchableOpacity>
          :
          null
        }
        </View>
        :
        <LeftMenu scrollHeight={window.innerHeight - this.state.menu_height} closeMenu={this._closeNav} menu={navigationMenu} history={undefined}/> 
        
        :
        null}

        </View>
      )
    }
/*

           <BlogIcon title='Блог' to={'/blog/list/'}
              style={{textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size,
              fontFamily: 'Roboto', fontStyle: 'normal', fontWeight : 'normal', letterSpacing: 2, textTransform: 'uppercase', marginLeft: 15, marginRight : 15}} />
          <BlogIcon title='школа' to={'/school/'}
              style={{textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size,
              fontFamily: 'Roboto', fontStyle: 'normal', fontWeight : 'normal', letterSpacing: 2, textTransform: 'uppercase', marginLeft: 15, marginRight : 15}} />
          <ServiceIcon title='Услуги'
              style={{textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size,
              fontFamily: 'Roboto', fontStyle: 'normal', fontWeight : 'normal', letterSpacing: 2, textTransform: 'uppercase', marginLeft: 15, marginRight : 15}} />

          <MatrixIcon title='Матрица'
                        style={{textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size,
                        fontFamily: 'Roboto', fontStyle: 'normal', fontWeight : 'normal', letterSpacing: 2, textTransform: 'uppercase', marginLeft: 15, marginRight : 15}} />

          <ChatIcon title='Чаты'
                        style={{textDecoration : 'none',color : '#000',textAlign : 'center',fontSize : this.state.text_size,
                        fontFamily: 'Roboto', fontStyle: 'normal', fontWeight : 'normal', letterSpacing: 2, textTransform: 'uppercase', marginLeft: 15, marginRight : 15}} />

*/
}

const styles = StyleSheet.create({
  menu : {
    flexDirection : 'row',
    flexWrap : 'wrap',
    width: '100%',
    backgroundColor: '#fff',
    alignItems : 'center',
    /*
    shadowColor: '#A4ACB3',
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    marginBottom : 2,*/
    height: 56,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)', 
  },
  menuScroll : {
    width: '100%',
    backgroundColor: '#fff',
    /*
    shadowColor: '#A4ACB3',
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    marginBottom : 2,*/
    height: 56,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)', 
  },
  menuScroll_without_width : {
    backgroundColor: '#fff',
    /*
    shadowColor: '#A4ACB3',
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    marginBottom : 2,*/
    height: 56,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.1)', 
  },
  defaultIconStyle : {

  },
  wholeMenu: {
    flexDirection : 'column',
    justifyContent : 'center',
    alignItems : 'center',
    width : '100%',
  },
  

})
export default TopMenuComponent
