import React,{Component} from 'react';
import {View, StyleSheet,TextInput,TouchableOpacity, FlatList, ActivityIndicator } from 'react-native';
import Ptext from "../../../common/Ptext.js"
import ContactChatItem from "./contactChatItem.js"


class CreateChatView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter : 3,
      arrayHolder : [],
      data : [],
      offset : 1,
      total_offset : 1,
      loading : false,
      error : undefined,
    };

  }
  _setUserList = (data,status) => {
    this.setState({loading : false});
    if (status) {
      if (data.page === 1)
        this.setState({data : data.results,arrayHolder : data.results,offset : data.page, total_offset : data.pages, loading : false});
      else
        this.setState({data : [...this.state.data, ...data.results],arrayHolder : [...this.state.data, ...data.results], offset : data.page, loading : false});
    }
    else {
        this.setState({error : data});
    }
  }


  componentDidMount() {
    this.setState({loading : true});
    this.props.misago.do_fetch_user_lists(this._setUserList,this.state.offset);
  }

  _keyExtractor = (item,index) => index.toString();

  _onPressItem = (index : int) => {
    if (this.props.selection)
        /*this.setState((state)=> {
            const selected = this.state.selected;
            selected.set(index,!selected.get(index));
            return {selected};
        }
      );*/
        this.props.changeSelection(index,this.state.arrayHolder[index].username);

    if (this.props.showChat)
        this.props.showChat();
  }
  _changeFilter = (value) => {
    const textData = value.toUpperCase();
    const newData = this.state.arrayHolder.filter(
      item => {
        const itemData = item.username.toUpperCase() + (item.real_name !== null ? item.real_name.toUpperCase() : '') ;
        return itemData.indexOf(textData) > -1;
      }
    );
    this.setState({data : newData});
  }

 _clearHeaderTextInputValue = () => {
   this._headerTextInputComponent.clear();
   this.setState({data : this.state.arrayHolder});
 }

  _renderHeader = () => {
      return (
        <View style={styles.headerFlatList}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" fill="#B4BBC2"/>
          </svg>
          <TextInput
            ref={component => this._headerTextInputComponent = component}
            style={styles.headerTextInput}
            onChangeText={this._changeFilter}
            />
          <TouchableOpacity onPress={this._clearHeaderTextInputValue}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="#B4BBC2"/>
            <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="#B4BBC2"/>
            </svg>
          </TouchableOpacity>
        </View>
      )
  }

  _renderFooter = () => {
      return (
        <View style={{width:'100%',justifyContent : 'center',alignSelf : 'center',alignItems : 'center'}}>
        {
          this.state.loading ?
          <ActivityIndicator size="large" color="#0000ff" />
          :
            null
        }
        </View>
      )
  }

  _renderEmpty = () => {
    return (
      <View style={{width:'100%',justifyContent : 'center',alignSelf : 'center',alignItems : 'center'}}>
      {this.state.error !== undefined ?
        <Ptext> {this.state.error.detail} </Ptext>
        :
        null
      }
      </View>
    )
  }

  _handleLoadMore = () => {
    if ((this.state.offset < this.state.total_offset)&&(!this.state.loading)) {
      this.setState({loading : true});
      this.props.misago.do_fetch_user_lists(this._setUserList,this.state.offset + 1);
    }
  }

  render () {
    let add_height = 140;
    if (this.props.selection)
      add_height = 60;
    return (
      <View style={styles.wrapper}>
        {/* New chat caption and chevron left(back) */}
        <View style={styles.horizontalWrapper}>
          <TouchableOpacity onPress={this.props.closeCreateChat}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.4 7.4L14 6L8 12L14 18L15.4 16.6L10.8 12L15.4 7.4Z" fill="black"/>
            </svg>
          </TouchableOpacity>
          <Ptext style={styles.createChatHeader}>
            {this.props.selection ? 'Выбор участников' : 'Новый чат'}
          </Ptext>
          {this.props.selection ?
            <TouchableOpacity onPress={this.props.closeCreateChat} style={{flex : 1, minHeight : 24, alignItems : 'flex-end', justifyContent : 'center'}} >
              <Ptext style={{color : 'blue'}}>ОК</Ptext>
            </TouchableOpacity>
           :
             null

          }
        </View>
        {/* Create group chat button */}
        { this.props.selection ?
          null
          :
          <TouchableOpacity style={styles.horizontalWrapper} onPress={this.props.showCreateGroupChat}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18" cy="18" r="17" fill="#45B4E3" stroke="#45B4E3" strokeWidth="2"/>
              <path d="M18 19C16 19 12 20 12 22V24H24V22C24 20 20 19 18 19ZM24.62 19.16C25.45 19.88 26 20.82 26 22V24H29V22C29 20.46 26.63 19.5 24.62 19.16ZM18 17C18.7956 17 19.5587 16.6839 20.1213 16.1213C20.6839 15.5587 21 14.7956 21 14C21 13.2044 20.6839 12.4413 20.1213 11.8787C19.5587 11.3161 18.7956 11 18 11C17.2044 11 16.4413 11.3161 15.8787 11.8787C15.3161 12.4413 15 13.2044 15 14C15 14.7956 15.3161 15.5587 15.8787 16.1213C16.4413 16.6839 17.2044 17 18 17ZM23 17C23.7956 17 24.5587 16.6839 25.1213 16.1213C25.6839 15.5587 26 14.7956 26 14C26 13.2044 25.6839 12.4413 25.1213 11.8787C24.5587 11.3161 23.7956 11 23 11C22.68 11 22.37 11.05 22.08 11.14C22.65 11.95 23 12.94 23 14C23 15.06 22.65 16.04 22.08 16.85C22.37 16.95 22.68 17 23 17ZM13 16H10V13H8V16H5V18H8V21H10V18H13V16Z" fill="white"/>
            </svg>
            <Ptext style={styles.createChatCaption}>
              Создать групповой чат
            </Ptext>
          </TouchableOpacity>
        }
        {/* Flat list with contact list */}
        <FlatList
          data={this.state.data}
          style={[styles.flatChatList,{height : this.props.fullHeight-add_height}]}
          onEndReached={this._handleLoadMore}
          onEndReachedThreshold={1}
          initialNumToRender={10}
          renderItem={({item,index}) => {

            return (
              <ContactChatItem chat={item} id={index}
              selected={!!this.props.selectedid.get(index)}
              onPressItem={this._onPressItem}/>
            )}

          }
          ListFooterComponent={this._renderFooter}
          ListEmptyComponent={this._renderEmpty}
          keyExtractor={this._keyExtractor}
          ListHeaderComponent={this._renderHeader}
          stickyHeaderIndices={[0]}
        />

      </View>
    )
  }
}

const styles=StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    width : '100%',
    backgroundColor : '#fff',
    alignItems : 'center',
    justifyContent : 'flex-start',
  },
  horizontalWrapper : {
    width : '90%',
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
    marginTop : 14,
    marginBottom : 14,
  },
  createChatCaption : {
    fontSize : 16,
    lineHeight : 24,
    marginLeft : 17,
  },
  createChatHeader : {
    fontWeight : 'bold',
    fontSize : 20,
    lineHeight : 24,
    marginLeft : 17,
  },
  flatChatList : {
    width : '90%',
    marginTop : 14,
    marginBottom : 14,
  },
  headerFlatList : {
    flexDirection : 'row',
    alignSelf : 'center',
    alignItems : 'center',
    justifyContent : 'flex-start',
    width : '100%',
    borderRadius : 4,
    borderColor : '#B4BBC2',
    borderWidth : 1,
    height : 40,
    padding : 12,
    backgroundColor : '#fff',
  },
  headerTextInput : {
    flex : 3,
    fontSize : 14,
    lineHeight : 20,
    marginLeft : 8,
    marginRight : 8,
    borderWidth : 0,
  }
});
export default CreateChatView;
