import React,{ Component } from 'react'
import { ScrollView} from 'react-native'
import AuthForm from "../../components/login/loginPage.js"
import RestoreForm from "../../components/login/restoreForm.js"
import SuccessOperationView from "../../components/login/succedEnterView.js"
import Modal from 'modal-react-native-web';
import { KeyboardAvoidingView } from 'react-native-web'

class LoginPageContainer extends Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.user = {
      email : undefined,
      pass : undefined,
    };
    this.state.auth = false;
    this.state.showreg = false;
    this.state.showrestore = false;
    this.state.show_success = false;
    this.state.form = {
      privacy_policy : null,
      terms_of_service : null,
    };
    this.state.submitEmailHint = undefined;
    this.state.captcha = {};
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.show_success && this.props.visible && !prevProps.visible)
      this.setState({show_success : false});
  }

  passInput = (value) => {
    let s = this.state;
    s.user.pass = value;
    s.form.password = value;
    this.setState(s);
  }
  emailInput = (value) => {
    let s = this.state;
    s.form.email = value;
    s.user.email = value;
    this.setState(s);
  }

  captchaInput = (value) => {
    let s = this.state;
    s.form.captcha = value;
    this.setState(s);
  }
  userInput = (value) => {
    let s = this.state;
    s.form.username = value;
    this.setState(s);
  }

  loginPress = () => {
    this.props.ajax.post(false,this.props.url+'/forum/api/auth/',{
      username : this.state.user.email,
      password : this.state.user.pass,
    }).then(
        (data) => {
          this.props.setUserInfo(data);
          /*this.props.cbClose(true);*/
          this.setState({show_success : true});
        }
    )
    .catch(
      (rejection) => {
        console.log('Failed login returned rejection cause');
        alert(rejection.detail);
      }
    )
  }

/*
  registerPress = () => {
    let s = this.state;
    this.renewCaptchaPress();
    s.showreg = true;
    this.setState(s);
  }
*/
  toLoginPage = () => {
    let s = this.state;
    s.showreg = false;
    this.setState(s);
  }

  toRestorePassword = () => {
    let s = this.state;
    s.showrestore = true;
    this.setState(s);
  }

  backRestorePassword = () => {
    this.setState({showrestore : false});
  }

  renewCaptchaPress = () => {
    this.props.ajax.get(false,this.props.url+'/forum/api/captcha-question/',{})
    .then(
        (data) => {
          let s = this.state;
          s.captcha = data;
          this.setState(s);
          }
    )
    .catch(
      (rejection) => {
        console.log('Failed renew captcha rejection cause');
        let s = this.state;
        s.captcha = {};
        console.log(rejection);
        this.setState(s);
      }
    )
  }

  restorePasswordPress = () => {

    this.props.ajax.post(false,this.props.url+'/forum/api/auth/send-password-form/',{
      email : this.state.user.email,
      password : this.state.user.pass,
    }).then(
        (data) => {
          /*this.props.setUserInfo(data);
          this.props.cbClose(true);*/
          this.setState({submitEmailHint : 'На указанный e-mail, выслано письмо с ссылкой установки пароля.'})
          setTimeout(()=>{this.setState({submitEmailHint : undefined})},3000);
        }
    )
    .catch(
      (rejection) => {
        console.log('User with Email not founded');
        alert(rejection.detail);
      }
    )

  }

  registerSubmitPress = () => {
    this.props.ajax.post(true,this.props.url+'/forum/api/users/',this.state.form,null)
    .then(
      (data) => {
        console.log("user registered");
        console.log(data);
        return this.props.ajax.get(true,this.props.url+'/forum/api/auth/');
      }
    )
    .then(
      (data) => {
        console.log("get result");
        console.log(data);
        this.props.setUserInfo(data);
        this.props.cbClose(true);
      }
    )
    .catch(
        (rejection) => {
        console.log(rejection);
        let str_error = '';
        if (rejection.hasOwnProperty('password')) {
          let str = 'Пароль: ';
          for (let i in rejection.password) {
            str += rejection.password[i] + ' ';
          }
          //alert(str);
          str_error += str +'\n';
        }
        if (rejection.hasOwnProperty('username')) {
          let str = 'Логин: ';
          for (let i in rejection.username) {
            str += rejection.username[i] + ' ';
          }
          //alert(str);
          str_error += str + '\n';
        }
        if (rejection.hasOwnProperty('email')) {
          let str = 'E-mail: ';
          for (let i in rejection.email) {
            str += rejection.email[i] + ' ';
          }
          //alert(str);
          str_error += str + '\n';
        }
        if (rejection.hasOwnProperty('captcha')) {
          let str = 'Captcha: ';
          for (let i in rejection.captcha) {
            str += rejection.captcha[i] + ' ';
          }
          //alert(str);
          str_error += str + '\n';
        }
        alert(str_error);
      }
    )
  }

  _cbClose = () => {
    this.props.cbClose(true);
  }

  _cbCloseAndToIndex = () => {
    this.props.cbClose(true);
    window.location.replace("/");
  }

  render () {
    return (
      <Modal style={{width : (this.props.fullWidth > 510 ? 510 : '100%')}} visible={this.props.visible}
        transparent={true} ariaHideApp={true} onBackdropPress={this.props.cbClose}
        animationType={'fade'}
        userStyle={{
          backgroundColor : 'rgba(0,0,0,0.6)',
        }}>
      <KeyboardAvoidingView style={{justifyContent: 'center',alignItems : 'center', flex : 1}}>
      <ScrollView style={{alignSelf : 'center', height: this.props.fullHeight, width : (this.props.fullWidth > 510 ? 510 : '100%')}} contentContainerStyle={{justifyContent: 'center',alignItems : 'center', flexGrow : 1}}> 
      { 
        !this.state.show_success ?     
        <>
          { this.state.showrestore ?
            <RestoreForm cbClose={this.props.cbClose}
              emailInput={this.emailInput}
              messageHint={'Перед сохранённением в базу данных сайта, пароли пользователей обрабатываются необратимым способом,. Для нас невозможно просто взять и выслать ваш пароль. Вы можете изменить пароль, запросив ссылку для изменения пароля на e-mail указанный при регистрации.'}
              submitPress={this.restorePasswordPress}
              goBack={this.backRestorePassword}
              submitEmailHint={this.state.submitEmailHint}
            />

          :
            <AuthForm passInput={this.passInput}
              emailInput={this.emailInput}
              email={this.state.user.email}
              passwd={this.state.user.pass}
              auth={this.state.auth}
              messageHint = {this.props.messageHint}
              cbClose={this.props.cbClose}
              loginPress={this.loginPress}
              restorePress={this.toRestorePassword}/>
          }
        </>
        :
        <SuccessOperationView cbClose={this._cbClose} history={this.props.history} closeToIndex={this._cbCloseAndToIndex}/>
      }
      </ScrollView>
  
      </KeyboardAvoidingView>

    </Modal>
    )
  }


}

export default LoginPageContainer;
