import React,{Component} from 'react'
import {View,StyleSheet, ScrollView, ActivityIndicator} from 'react-native'
import Ptext from "../../common/Ptext.js"
import Htext from "../../common/Htext.js"

import Button from "../../common/Button.js"
import FormInput from "../../common/FormInput.js"
import queryString from 'query-string'


class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hint : {
        first_name : undefined,
        middle_name : undefined,
        last_name : undefined,
        email : undefined,
        instagram : undefined,
        bid : undefined,
      },
      form : {
        first_name : undefined,
        middle_name : undefined,
        last_name : undefined,
        email : undefined, 
        instagram : undefined,
        bid : undefined,
      },
      loading : false,
      email_code : undefined,
      target : undefined,
    };
  }

  componentDidMount() {
    this.props.numer.get_from_url('/numer/api/user/', this._cbSetData);

    var values = queryString.parse(this.props.location.search);
    let q_str = '';
    var target = values.target;
    delete values.target;
    q_str += Object.keys(values).map(key => {
        if (key !== 'target') 
            return key + '=' + values[key]
        }).join('&');
    if (q_str.length > 1)
        target += '&'+q_str;
    this.setState({target : target});

  }

  _cbSetData = (data, status) => {
    if (status) {
      this.setState({loading : false, form : data});
      if (data.validate_url === null) {
          let user = this.props.user;
          user.ninfo = data;
          this.props.setUserInfo(user);
          this.props.history.push(this.state.target);
      }
    }else {
      if (data.hasOwnProperty('desc')){
        this.setState({'error_desc' : data['desc']});
      }else {
        this.setState({hint : data});
      }
    }
  }

  _cbSetValidateData = (data, status) => {
    if (status){
      this.setState({loading : false, form : data.ninfo});
      if (data.validate_url === null) {
          this.props.setUserInfo(data);
          this.props.history.push(this.state.target);
      }
    }else {
      if (data.hasOwnProperty('desc')){
        this.setState({'error_desc' : data['desc']});
      }else {
        this.setState({hint : data});
      }
    }
  }


  _editItem = (item, value) => {
    let form = this.state.form;
    let hint = this.state.hint;
    form[item] = value;
    if (hint[item] !== undefined) {
      hint[item] = undefined;
      this.setState({hint : hint});
    }
    this.setState({form : form});
  }

  _editBid = (value) => {
    let pp = this._dateInputValidator(value);
    let form = this.state.form;
    form.bid = pp;
    this.setState({form : form});
  }

  
  _applySchoolClick = () => {
  }

  _dateInputValidator = (value) => {
    let ppp = value.replace(/[^\d]/g, '');
    let days = ppp.slice(0,2);
    let mm = ppp.slice(2,4);
    let yy = ppp.slice(4);

    if (parseInt(days,10) > 31)
      days='31';
    if ((days.length)&&(days[0] > '3'))
        days = ("000" + parseInt(days,10)).slice(-2);

    if (parseInt(mm,10) > 12)
        mm='12';
    if ((mm.length)&&(mm[0]>'1'))
        mm = ("000" + parseInt(mm,10)).slice(-2);

    let tt = [];
    if (days.length)
      tt.push(days);
    if (mm.length)
        tt.push(mm);
    if (yy.length)
        tt.push(yy);
    ppp = tt.join('.');

    return ppp;
  }

  val_email_req_cb = (data, status) => {
    this.setState({validate_email : true});
  }

  validate_email_req = () => {
    this.setState({'error_desc' : undefined});
    this.props.validate_email_req(this.val_email_req_cb);
  }

  validate_email_ans = () => {
    this.setState({validate_email : false, 'error_desc' : undefined});
    this.props.validate_email_ans(this.val_email_ans_cb);
  }

  _validate_email_request_send = () => {
    let data = {
      'email' : this.state.form.email_temp,
    }
    this.props.numer.post_to_url(data, '/numer/api/user/validate/send/', this._cbSetData);
  }

  _validate_email_request_test = () => {
    let data = {
      'code' : this.state.email_code,
    }
    this.props.numer.post_to_url(data, '/numer/api/user/validate/test/', this._cbSetValidateData);
  }


  _saveFormClick = () => {
    this.props.numer.patch_to_url(this.state.form, '/numer/api/user/', this._cbSetData);
  }

  render () {
    if (this.state.loading) {
      return (
        <ScrollView style={{alignSelf : 'center', width: this.props.fullWidth, height : this.props.fullHeight, flexDirection : 'column', backgroundColor : '#fff', padding : 16}}
            contentContainerStyle={{justifyContent : 'flex-start',alignItems : 'center'}}>
            <ActivityIndicator size="large" color="#0000ff" />
        </ScrollView>
      )
    }else {
        if (this.state.form.email_valid)
            return (
              <ScrollView style={{alignSelf : 'center', width: this.props.fullWidth, height : this.props.fullHeight, flexDirection : 'column', backgroundColor : '#fff', padding : 16}}
                  contentContainerStyle={{justifyContent : 'flex-start',alignItems : 'center'}}>

                  {this.state.error_desc ?
                  <Ptext style={styles.error}>
                    {this.state.error_desc}
                  </Ptext>
                  :
                  null }
                  {this.state.done_desc ?
                  <Ptext style={styles.done_desc}>
                    {this.state.done_desc}
                  </Ptext>
                  :
                  null }
                <Htext style={styles.text}>Персональные данные</Htext>
                <Ptext style={styles.text}>Предоставьте следующие данные о себе:</Ptext>
              
                
                <View style={styles.formItem} >

                  <Ptext style={styles.itemHint}>Фамилия</Ptext>
                  <FormInput value={this.state.form.last_name} onChangeText={this._editItem.bind(this,'last_name')} placeholder={'Фамилия'} hint={this.state.hint.last_name} style={{width: 306}}/>
                </View>

                <View style={styles.formItem} >
                  <Ptext style={styles.itemHint}>Имя</Ptext>
                  <FormInput value={this.state.form.first_name} onChangeText={this._editItem.bind(this,'first_name')} placeholder={'Имя'} hint={this.state.hint.first_name} style={{width: 306}}/>

                </View>

                <View style={styles.formItem} >
                  <Ptext style={styles.itemHint}>Отчество</Ptext>
                  <FormInput value={this.state.form.middle_name} onChangeText={this._editItem.bind(this,'middle_name')} placeholder={'Отчество'} hint={this.state.hint.middle_name} style={{width: 306}}/>
                </View>


                <View style={styles.formItem} >
                  <Ptext style={styles.itemHint}>Дата рождения</Ptext>
                  <FormInput value={this.state.form.bid} onChangeText={this._editBid} placeholder={'ДД.ММ.ГГГГ'} hint={this.state.hint.middle_name} style={{width: 306}}/>
                </View>

                <View style={styles.formItem} >
                  <Ptext style={styles.itemHint}>
                    Аккаунт Instagram
                  </Ptext>
                  <FormInput value={this.state.form.instagram} onChangeText={this._editItem.bind(this,'instagram')} placeholder={'Instagram'} hint={this.state.hint.middle_name} style={{width: 306}}/>
                </View>

                <Ptext style={styles.text}>Нажимая сохранить, Вы выражаете согласие с Политикой конфеденциальности и Политикой обработки персональных данных</Ptext>
                <Button title={'Сохранить'} style={styles.buttonApply} onPress={this._saveFormClick}/>


              </ScrollView>
            )
    else
        return (
          <ScrollView style={{alignSelf : 'center', width: this.props.fullWidth, height : this.props.fullHeight, flexDirection : 'column', backgroundColor : '#fff', padding : 16}}
              contentContainerStyle={{justifyContent : 'flex-start',alignItems : 'flex-start'}}>
            {this.state.error_desc ?
            <Ptext style={styles.error}>
              {this.state.error_desc}
            </Ptext>
            :
            null }
            {this.state.done_desc ?
            <Ptext style={styles.done_desc}>
              {this.state.done_desc}
            </Ptext>
            :
            null }
            <Htext style={styles.text}>Подтверждение E-mail</Htext>
            
            <Ptext style={styles.text}>Укажите E-mail и подтвердите его высланным кодом.</Ptext>
            <View style={styles.formItem} >
                 <FormInput value={this.state.form.email_temp} onChangeText={this._editItem.bind(this,'email_temp')} placeholder={'E-mail'} hint={this.state.hint.email_temp} textContentType={'email'} style={{width: 306}}/>
                 <Ptext>На указанный Вами адрес электронной почты, прийдет письмо содержашее код подтвердения.</Ptext>
            </View>  
            <Button title={'Получить код'} onPress={this._validate_email_request_send}/>
            {this.state.form.validating_email && this.state.form.email_temp ? 
              <View style={styles.formItem} >
                  <FormInput value={this.state.email_code} onChangeText={(value) => {this.setState({'email_code' : value})}} placeholder={'Код'} hint={this.state.hint.email_code} style={{width: 306}}/>
                  <Button title={'Подтвердить'} style={styles.buttonApply} onPress={this._validate_email_request_test}/>
            </View>  
            :
            null
            }
          </ScrollView>

        )
    }
  }

}

const styles=StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    width : '100%',
    padding : 16,
    backgroundColor : '#fff',
  },
  text : {
    width : '100%',
    textAlign : 'left',
    marginTop : 5,
    marginBottom : 5,
  },
  textInput : {
    borderWidth : 2,
    minHeight : 40,
    borderRadius : 4,
    margin : 4,
    padding : 8,
    fontSize : 18,
    fontWeight : 'bold',
    width : '95%',
    marginBottom : 20,
    alignSelf : 'flex-start',
    borderColor : '#B4BBC2',
  },
  buttonSubscribe : {
    alignSelf : 'center',
  },
  error : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : 'red',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  done_desc : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : '#90ee90',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  formItem : {
    width : '100%',
    flexDirection : 'column',
    alignSelf : 'flex-start',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    marginTop : 5,
    marginBottom : 5,
  },
  formTextInput : {
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    width : '100%',
  },
  itemHint : {
    fontSize : 16,
    fontWeight : 'bold',
    lineHeight : 20,
    marginBottom : 8,
    alignSelf : 'center',
  },
  itemCheckHint : {
    fontSize : 16,
    fontWeight : 'bold',
    lineHeight : 20,
  },
  itemHintError : {
    fontSize : 12,
    lineHeight : 16,
    color : 'red',
    fontStyle : 'italic',
    fontWeight : 'bold',
    marginBottom : 8,
  },
  itemHintInfo : {
    fontSize : 12,
    lineHeight : 16,
    color : 'red',
    fontStyle : 'italic',
    fontWeight : 'bold',
    marginBottom : 8,
  },
});

export default ProfileForm;
