import React, { Component } from 'react'
import { Text, StyleSheet } from 'react-native'


export default class Titletext extends Component {
  render () {
    return (
      <Text style={[styles.titleText,this.props.style]}>
      {this.props.children}
      </Text>
    )
  }
}



const styles = StyleSheet.create({
  titleText: {
    fontFamily: 'Amaranth',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: 19,
  }
})
