import React,{Component} from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Ptext from "../../common/Ptext.js"
import Button from "../../common/Button.js"

class PromoSchool extends Component {
  render () {
    return (

        <View style={styles.wrapper}>
          <Ptext style={styles.headerLabel}>Обучение нумрологии</Ptext>
          <Ptext style={styles.descrLabel}>Открыт набор  в Школу нумерологии.</Ptext>
          <Button title="Записаться" style={styles.button_sign_school} styleTitle={styles.button_sign_school_title} onPress={this.props.applySchool} />
          <Image source={require('../../icons/img_book.png')} style={styles.imageStyle}/>
          <Button title="Заказать книгу" style={styles.button_order_book} onPress={this.props.orderBook}/>
        </View>


    )
  }


}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    alignSelf : 'center',
    alignItems : 'center',
    justifyContent : 'center',
    width : '100%',
    backgroundColor: '#fff',
    borderRadius : 4,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowColor: '#000',
    margin : 16,
    flex : 1,
    paddingTop : 16,
    paddingBottom : 1,
    shadowOffset: { height: 1, width: 0 },
  },
  imageStyle : {
    flex : 1,
    height : 185,
    width : '100%',
    marginTop : 20,
    alignSelf : 'center'
  },
  headerLabel : {
    fontSize : 20,
    fontWeight : 'bold',
    lineHeight : 24,
    textAlign : 'left'
  },
  descrLabel : {
    width : '90%',
    fontSize : 14,
    textAlign : 'left',
  },
  button_sign_school : {
    width : '90%',
    backgroundColor : '#45B4E3',
    borderColor : '#45B4E3',
  },
  button_sign_school_title : {
    color : 'white',
  },
  button_order_book : {
    width : '90%',
    backgroundColor : '#fff',
    borderColor : '#fff',
  }

});

export default PromoSchool;
