import React,{ Component } from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import Modal from 'modal-react-native-web';
import CheckBox from "../../common/CheckBox.js"
import Ptext from "../../common/Ptext.js"

class BlogModalMenu extends Component {
  constructor(props){
    super(props);
    this.state = {
      conf : this.props.conf,
    }
  }

  confChange = (param) => {
    let c = this.state.conf;
    if (c.hasOwnProperty(param))
      c[param] = !c[param];
    this.setState({conf : c});
  }

  componentWillUnmount () {
    this.props.confChange(this.state.conf);
  }


  render () {
    return (
      <Modal style={{width : (this.props.fullWidth > 510 ? 510 : '100%'), alignItems: 'center', justifyContent: 'center'}} visible={this.props.visible}
      transparent={true} ariaHideApp={true} onBackdropPress={this.props.cbClose}
      animationType={'fade'}
      userStyle={{
        backgroundColor : 'rgba(0,0,0,0.6)',
      }}
        onDismiss={this.props.cbClose} >
        <View style={{flex:1, alignSelf: 'center', justifyContent: 'center', alignItems: 'center'}}>
        <View style={{ padding: 20, borderRadius: 2, justifyContent : 'flex-start', alignItems : 'flex-start', width : '100%', flexDirection : 'column', alignSelf: 'center', maxWidth: 510, backgroundColor: 'white'}}>
            
            <View style={{flexDirection : 'row', width : '100%', paddingBottom : 20, alignItems : 'center'}}>
              <Ptext style={{textAlign : 'left', fontWeight : 'bold', fontSize : 18, flex : 1, alignSelf : 'flex-end'}}>Показывать</Ptext>
              <TouchableOpacity onPress={this.props.onBackdropPressed}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
              <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
              </svg>
              </TouchableOpacity>
            </View>
        <CheckBox style={styles.checkbox} labelStyle={styles.labelStyle} label="Текстовые публикации" checked={this.state.conf.text} onCheck={() => this.confChange('text')}/>
        <CheckBox style={styles.checkbox} labelStyle={styles.labelStyle} label="Видео записи" checked={this.state.conf.video} onCheck={() => this.confChange('video')}/>
        <CheckBox style={styles.checkbox} labelStyle={styles.labelStyle} label="Сториз и прямые эфиры" checked={this.state.conf.stories} onCheck={() => this.confChange('stories')}/>
        <Ptext style={{textAlign : 'left', fontWeight : 'bold', fontSize : 18, paddingTop : 10, paddingBottom : 10}}>Получать уведомления</Ptext>
        <CheckBox style={styles.checkbox} labelStyle={styles.labelStyle} label="О комментариях к избранному посту" checked={this.state.conf.comments_fav} onCheck={() => this.confChange('comments_fav')}/>
        <CheckBox style={styles.checkbox} labelStyle={styles.labelStyle} label="О комментариях к понравившемуся посту" checked={this.state.conf.comments_like} onCheck={() => this.confChange('comments_like')}/>
        <CheckBox style={styles.checkbox} labelStyle={styles.labelStyle} label="Об ответах на свой комментарий" checked={this.state.conf.comments_reply} onCheck={() => this.confChange('comments_reply')}/>
        <CheckBox style={styles.checkbox} labelStyle={styles.labelStyle} label="Об оценке своего комментарий" checked={this.state.conf.comments_reply_like} onCheck={() => this.confChange('comments_reply_like')}/>
        </View>
        </View>
      </Modal>
    )
  }
}

const styles = StyleSheet.create({
  wrapper : {
    width : '100%',
    alignSelf : 'center',
    maxWidth : 555,
    padding : 20,
    justifyContent : 'center',
    alignItems : 'center',
  },
  checkbox : {
    width : '100%',
    alignSelf : 'flex-start',
  },
  labelStyle: {
    textAlign: 'left',
  }
});

export default BlogModalMenu;
