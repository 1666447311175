import React,{ Component } from 'react'
import CreateChatView from "../../components/chat/create/createChatView.js"
import CreateGroupChatView from "../../components/chat/create/createGroupChatView.js"
import Modal from 'modal-react-native-web';

class CreateChatPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateChat : true,
    }
  }

  _showGroupChatView = () => {
    this.setState({showCreateChat : false});
  }

  _showChatView = () => {
    this.setState({showCreateChat : true});
  }


  render () {
    return (
      <Modal style={{width : '100%'}} visible={this.props.visible}
        transparent={false} ariaHideApp={false} animationType={'fade'}>
        {this.props.visible ?
         this.state.showCreateChat ?
          <CreateChatView closeCreateChat={this.props.closeCreateChat} fullWidth={this.props.fullWidth}
            showCreateGroupChat={this._showGroupChatView}
            misago={this.props.misago} category={this.props.category} cbCreated={this.props.cbCreated}
            selectedid={(new Map() : Map<string,boolean>)}
            fullclose={this.props.closeCreateChat}
            fullHeight={window.innerHeight}/>
          :
          <CreateGroupChatView fullWidth={this.props.fullWidth} closeCreateChat={this._showChatView}
            fullclose={this.props.closeCreateChat}
            misago={this.props.misago} category={this.props.category} cbCreated={this.props.cbCreated}/>
          : null
        }
      </Modal>
    )
  }
}
export default CreateChatPageContainer;
