import React, { Component } from 'react';
import { View, StyleSheet, TextInput, TouchableOpacity} from 'react-native';
import Ptext from "../../common/Ptext.js";
import Button from "../../common/Button.js";
//import SocialAuthIcon from "./socialAuth.js"



class RestoreForm extends Component {



  onPressClose = () => {
    if (this.props.cbClose)
      this.props.cbClose(this.props.auth);
  }

  render () {
      return (

        <View style={styleAuth.wrapper}>
          <View style={styleAuth.horWrapperAuth}>
            <Ptext style={styleAuth.headerCaption}>Восстановление пароля</Ptext>
            <TouchableOpacity style={styleAuth.headerCloseIcon} onPress={this.onPressClose}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
              <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
              </svg>
            </TouchableOpacity>
          </View>
          {
            this.props.submitEmailHint !== undefined ?
            <View style={styleAuth.doneWrapper}>
            <Ptext style={styleAuth.doneCaption}>{this.props.submitEmailHint}</Ptext>
            </View>
            :
            null
          }
          {
            this.props.messageHint !== undefined && this.props.messageHint !== '' ?
            <View style={styleAuth.hintWrapper}>
            <Ptext style={styleAuth.hint}>{this.props.messageHint}</Ptext>
            </View>
            : null
          }
          <TextInput placeholder={'E-mail'} style={styleAuth.loginInput}
            autoCompleteType={'email'}
            textContentType={'emailAddress'}
            onChangeText={this.props.emailInput}/>
          <Button title={'Восстановить'} style={styleAuth.buttonReg} theme={'light'}
            onPress={this.props.submitPress}/>
          <Button title={'Назад'} style={styleAuth.buttonRegBack} theme={'dark'}
            onPress={this.props.goBack}/>


        </View>

      )

  }

}

const styleAuth = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    width : '95%',
    maxWidth : 510,
    backgroundColor: '#fff',
    padding: 10,
  },
  horWrapper : {
    flexDirection : 'row',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'baseline',
    width : '100%',
    padding : 0,
    borderRadius : 4,
    borderWidth : 1,
    marginTop : 10,
    borderColor : '#B4BBC2',
    marginBottom : 8
  },
  horWrapperAuth : {
    flexDirection : 'row',
    alignSelf : 'center',
    justifyContent : 'space-around',
    alignItems : 'center',
    width : '100%',
    padding : 0,
    marginTop : 10,
    marginBottom : 8
  },
  loginInput : {
    width : '100%',
    borderRadius : 4,
    borderColor : '#B4BBC2',
    height : 40,
    borderWidth : 1,
    paddingLeft : 10,
    marginTop : 10,
    marginBottom : 10,
    alignSelf : 'flex-start'
  },
  passInput : {
    flex : 1,
    borderRadius : 0,
    height : 40,
    paddingLeft : 10,
    alignSelf : 'flex-start'
  },
  loginButton : {
    margin : 0,
    marginTop : 0,
    marginBottom : 0,
    alignSelf : 'flex-end'
  },
  hint : {
    textAlign : 'left',
    alignSelf : 'flex-start',
    fontStyle : 'italic',
    backgroundColor : '#f0f1f3',
  },
  hintWrapper : {
    alignSelf : 'flex-start',
    justifyContent : 'center',
    borderRadius : 4,
    alignItems : 'center',
    backgroundColor : '#f0f1f3',
    padding : 15,
  },
  doneWrapper : {
    alignSelf : 'flex-start',
    justifyContent : 'center',
    borderRadius : 4,
    flex : 1,
    alignItems : 'center',
    backgroundColor : '#90ee90',
    padding : 15,
  },
  doneCaption : {
    fontSize : 16,
  },
  forgot_password : {
    alignSelf : 'center',
    marginTop : 26,
  },
  forgot_password_caption : {
    fontSize : 16,
  },
  soc_caption : {
    alignSelf : 'center',
    marginTop : 26,
  },
  buttonReg : {
    maxWidth : 200,
    alignSelf : 'center',
    width : '100%',
  },
  buttonRegBack : {
    maxWidth : 200,
    alignSelf : 'center',
    width : '100%',
  },
  headerCaption : {
    fontSize : 20,
    fontWeight : 'bold',
    textAlign : 'left',
    width : '90%',
  },
  headerCloseIcon : {
    width : '10%'
  }

});

export default RestoreForm;
