import React, { Component } from 'react';
import { View } from 'react-native';
import Ptext from "../../common/Ptext.js"


class ChatUnderContructionPage extends Component {
  render () {
    return (
      <View>
        <Ptext>
           Раздел находится в стадии разработки
        </Ptext>
      </View>
    )
  }
}

export default ChatUnderContructionPage;
