import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import ActionProfile from './actions/actionprofile.js'
import ActionOrders from './actions/actionorders.js'
import ActionSettings from './actions/actionsettings.js'
import ActionLogout from './actions/actionlogout.js'


const menuItems = [
  ActionProfile,
 // ActionNotify,
 // ActionMessages,
  ActionOrders,
 // ActionSchool,
  ActionSettings,
  ActionLogout
]

/*
 <ActionProfile eventsCnt={undefined} onPress={this.props.closeTab}/>
          <ActionNotify eventsCnt={undefined} onPress={this.props.closeTab}/>
          <ActionMessages eventsCnt={undefined} onPress={this.props.closeTab}/>
          <ActionOrders eventsCnt={undefined} onPress={this.props.closeTab}/>
          <ActionSchool eventsCnt={undefined} onPress={this.props.closeTab}/>
          <ActionSettings eventsCnt={undefined} onPress={this.props.closeTab}/>
          <ActionLogout eventsCnt={undefined} onPress={this.props.logOut}/>

*/
class MenuContent extends Component {
  render () {
    return (
      <View style={[styles.actionsMenu,this.props.style]}>
           {menuItems.map((item, index, array) => {
            const It = item;
            return <It onPress={It === ActionLogout ? this.props.logOut : this.props.closeTab} />;
           }
           
           )
           
           
           }


          <View style={styles.actionLabel}/>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  actionsMenu : {
    flexDirection : 'column',
    backgroundColor: '#fff',
    alignItems : 'flex-start',
    alignContent: 'flex-start',
    justifyContent : 'flex-start',
    width : '100%',
    flexGrow : 1
  },
  actionColumn : {
    width : '100%',
    height : 48,
    flexDirection : 'row'
  },
  actionColumnIcon : {
    width : 64,
    paddingLeft : 24,
    paddingTop : 12,
  },
  actionLabel : {
    flexGrow : 1
  },
})


export default MenuContent;
