export class Numer{
  constructor(ajax, url) {
    this.ajax = ajax;
    this.debug = url;
    this.favs_list = '/numer/api/favs';
    this.favs_url = this.debug + this.favs_list;
    this.service_pay_url = '/numer/api/getpayurl/';
    this.flow_list_url = '/numer/api/flow/';
    this.promo_create_url = '/numer/api/crcodes/';
    this.promo_create_ticket_url = '/numer/api/crtickcodes/';
    this.promo_test = '/numer/api/tcodes/';
    this.promo_test_ticket = '/numer/api/ticktestcodes/';


    this.flow_view_url = '/numer/api/fdesc'
    this.review_api_url = '/numer/api/index/review/'
    this.faq_api_url = '/numer/api/index/faq/'
    this.api_start = '/numer/api/';
    this.api_experts_list = this.api_start+'experts/';
    this.api_experts_clients = this.api_start+'expclients/';
    this.api_clients = this.api_start + 'clients/'
    this.api_experts_info = this.api_start + 'exp_add/';
    this.api_clear_expert_balance = this.api_start+'cl_exp';
    this.api_client_pay = this.api_start+'pay_to/';
    this.api_client_pers_pay = this.api_start+'curator/';
    this.api_client_apply_pers = this.api_start+'curator/0/';
    this.api_client_apply_pers_purl = this.api_start+'curator_purl/';
    this.eventslist = this.api_start + 'events/';
    this.eventsActive = this.api_start + 'actevents/';
    this.buyTicket = this.eventslist + 'ticket/';
    this.event_get_promo_url = '/numer/api/tickcodes/?event=';


    this.flow_get_students_url = '/numer/api/schoolflow/?flow=';
    this.flow_get_promo_url = '/numer/api/codes/?flow=';
    this.flow_get_curators_url = '/numer/api/curatorsflow/?flow=';
    this.flow_get_perscurators_url = '/numer/api/perscurrflow/?flow=';
    this.flow_get_extend_url = '/numer/api/extendflow/?flow=';
    this.get_recruitment_flow_url='/numer/api/recr_flow/';
    this.get_register_flow_url='/numer/api/reg_flow/';
    this.save_app_form='/numer/api/addrecord/';
    this.save_app_curator = '/numer/api/addcurator/';
    this.verifyPhone_url = '/get_sms_code/';
    this.confirmSMSCode_url = '/check_sms_code/';
  }

  do_get_favs_list = (cbSetData) => {
    this.ajax.get(true,this.favs_url+'s/').then(
      (data) => {
        cbSetData(data);
      }
    )
    .catch(
      (rejection) => {
        console.log(rejection);
        cbSetData([]);
      }
    )
  }

  do_delete_favs = (id) => {
    this.ajax.delete(true,this.favs_url+'/'+id).then(
      (data) => {


      }
    )
    .catch(
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  do_addNew_favs = (item,cbSet) => {
    this.ajax.post(true,this.favs_url+'s/',item).then(
      (data) => {

        cbSet(data);
      }
    )
    .catch(
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  do_getpayment_url = (data,cbSet) => {
    this.ajax.post(true,this.debug+this.service_pay_url,data).then(
      (data) => {
        console.log(data);
        cbSet(data);
      }
    )
    .catch(
      (rejection) => {
        console.log(rejection);
      }
    )
  }

  do_get_flow_list = (cbSet) => {
    this.ajax.get(true,this.debug + this.flow_list_url).then(
      (data) => {
        cbSet(data);
      }
    )
    .catch(
      (rejection) => {
        console.log(rejection);

      }
    )
  }

  do_get_create_flow = (data,cbSet) => {
    this.ajax.post(true,this.debug + this.flow_list_url,data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_create_promo = (data, cbSet) => {
    this.ajax.post(true,this.debug + this.promo_create_url,data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_flow_descr = (id,cbSet) => {
    this.ajax.get(true,this.debug + this.flow_view_url + '/'+id).then(
      (data) => {
        cbSet(data);
      }
    )
    .catch(
      (rejection) => {
        console.log(rejection);

      }
    )
  }

  do_get_faq = (cbSet) => {
    this.ajax.get(true,this.debug + this.faq_api_url).then(
      (data) => {
        cbSet(data);
      }
    )
    .catch(
      (rejection) => {

      }
    )
  }

  do_get_review = (cbSet) => {
    this.ajax.get(true,this.debug + this.review_api_url).then(
      (data) => {
        cbSet(data);
      }
    )
    .catch(
      (rejection) => {

      }
    )
  }

  save_flow_details = (id,data,cbSet) => {
    data.created = undefined;
    this.ajax.put(true,this.debug + this.flow_view_url + '/'+id+'/',data)
    .then (
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_students_list = (id,cbSet) => {
    this.ajax.get(true,this.debug + this.flow_get_students_url + id).then(
      (data) => {
        cbSet(true,data);
      }
    )
    .catch(
      (rejection) => {
        cbSet(false,rejection);
      }
    )
  }


do_get_codes_list = (id,cbSet) => {
  this.ajax.get(true,this.debug + this.flow_get_promo_url + id).then(
    (data) => {
      cbSet(data, true);
    }
  )
  .catch(
    (rejection) => {
      cbSet(rejection, false);
      console.log(rejection);
    }
  )
}



  do_get_curators_list = (id,cbSet) => {
    this.ajax.get(true,this.debug + this.flow_get_curators_url + id).then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection, false);

      }
    )
  }

  do_get_perscurators_list = (id,cbSet) => {
    this.ajax.get(true,this.debug + this.flow_get_perscurators_url + id).then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection, false);

      }
    )
  }

  do_get_extend_list = (id, cbSet) => {
    this.ajax.get(true,this.debug + this.flow_get_extend_url + id).then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection, false);
      }
    )
  }

  get_recruitment_flow = (cbSet, slug) => {
    this.ajax.get(true, this.debug+this.get_recruitment_flow_url + slug)
    .then (
      (data) => {
                cbSet(data,true);
                }
    )
    .catch (
      (rejection) => {
                cbSet(rejection,false);
              }
    )
  }

  get_register_flow= (cbSet) => {
    this.ajax.get(true, this.debug+this.get_register_flow_url)
    .then (
      (data) => {
                cbSet(data,true);
                }
    )
    .catch (
      (rejection) => {
                cbSet(rejection,false);
              }
    )
  }

  do_create_new_school_app = (data,cbSet) => {
    this.ajax.post(false,this.debug+this.save_app_form,data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }
  do_create_new_school_curator = (data,cbSet) => {
    this.ajax.post(false,this.debug+this.save_app_curator,data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  get_service_pay_details = (order,type,email,cbSet) => {
    this.ajax.get(false,this.debug+this.api_start+type+'/'+order+'/?email='+email)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  get_payment_url =  (order,type,data,cbSet) => {
    this.ajax.put(false,`${this.debug + this.api_start + type}url/${order}/`, data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_getvalidate_url = (data, cbSet) => {
    this.ajax.post(false,this.debug+this.api_start+'valid/', data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_experts_list = (cbSet) => {
    this.ajax.get(false,this.debug+this.api_experts_list)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }
  do_post_create_expert = (data, cbSet) => {
    this.ajax.post(false,this.debug+this.api_experts_list, data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_experts_clients_list = (id, cbSet) => {
    this.ajax.get(false,this.debug+this.api_experts_clients+id)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_clients_list = (id, cbSet) => {
    this.ajax.get(false,this.debug+this.api_clients+id)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_create_new_client_pay = (data, url, cbSet ) => {
    this.ajax.post(false,this.debug+this.api_client_pay+url, data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }
  do_create_new_pers_curator = (data,cbSet) => {
    this.ajax.post(false,this.debug+this.api_client_pers_pay, data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  get_expert_pay_info = (url, cbSet) => {
    this.ajax.get(false,this.debug+this.api_experts_info+url)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }
  clear_expert_balance = (id, cbSet) => {
    this.ajax.get(false, this.debug+this.api_clear_expert_balance+'/'+id)
      .then(
        (data) => {
          cbSet(data,true);
        }
      )
      .catch(
        (rejection) => {
          cbSet(rejection,false);
        }
      )
  }
  test_promo = (data, cbSet) => {
    this.ajax.post(false,this.debug+this.promo_test, data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  test_promo_ticket = (data, cbSet) => {
    this.ajax.post(false,this.debug+this.promo_test_ticket, data)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_events_list = (cbSet) => {
    this.ajax.get(false,this.debug + this.eventslist)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false)
      }
    )
  }

  do_get_create_event = (form, cbSet) => {
    this.ajax.post(false,this.debug + this.eventslist,form).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_events = (cbSet) => {
    this.ajax.get(false,this.debug + this.eventsActive)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false)
      }
    )
  }

  create_new_ticket = (form, cbSet) => {
    this.ajax.post(false,this.debug + this.buyTicket,form).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_event_descr = (id, cbSet) => {
    this.ajax.get(false,this.debug + this.eventslist + id+'/')
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false)
      }
    )
  }

  do_get_ticket_codes_list = (id, cbSet) => {
    this.ajax.get(false,this.debug + this.event_get_promo_url + id)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false)
      }
    )
  }

  do_get_create_ticket_promo = (data, cbSet) => {
    this.ajax.post(false,this.debug + this.promo_create_ticket_url,data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  do_get_ticket_list = (evId, cbSet) => {
    this.ajax.get(false,this.debug + this.eventslist + evId + '/tickets')
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false)
      }
    )
  }
  get_accept_pers_curator_url = (form, cbSet) => {
    this.ajax.post(false,this.debug + this.api_client_apply_pers,form).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  get_accept_pers_curator_pay_url = (id, cbSet) => {
    this.ajax.get(false,this.debug + this.api_client_apply_pers_purl + id)
    .then(
      (data) => {
        cbSet(data,true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false)
      }
    )
  }

  send_verify_sms_to_phone = (data, cbSet) => {
    this.ajax.post(true,this.debug + this.verifyPhone_url,data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  send_test_verify_sms_code = (data, cbSet) => {
    this.ajax.post(true,this.debug + this.confirmSMSCode_url,data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  get_from_url = (url, cbSet) => {
    this.ajax.get(true,this.debug + url).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )

  }

  post_to_url = (data, url, cbSet) => {
    this.ajax.post(true,this.debug +  url, data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )

  }

  patch_to_url = (data, url, cbSet) => {
    this.ajax.patch(true,this.debug + url, data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  put_to_url = (data, url, cbSet) => {
    this.ajax.put(true,this.debug + url, data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }

  put_to_url_upload = (data, url, cbSet) => {
    this.ajax.put_upload(true,this.debug + url, data).then(
      (data) => {
        cbSet(data, true);
      }
    )
    .catch(
      (rejection) => {
        cbSet(rejection,false);
      }
    )
  }


}
