import React, { Component } from 'react'
import { Text, StyleSheet } from 'react-native'



export default class Htext extends Component {
  render () {
    return (
      <Text style={[styles.headerText,this.props.style]}>
      {this.props.children}
      </Text>
    )
  }
}



const styles = StyleSheet.create({
  headerText: {
    textAlign : 'center',
    fontFamily: 'Russo One',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 24,
    lineHeight: 28,
    letterSpacing : 3,
    marginTop : 5,
    marginBottom : 5,
  }
})
