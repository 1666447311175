import React, {Component} from 'react';
import { StyleSheet, View, ScrollView, Image, TouchableOpacity } from 'react-native';
import Ptext from "../../common/Ptext.js"
import globalStyle from "../../styles/globalStyle.js"
import { isArray } from 'jquery';
import ServiceIconViewPage from "../../common/serviceIconViewPage"



class ServicesViewPage extends Component {

  

  render () {
    
    return (
      <View style={[styles.page,{width: '100%', alignSelf : 'center'}]} >
        <TouchableOpacity style={[styles.header_wrapper]} onPress={() => this.props.history.push('/services/')}>
          <View style={[styles.bar,{width : this.props.fullWidth > 510 ? 130 : 24}]}/>
          <Ptext style={styles.header_caption}> Услуги</Ptext>
        </TouchableOpacity>
        <ScrollView horizontal={true} style={[styles.scrollWrapper,{marginHorizontal: this.props.fullWidth > 510 ? 140 : 0}]} contentContainerStyle={styles.scrollContainer}>
          {this.props.services !== undefined && isArray(this.props.services) ? 
            this.props.services.map((item, index, arrya)=> {
              return(
                <ServiceIconViewPage img_url={item.image_light.file} title={item.title} key={'12'+index} url={'/services/desc/'+item.id} history={this.props.history}/>
              )
            })
            :
            null
          }
        </ScrollView>
      </View>
    );
  }
}
//
const styles = StyleSheet.create({
  page : {
    flexDirection : 'column',
    alignItems : 'center',
    alignContent: 'flex-start',
    justifyContent : 'flex-start',
    backgroundColor: 'white',
  },
  scrollWrapper: {
    width: '100%',
    maxWidth: 1140,
  },
  bar : {
    width : 24,
    height : 1,
    backgroundColor : 'black',
  },
  header_wrapper : {
    width : '100%',
    alignSelf : 'flex-start',
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
    height: 85,
  },
  header_caption : {
    letterSpacing: 2,
    textTransform: 'uppercase',
    fontSize : 18,
    lineHeight : 20,
    fontStyle : 'normal',
    fontWeight : 'normal',
    color: 'black',
  }
})

export default ServicesViewPage;
