import React, {Component} from 'react';
import { Image, TouchableOpacity } from 'react-native';
import Ptext from "./Ptext.js"

class ServiceIconViewPage extends Component {
    render () {
      return(
        <TouchableOpacity style={{width : 140, margin: this.props.margin ? this.props.margin : 15, alignItems: 'center', justifyContent: 'flex-start'}} onPress={()=>this.props.history.push(this.props.url)}>
          <Image source={this.props.img_url} style={{width: 60, height : 66}}/>
          <Ptext style={{color: this.props.color ? this.props.color : 'black'}}>{this.props.title}</Ptext>
        </TouchableOpacity>
      )
    }
  }
export default ServiceIconViewPage;
  