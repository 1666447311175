import React, {Component} from 'react'
import { View, StyleSheet, FlatList,TouchableOpacity, TextInput } from 'react-native';
import Ptext from "../../common/Ptext.js"

class RemoveButton extends Component {
  render () {
    return (
      <TouchableOpacity onPress={this.props.onPress} style={this.props.style}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.00008 17.8333C7.00008 18.2754 7.17568 18.6993 7.48824 19.0118C7.8008 19.3244 8.22472 19.5 8.66675 19.5H15.3334C15.7754 19.5 16.1994 19.3244 16.5119 19.0118C16.8245 18.6993 17.0001 18.2754 17.0001 17.8333V7.83333H7.00008V17.8333ZM8.66675 9.5H15.3334V17.8333H8.66675V9.5ZM14.9167 5.33333L14.0834 4.5H9.91675L9.08342 5.33333H6.16675V7H17.8334V5.33333H14.9167Z" fill="#B4BBC2"/>
        </svg>

      </TouchableOpacity>
    )
  }
}

class FavouritsListItem extends Component{
  constructor(props){
    super(props);
    this.state = {

    };
  }

  render () {
    return(
      <View key={this.props.id} style={itemStyle.wrapper}>

        <TouchableOpacity onPress={this.props.onPressItem} style={itemStyle.caption}>
          <Ptext style={itemStyle.name}>{this.props.name}</Ptext>
          <Ptext style={itemStyle.date}>{this.props.date}</Ptext>
        </TouchableOpacity>
        <RemoveButton style={itemStyle.delete} onPress={this.props.onDelete}/>
      </View>
    )
  }
}

const itemStyle = StyleSheet.create({
  wrapper : {
    flexDirection : 'row',
    minHeight : 40,
    justifyContent : 'space-between',
    alignItems : 'flex-start',
    backgroundColor : '#fff',
    margin : 4,
  },
  caption : {
    width : '90%',
    backgroundColor : '#fff',
    justifyContent : 'flex-start',
    alignItems : 'flex-start'
  },
  delete : {
    width : '10',
    backgroundColor : '#fff',
  },
  name : {
    fontSize : 16,
    color : 'black',
    backgroundColor : '#fff',

  },
  date : {
    fontSize : 12,
    color : '#B4BBC2',
    backgroundColor : '#fff',
  }
});

class SearchBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value : '',
      outln : 'none'
    }
  }

  _clearText = () => {
    this.setState({value : ''});
    if (this.props.onChangeText)
      this.props.onChangeText('');
  }
  _onChangeText = (value) => {

    this.setState({value:value});
    if (this.props.onChangeText)
      this.props.onChangeText(value);
  }
  _onFocus = (val) => {
    //this.state.outln = 'solid';
    //console.log(val);

  }


  render () {
    return (
      <View style={[styleSearch.wrapper,{outlineStyle : this.state.outln}]}>
        <View style={styleSearch.magnify}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.91667 2.5C9.35326 2.5 10.731 3.07068 11.7468 4.0865C12.7627 5.10233 13.3333 6.48008 13.3333 7.91667C13.3333 9.25833 12.8417 10.4917 12.0333 11.4417L12.2583 11.6667H12.9167L17.0833 15.8333L15.8333 17.0833L11.6667 12.9167V12.2583L11.4417 12.0333C10.4917 12.8417 9.25833 13.3333 7.91667 13.3333C6.48008 13.3333 5.10233 12.7627 4.0865 11.7468C3.07068 10.731 2.5 9.35326 2.5 7.91667C2.5 6.48008 3.07068 5.10233 4.0865 4.0865C5.10233 3.07068 6.48008 2.5 7.91667 2.5V2.5ZM7.91667 4.16667C5.83333 4.16667 4.16667 5.83333 4.16667 7.91667C4.16667 10 5.83333 11.6667 7.91667 11.6667C10 11.6667 11.6667 10 11.6667 7.91667C11.6667 5.83333 10 4.16667 7.91667 4.16667Z" fill="#B4BBC2"/>
        </svg>
        </View>

        <TextInput
          placeholder={this.props.placeholder}
          onChangeText={this._onChangeText}
          onFocus={this._onFocus}
          clearButtonMode={'always'}
          value={this.state.value}
          style={styleSearch.textinput}
          />
        <TouchableOpacity onPress={this._clearText} style={styleSearch.clear}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="#B4BBC2"/>
            <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="#B4BBC2"/>
          </svg>
        </TouchableOpacity>
      </View>
    )
  }
};

const styleSearch = StyleSheet.create({
  wrapper : {
    width : '100%',
    borderRadius : 4,
    borderColor : '#B4BBC2',
    borderWidth : 1,
    minHeight : 40,
    flexDirection : 'row',
    alignItems : 'center',
    justifyContent : 'flex-start',
    backgroundColor : '#fff',
  },
  textinput : {
    outlineStyle : 'none',
    width : '80%',
    backgroundColor : '#fff',
  },
  magnify : {
    width : '10%',
    backgroundColor : '#fff',
  },
  clear : {
    width : '10%',
    backgroundColor : '#fff',
  }
});


class FavouritsList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      data : Object.assign([],props.data),
      text : '',
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({data : newProps.data});
  }

  _onPressItem = (item) => {
    if (this.props.onSelect) {
      this.props.onSelect(item);
      this.props.cbClose();
    }
  }

  _onDelete = (item) => {
    let i = 0;
    for (i = 0; i < this.state.data.length; i++)
      if (item.id === this.state.data[i].id) {
        this.props.onDelete(i);
        return;
      }

  }

  _renderItem = ({item}) => (
    <FavouritsListItem
      key={item.id}
      name={item.name}
      date={item.date}
      style={stylesAll.items}
      onPressItem={() => this._onPressItem(item)}
      onDelete={() => this._onDelete(item)}
    />
  );

//  _keyExtractor = (item, index) => item.key;
  _keyExtractor = (item, index) => item.id;

  searchFilterFunction = (text) => {
    /*if (this.state.arrayholder === undefined)
      return;
    const newData = this.state.arrayholder.filter(item => {
    const itemData = `${item.name.toUpperCase()}
    ${item.date.toUpperCase()}`;
     const textData = text.toUpperCase();

     return itemData.indexOf(textData) > -1;
    });*/
    this.setState({ text : text });
  }

  renderHeader = () => {
    return (
      <SearchBar style={stylesAll.searchBar}
        placeholder="Имя или дата"
        onChangeText={text => this.searchFilterFunction(text)}
      />
    );
  };

  render () {
    const newData = this.state.data.filter(item => {
      const itemData = `${item.name.toUpperCase()}
      ${item.date.toUpperCase()}`;
      const textData = this.state.text.toUpperCase();

      return itemData.indexOf(textData) > -1;
    });

    return (

        <FlatList
          data={newData}
          keyExtractor={this._keyExtractor}
          renderItem={this._renderItem}
          style={this.props.style}
          extraData={this.state}
          ListHeaderComponent={this.renderHeader}
        />
    )
  }

}

const stylesAll = StyleSheet.create({
  searchBar : {
    margin : 5,
    padding : 5,
    backgroundColor : '#fff',
  },
  listStyle : {
    margin : 5,
    padding : 5,
    backgroundColor : '#fff',
  },
  wrapper : {
    width : '100%',
  }
});
export default FavouritsList;
