import React, { Component } from 'react';
import { View, StyleSheet,TouchableOpacity } from 'react-native';
import Ptext from "../../../common/Ptext.js"

import { NavLink } from '../../router/react-router';


class ActionOrders extends Component {
  render () {
      return (
        <NavLink to="/profile/orders" style={{textDecoration : 'none',color : '#000'}}>
          <TouchableOpacity onPress={this.props.onPress}>
          <View style={styles.actionColumn}>
            <View style={styles.actionColumnIcon}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 6C20.58 6 21.05 6.2 21.42 6.59C21.8 7 22 7.45 22 8V19C22 19.55 21.8 20 21.42 20.41C21.05 20.8 20.58 21 20 21H4C3.42 21 2.95 20.8 2.58 20.41C2.2 20 2 19.55 2 19V8C2 7.45 2.2 7 2.58 6.59C2.95 6.2 3.42 6 4 6H8V4C8 3.42 8.2 2.95 8.58 2.58C8.95 2.2 9.42 2 10 2H14C14.58 2 15.05 2.2 15.42 2.58C15.8 2.95 16 3.42 16 4V6H20ZM4 8V19H20V8H4ZM14 6V4H10V6H14Z" fill="black"/>
              </svg>
            </View>
            <View style={styles.actionLabel}>
              <Ptext style={styles.actionCaptionLabel}>
              Заказы услуг
              </Ptext>
              {this.props.eventsCnt ?
              <View style={styles.actionEventsLabel}>
              <Ptext style={styles.actionEventsText}>
              {this.props.eventsCnt}
              </Ptext>
              </View> : null }
            </View>
          </View>
            </TouchableOpacity>
        </NavLink>
      )
    }
}

const styles = StyleSheet.create({
  actionColumn : {
    width : '100%',
    height : 48,
    flexDirection : 'row'
  },
  actionColumnIcon : {
    width : 64,
    paddingLeft : 24,
    paddingTop : 12,
  },
  actionLabel : {
    flex : 1,
    flexDirection : 'row',
    alignItems : 'center',
    justifyContent : 'flex-start',
    paddingLeft : 5
  },
  actionCaptionLabel : {
    fontSize : 16
  },
  actionEventsView : {
    flex : 1,
  },
  actionEventsText : {
    borderRadius : 10,
    borderWidth : 1,
    borderColor : '#45B4E3',
    color : '#45B4E3',
    borderStyle : 'solid',
    textAlign : 'left',
    paddingLeft : 8,
    paddingRight : 8,
    marginLeft : 8,
    marginRight : 8,
    fontSize : 16
  }
})
export default ActionOrders;
