import React,{Component} from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Ptext from "../../common/Ptext.js"
import Button from "../../common/Button.js"

class PromoService extends Component {
  render () {
    return (

        <View style={[styles.wrapper, {flexDirection : this.props.fullWidth > 400 &&  this.props.fullWidth < 720 ? 'row' : 'column' }]}>
          <Image source={require('../../icons/folder.png')} style={styles.imageStyle} resizeMode="center"/>
          <View style={styles.vertWrapper}>
            <Ptext style={styles.headerLabel}>Профессиональное описание психоматрицы</Ptext>
            <Ptext style={styles.descrLabel}>Опытный специлист подготовит подробный разбор
            психоматрицы и ответит на возникшие вопросы.</Ptext>
          <Button title="Подробнее об услуге" style={{width: '100%'}} onPress={this.props.onService}/>

          </View>
        </View>


    )
  }


}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'row',
    flexWrap : 'wrap',
    alignSelf : 'center',
    alignItems : 'center',
    justifyContent : 'center',
    width : '100%',
    backgroundColor: '#fff',
    paddingBottom : 16,
    borderRadius : 4,
    shadowOpacity: 0.2,
    shadowRadius: 2,
    shadowColor: '#000',
    margin : 16,
    shadowOffset: { height: 1, width: 0 },
  },
  vertWrapper : {
    flexDirection : 'column',
    width : '100%',
    padding : 20,
    flex : 4,
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  imageStyle : {
    width : 120,
    height : 120,
    flex : 1,
    marginTop : 20,
    alignSelf : 'center',
  },
  headerLabel : {
    fontSize : 20,
    fontWeight : 'bold',
    lineHeight : 24,
    textAlign : 'left'
  },
  descrLabel : {
    width : '90%',
    fontSize : 14,
    textAlign : 'left',
  }

});

export default PromoService;
