import React, { Component } from 'react';
import { View, Image, StyleSheet,TouchableOpacity,TextInput, FlatList, ActivityIndicator } from 'react-native';
import Ptext from "../../common/Ptext.js"


class ReplyComment extends Component {
//

  _likeReplyCb = (data) => {
    this.props.setLikeReply(data);
  }


  _pushLikeReply = (id) => {
    if (this.props.auth)
      this.props.wagtail.debug_add_reply_like(id, this._likeReplyCb);
    else {
      this.props.login();
    }
  }

  render () {
    return(

        <View style={[styles.wrapperHorizontal, {paddingLeft : 24}]}>
          <Image source={this.props.comment.user.avatar} style={{width:24,height:24,marginLeft : 4, marginRight : 4}}/>
          <View style={styles.wrapperVertComment}>

            <Ptext style={styles.username}>{this.props.comment.user.name}</Ptext>


            <Ptext style={styles.comment}>{this.props.comment.text}</Ptext>
            <View style={[styles.wrapperHorizontal,{width : '100%', marginTop : 0}]}>
              <TouchableOpacity onPress={() => this._pushLikeReply(this.props.comment.id)}>
              {(this.props.comment.like == null || !this.props.comment.like.liked) ?
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.06659 12.3667L7.99992 12.4333L7.92659 12.3667C4.75992 9.49333 2.66659 7.59333 2.66659 5.66667C2.66659 4.33333 3.66659 3.33333 4.99992 3.33333C6.02659 3.33333 7.02659 4 7.37992 4.90667H8.61992C8.97325 4 9.97325 3.33333 10.9999 3.33333C12.3333 3.33333 13.3333 4.33333 13.3333 5.66667C13.3333 7.59333 11.2399 9.49333 8.06659 12.3667ZM10.9999 2C9.83992 2 8.72659 2.54 7.99992 3.38667C7.27325 2.54 6.15992 2 4.99992 2C2.94659 2 1.33325 3.60667 1.33325 5.66667C1.33325 8.18 3.59992 10.24 7.03325 13.3533L7.99992 14.2333L8.96659 13.3533C12.3999 10.24 14.6666 8.18 14.6666 5.66667C14.6666 3.60667 13.0533 2 10.9999 2Z" fill="black"/>
                  </svg>
                  :
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.99992 12.2333L6.03325 11.3533C2.59992 8.24 0.333252 6.18 0.333252 3.66667C0.333252 1.60667 1.94659 0 3.99992 0C5.15992 0 6.27325 0.54 6.99992 1.38667C7.72659 0.54 8.83992 0 9.99992 0C12.0533 0 13.6666 1.60667 13.6666 3.66667C13.6666 6.18 11.3999 8.24 7.96659 11.3533L6.99992 12.2333Z" fill="#F5222D"/>
                  </svg>
              }

              </TouchableOpacity>
              {
                this.props.comment.like !== null ?
                  <Ptext style={styles.liked_text}>{this.props.comment.like.cnt} нравится</Ptext>
                  :
                  null
              }

              <TouchableOpacity onPress={() => this.props.activate_anwer(this.props.root_id,this.props.comment.user.name)}>
                <Ptext style={styles.liked_text}>Ответить</Ptext>
              </TouchableOpacity>
            </View>
          </View>
          <Ptext style={styles.time}>{this.props.comment.date}</Ptext>
        </View>

    )
  }
}

class RootComment extends Component {
//
  _setAnswer = (id,user) => {
    this.props.setCommentId(id,user);
  }
  _setReplyAnser = (id,user) => {
    this.props.setCommentId(id,user)
  }

  _likeCommentCb = (data) => {
    //console.log(data);
    this.props.setLikeComment(data);
  }

  _pushLikeComment = (id) => {
    if (this.props.auth)
      this.props.wagtail.debug_add_comment_like(id,this._likeCommentCb);
    else {
      this.props.login();
    }
  }

  render () {
    return(
        <View style={styles.wrapperVertComment}>
          <View style={styles.wrapperHorizontal}>
            <Image source={this.props.post.user.avatar} style={{width:24,height:24,marginLeft : 4, marginRight : 4}}/>
            <View style={styles.wrapperVertComment}>

              <Ptext style={styles.username}>{this.props.post.user.name}</Ptext>

              <Ptext style={[styles.comment,{marginBottom : 6}]}>{this.props.post.text}</Ptext>

              <View style={[styles.wrapperHorizontal,{width : '100%', marginTop : 0}]}>
                <TouchableOpacity onPress={() => this._pushLikeComment(this.props.post.id)}>
                {(this.props.post.like == null || !this.props.post.like.liked) ?
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.06659 12.3667L7.99992 12.4333L7.92659 12.3667C4.75992 9.49333 2.66659 7.59333 2.66659 5.66667C2.66659 4.33333 3.66659 3.33333 4.99992 3.33333C6.02659 3.33333 7.02659 4 7.37992 4.90667H8.61992C8.97325 4 9.97325 3.33333 10.9999 3.33333C12.3333 3.33333 13.3333 4.33333 13.3333 5.66667C13.3333 7.59333 11.2399 9.49333 8.06659 12.3667ZM10.9999 2C9.83992 2 8.72659 2.54 7.99992 3.38667C7.27325 2.54 6.15992 2 4.99992 2C2.94659 2 1.33325 3.60667 1.33325 5.66667C1.33325 8.18 3.59992 10.24 7.03325 13.3533L7.99992 14.2333L8.96659 13.3533C12.3999 10.24 14.6666 8.18 14.6666 5.66667C14.6666 3.60667 13.0533 2 10.9999 2Z" fill="black"/>
                  </svg>
                  :
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.99992 12.2333L6.03325 11.3533C2.59992 8.24 0.333252 6.18 0.333252 3.66667C0.333252 1.60667 1.94659 0 3.99992 0C5.15992 0 6.27325 0.54 6.99992 1.38667C7.72659 0.54 8.83992 0 9.99992 0C12.0533 0 13.6666 1.60667 13.6666 3.66667C13.6666 6.18 11.3999 8.24 7.96659 11.3533L6.99992 12.2333Z" fill="#F5222D"/>
                  </svg>
                }
                </TouchableOpacity>
                {this.props.post.like !== null ?
                  <Ptext style={styles.liked_text}>{this.props.post.like.cnt} нравится</Ptext>
                :
                  null
                }
                <TouchableOpacity onPress={() => this._setAnswer(this.props.post.id,this.props.post.user.name)} style={{marginLeft : 20}}>
                  <Ptext style={styles.liked_text}>Ответить</Ptext>
                </TouchableOpacity>
              </View>

            </View>
            <Ptext style={styles.time}>{this.props.post.date}</Ptext>
          </View>
          {
            this.props.post.reply.map((reply,key,arr)=>{
                return (
                  <ReplyComment key={key} id={key} comment={reply}  activate_anwer={this._setAnswer} root_id={this.props.post.id} wagtail={this.props.wagtail}
                     auth={this.props.auth}
                     login={this.props.login}
                     setLikeReply={this.props.setLikeReply}/>
                )
              }
            )
          }
          </View>

    )
  }
}
/*

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12ZM6 13H14L10.5 16.5L11.92 17.92L17.84 12L11.92 6.08L10.5 7.5L14 11H6V13Z" fill="#B4BBC2"/>
</svg>


*/

class CommentsFull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments : [],
      loading : false,
      offset : 0,
      total_offset : 1,
      textInput : '',
      comment_id : -1,
      borderColor : '#F1F3F5',
    }
  }

  componentDidMount() {
    this.setState({loading : true});
    this._handleLoadMore();
  }


  _msgSend = () => {

    //this.props.wagtail.do_post_comment(this.props.chat,this.state.message,[],this._postResult);
  }

  _renderFooter = () => {
      return (
        <View style={{width:'100%',justifyContent : 'center',alignSelf : 'center',alignItems : 'center'}}>
        {
          this.state.loading ?
          <ActivityIndicator size="large" color="#0000ff" />
          :
            null
        }
        </View>
      )
  }
  _setCommentData = (data, offset) => {
    this.setState({loading : false, comments : data, total_offset : (data.count/data.page_size)|0, offset : offset});
  }

  _addedComment = (data) => {
    //console.log(data);
    this.setState({comment_id : -1, textInput : '',loading : false,offset : 0,total_offset : 1});
    this._handleLoadMore();
  }

  _handleLoadMore = () => {
    if ((this.state.offset < this.state.total_offset)&&(!this.state.loading)) {
      this.setState({loading : true});
      this.props.wagtail.debug_comments(this.props.post_id,this.state.offset+1, this._setCommentData);
    }
  }

  _addComment = () => {
    if (this.state.comment_id === -1)
      this.props.wagtail.debug_add_comments(this.props.post_id,{text:this.state.textInput},this._addedComment);
    else
      this.props.wagtail.debug_add_reply(this.state.comment_id,{text:this.state.textInput},this._addedComment);

  }

  _setCommentId = (id,user) => {
    this.setState({comment_id : id, textInput : '@'+user+' '});
  }

  _changeText = (val) => {
    if (val.length === 0 ) {
      this.setState({height : 19, comment_id : -1,textInput : val});
    }else
      this.setState({textInput : val});
  }

  _setLikeComment = (data) => {
    let c = this.state.comments;
    let i = 0;
    for (i in c.results) {
      let post = c.results[i];
      if (post.id === data.comment) {
        if (post.like == null)
          c.results[i].like = {
            cnt : data.cnt,
            liked : true
          }
        else {
          c.results[i].like.cnt++;
          c.results[i].like.liked = true;
        }
      }
    }
    this.setState({comments : c});
  }

  _setLikeReply = (data) => {
    let c = this.state.comments;
    let i = 0;
    for (i in c.results) {
      let post = c.results[i];
      let j = 0;
      if (post.reply == null)
        continue;
      for (j in post.reply) {
        let reply = post.reply[j];
        if (reply.id === data.reply) {
          if (reply.like == null)
            c.results[i].reply[j].like = {
              cnt : data.cnt,
              liked : true
            }
          else {
            c.results[i].reply[j].like.cnt++;
            c.results[i].reply[j].like.liked = true;
          }

        }
      }
    }
    this.setState({comments : c});
  }


  render () {
    return (
      <View style={[styles.wrapper,{width : Math.min(1140, this.props.fullWidth)}]}>
        <FlatList
          data={this.state.comments.results}
          ref="flatList"
          style={[styles.wrapperList,{backgroundColor : '#fff', paddingLeft : 10, paddingTop : 10, paddingRight : 10}]}
          renderItem={({item,index}) => {

            return (
              <RootComment post={item} id={index}  key={index} setCommentId={this._setCommentId} wagtail={this.props.wagtail}
                auth={this.props.auth}
                login={this.props.login}
                setLikeReply={this._setLikeReply}
                setLikeComment={this._setLikeComment}
              />
            )
          }}
          onEndReached={this._handleLoadMore}
          onEndReachedThreshold={1}
          initialNumToRender={10}
          ListFooterComponent={this._renderFooter}
        />
      {this.props.auth ?
        <View style={{flexDirection : 'row',width : '100%',minHeight : 76, alignItems : 'center', borderTopWidth : 1, backgroundColor : 'black', padding : 16}}>

              <TextInput placeholder={'Оставьте комментарий'}
                  style={[styles.textinput, {height : Math.min(95, Math.max(this.state.height,44))}]}
                  value={this.state.textInput} onChangeText={this._changeText}
                  onFocus={() => {this.setState({borderColor : '#FFCB66'})}}
                  onBlur={() => {this.setState({borderColor : '#F1F3F5'})}}
                  
                  onContentSizeChange={(event)=> {
                    this.setState({height: event.nativeEvent.contentSize.height})
                    }
                  }
                  multiline={true}
                  />

          <TouchableOpacity onPress={this._addComment} style={{alignSelf : 'flex-end', justifyContent : 'flex-start', marginLeft: 8}}>
          <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="44" height="44" rx="22" fill="#FF7C77"/>
          <path d="M32.9646 12.1356C32.9633 12.1314 32.9633 12.1259 32.9619 12.1204C32.9289 12.0365 32.8807 11.9567 32.8133 11.888C32.801 11.8756 32.7858 11.8715 32.7734 11.8605C32.7638 11.8522 32.7624 11.8398 32.7528 11.8316C32.7349 11.8178 32.7129 11.8178 32.6937 11.8054C32.6249 11.76 32.5534 11.7284 32.4763 11.7105C32.4461 11.7036 32.4186 11.6954 32.3869 11.6926C32.2783 11.6816 32.1682 11.6899 32.065 11.7312L11.4321 19.983C11.1777 20.0834 11.0071 20.3268 11.0002 20.6019C10.9933 20.877 11.1501 21.1288 11.4005 21.2443L19.2534 24.8688V31.6241C19.2534 31.9322 19.4583 32.2032 19.7541 32.2857C19.816 32.3036 19.8792 32.3118 19.9411 32.3118C20.1764 32.3118 20.3992 32.1908 20.5271 31.9844L23.6468 26.8977L29.2824 29.4989C29.3731 29.5388 29.4722 29.5608 29.5698 29.5608C29.6813 29.5608 29.7913 29.5333 29.8917 29.481C30.0815 29.3806 30.2136 29.1976 30.248 28.9858L32.999 12.4808C33.0018 12.4671 32.9935 12.4547 32.9949 12.4409C33.0059 12.3378 33.0004 12.2346 32.9646 12.1356ZM19.7995 23.6061L13.4266 20.6652L29.358 14.2938L19.7995 23.6061ZM20.6289 29.188V25.5043L22.3882 26.3173L20.6289 29.188ZM29.0403 27.8702L21.1379 24.2237L31.2975 14.3254L29.0403 27.8702Z" fill="white"/>
          </svg>
          </TouchableOpacity>
        </View>
        :
        <View style={{flexDirection : 'row',width : '100%',height : 48, alignItems : 'center', borderTopWidth : 1, borderTopColor : 'rgba(180, 187, 194, 0.2)'}}>
          <TouchableOpacity onPress={this.props.login}>
            <Ptext style={{paddingLeft : 10}}>Для добавления комментария войдите в систему</Ptext>
          </TouchableOpacity>
        </View>
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  wrapper : {
    flexDirection : 'column',
    flex : 1,
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-end',
    marginTop : 0,
    backgroundColor : '#fff',
    width : '100%',
  },
  wrapperList : {
    width : '100%',
    backgroundColor : '#fff',
  },
  wrapperVertical : {
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
  },
  wrapperHorizontal : {
    flexDirection : 'row',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    marginBottom : 14,
    flex : 1,
    width : '100%'

  },
  wrapperVertComment : {
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    flex : 1,
    marginBottom : 3,
  },
  wrapperVertComment2ndLevel : {
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    flex : 1,
    borderLeftWidth : 1,
    borderColor : '#B4BBC2',
    paddingLeft : 12,
  },
  username : {
    fontSize : 14,
    lineHeight : 16,
    textAlign : 'left',
    fontWeight : 'bold',
    marginTop : 6,
    marginBottom : 6,
    flex : 1,
    width : '100%',
  },
  time : {
    fontSize : 14,
    lineHeight : 16,
    color : '#B4BBC2',
    textAlign : 'right',
  },
  liked_text : {
    fontSize : 12,
    lineHeight : 16,
    color : '#B4BBC2',
    textAlign : 'left',
    marginLeft : 20,
  },
  comment : {
    fontSize : 14,
    lineHeight : 16,
    color : '#000',
    textAlign : 'left',
  },
  textinput : {
    flex : 1,
    textAlign : 'left',
    backgroundColor : 'white',
    borderRadius : 3,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 16,
    padding: 16,
    borderColor: 'black',
  },
  vertLine : {
    borderColor : '#B4BBC2',
    width : 1,
    borderWidth : 1,
    marginRight : 12,
    height : '100%',
  },
  allcomments : {
    fontSize : 14,
    lineHeight : 16,
    color : '#B4BBC2',
    textAlign : 'left',
    alignSelf : 'flex-start',
    marginBottom : 18,
  }
});

export default CommentsFull;
