import React, { Component } from 'react';
import { View, StyleSheet, Text } from 'react-native';


class Footer extends Component {
    render() {
        return (
            <View style={styles.wrapper}>
                <Text style={styles.caption}>nenumerolog.ru</Text>
                <Text style={styles.caption_year}>{(new Date()).getFullYear()}</Text>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    wrapper : {
        flexDirection : 'row',
        justifyContent : 'flex-start',
        alignSelf : 'center',
        alignItems : 'center',
        backgroundColor : '#000',
        width : '100%',
        height : 60,
        paddingHorizontal : 16,
    },
    caption : {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: 21,
        textAlign : 'left',
/* identical to box height */
/* @primary_white */
        color: '#FFFFFF',
    },
    caption_year : {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: 21,
        flex : 1,
        textAlign : 'right',
/* identical to box height */
/* @primary_white */
        color: '#FFFFFF',
    }

})

export default Footer;