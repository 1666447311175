import React, { Component } from 'react';
import {View, StyleSheet, TouchableOpacity, TextInput, ScrollView} from 'react-native';
import Modal from 'modal-react-native-web';
import Ptext from '../../../common/Ptext.js';
import Button from '../../../common/Button.js';





class CreateExpertModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form : {
        first_name : undefined,
        middle_name : undefined,
        last_name : undefined,
        email : undefined,
        phone : undefined,
        percent : undefined,
      },
      form_hint : {
        first_name : undefined,
        middle_name : undefined,
        last_name : undefined,
        email : undefined,
        phone : undefined,
        percent : undefined,
      }
    }
  }

  _setFormField = (field,value) => {
    let f = this.state.form;
    let h = this.state.form_hint;
    f[field] = value;
    h[field] = undefined;
    this.setState({form : f, form_hint : h});
  }

  _formCb = (data, status) => {

    if (!status) {
      let hint = {};
      for (let i in data) {
        console.log(i);
        hint[i] = data[i][0];
      }
      console.log(hint);
      this.setState({error_desc : 'Ошибка', form_hint : hint});
      setTimeout(() => {this.setState({error_desc: undefined})}, 4000);
    }else {
      if (!data.hasOwnProperty('detail'))
        this.setState({done_desc : 'Сохранено'});
      else {
        this.setState({done_desc : data.detail});
      }
      setTimeout(() => {this.setState({done_desc: undefined})}, 4000);

    }

  }

  _submitForm = () => {

    this.props.numer.do_post_create_expert(this.state.form , this._formCb);

  }

  _inputFlowNum = (value) => {
    let ppp = value.replace(/[^\d]/g, '');
    this.setState({value : ppp});
  }

  render () {
    return (
      <Modal visible={this.props.visible}
        transparent={false} ariaHideApp={false} style={styles.modal} animationType={'fade'}>
        <ScrollView style={{width : this.props.fullWidth, height : this.props.fullHeight}}>
        <View style={styles.header}>
          <TouchableOpacity style={styles.headerChevron} onPress={this.props.close}>
            <Ptext style={styles.headerCaption}>
              Добавление нового эксперта
            </Ptext>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.props.close} style={styles.closeCross}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
            <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
            </svg>
          </TouchableOpacity>
        </View>
        <View style={styles.wholeForm}>
          {this.state.error_desc ?
          <Ptext style={styles.error}>
            {this.state.error_desc}
          </Ptext>
          :
          null }
          {this.state.done_desc ?
          <Ptext style={styles.done_desc}>
            {this.state.done_desc}
          </Ptext>
          :
          null }

          <View style={styles.formItem} >
              <Ptext style={styles.itemHint}>
                Имя эксперта
              </Ptext>
              {
                this.state.form_hint.first_name ?
                  <Ptext style={styles.itemHintError}>
                    {this.state.form_hint.first_name}
                  </Ptext>
                :
                null
              }
              <TextInput value={this.state.form.first_name} placeholder={'Имя эксперта'}
              style={[styles.itemTextInput,{borderColor : this.state.form_hint.first_name ? 'red' : 'black'}]}
                    multiline={false}
                    secureTextEntry={false}
                    onChangeText={this._setFormField.bind(this,'first_name')}/>
          </View>

          <View style={styles.formItem} >
              <Ptext style={styles.itemHint}>
                Отчество эксперта
              </Ptext>
              {
                this.state.form_hint.middle_name ?
                  <Ptext style={styles.itemHintError}>
                    {this.state.form_hint.middle_name}
                  </Ptext>
                  :
                  null
              }
              <TextInput value={this.state.form.middle_name} placeholder={'Отчество эксперта'}
              style={[styles.itemTextInput,{borderColor : this.state.form_hint.middle_name ? 'red' : 'black'}]}
                    multiline={false}
                    secureTextEntry={false}
                    onChangeText={this._setFormField.bind(this,'middle_name')}/>
          </View>

          <View style={styles.formItem} >
              <Ptext style={styles.itemHint}>
                Фамилия эксперта
              </Ptext>
              {
                this.state.form_hint.last_name ?
                  <Ptext style={styles.itemHintError}>
                    {this.state.form_hint.last_name}
                  </Ptext>
                  :
                  null
              }
              <TextInput value={this.state.form.last_name} placeholder={'Фамилия эксперта'}
              style={[styles.itemTextInput,{borderColor : this.state.form_hint.last_name ? 'red' : 'black'}]}

                    multiline={false}
                    secureTextEntry={false}
                    onChangeText={this._setFormField.bind(this,'last_name')}/>
          </View>

          <View style={styles.formItem} >
              <Ptext style={styles.itemHint}>
                Email эксперта
              </Ptext>
              {
                this.state.form_hint.email ?
                  <Ptext style={styles.itemHintError}>
                    {this.state.form_hint.email}
                  </Ptext>
                  :
                  null
              }
              <TextInput value={this.state.form.email} placeholder={'Email'}
              style={[styles.itemTextInput,{borderColor : this.state.form_hint.email ? 'red' : 'black'}]}

                    multiline={false}
                    secureTextEntry={false}
                    onChangeText={this._setFormField.bind(this,'email')}/>
          </View>

          <View style={styles.formItem} >
              <Ptext style={styles.itemHint}>
                Номер телефона эксперта
              </Ptext>
              {
                this.state.form_hint.phone ?
                  <Ptext style={styles.itemHintError}>
                    {this.state.form_hint.phone}
                  </Ptext>
                  :
                  null
              }
              <TextInput value={this.state.form.phone} placeholder={'Телефон'}
              style={[styles.itemTextInput,{borderColor : this.state.form_hint.phone ? 'red' : 'black'}]}

                    multiline={false}
                    secureTextEntry={false}
                    onChangeText={this._setFormField.bind(this,'phone')}/>
          </View>

          <View style={styles.formItem} >
              <Ptext style={styles.itemHint}>
                Сколько в процентах получает эксперт от заказа
              </Ptext>
              {
                this.state.form_hint.percent ?
                  <Ptext style={styles.itemHintError}>
                    {this.state.form_hint.percent}
                  </Ptext>
                  :
                  null
              }
              <TextInput value={this.state.form.percent} placeholder={'Процент'}
              style={[styles.itemTextInput,{borderColor : this.state.form_hint.percent ? 'red' : 'black'}]}

                    multiline={false}
                    secureTextEntry={false}
                    onChangeText={this._setFormField.bind(this,'percent')}/>
          </View>

          <Button title={'Добавить эксперта'} style={styles.button}
            onPress={this._submitForm}/>
        </View>
        </ScrollView>
      </Modal>

    )
  }

}

const styles=StyleSheet.create({
  modal : {
    width : '100%',
    height : '100%',
  },
  header : {
    width : '100%',
    minHeight : 65,
    justifyContent : 'flex-start',
    alignItems : 'center',
    alignSelf : 'center',
    flexDirection : 'row',
  },
  headerChevron : {
        marginLeft : 10,
        flexDirection : 'row',
        alignSelf : 'center',
        justifyContent : 'flex-start',
        alignItems : 'center',
        flex : 2,
  },
  headerCaption : {
    flex : 2,
    fontWeight : 'bold',
    fontSize : 20,
    lineHeight : 24,
    textAlign : 'left',
  },

  closeCross : {
    marginRight : 10,
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
  },
  backChevron : {
    marginLeft : 10,
  },
  wholeForm : {
    width : '90%',
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    alignSelf : 'center',
  },
  mainHint : {
    width  : '100%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
  },
  error : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : 'red',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  done_desc : {
    width  : '70%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
    backgroundColor : '#90ee90',
    alignSelf : 'center',
    padding : 10,
    borderRadius : 4,
  },
  formItem : {
    width : '100%',
    flexDirection : 'column',
    alignSelf : 'flex-start',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  itemHint : {
    fontSize : 12,
    lineHeight : 16,
    marginBottom : 8,
  },
  itemSubHint : {
    fontSize : 12,
    lineHeight : 16,
    color : '#B4BBC2',
    textAlign : 'left',
    marginBottom : 20,
  },
  errorHint : {
    fontSize : 12,
    lineHeight : 16,
    fontWeight : 'italic',
    color : 'red',
    textAlign : 'left',
    marginBottom : 20,
  },
  itemTextInput : {
    borderWidth : 1,
    height : 40,
    minHeight : 40,
    borderRadius : 4,
    flex : 1,
    margin : 4,
    paddingLeft : 8,
    width : '98%',
    marginBottom : 20,
  },
  itemHintError : {
    fontSize : 16,
    lineHeight : 20,
    marginBottom : 8,
    fontStyle : 'italic',
    fontWeight : 'bold',
    color : 'red',
    textAlign : 'left',
  },
  button : {
    width : '100%',
  }

});


export default CreateExpertModal;
