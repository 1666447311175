import React, {Component} from 'react'
import {TouchableOpacity, StyleSheet, View} from 'react-native';
import Ptext from "../../../common/Ptext.js"
import Chevron from "../../../common/chevron"
import { NavLink } from '../../../components/router/react-router';

class MenuArrowIcon extends Component{
    constructor(props) {
        super(props);
        this.state = {
            pos : {},
        }
    }

    componentDidMount() {
        if (this.touchButton !== undefined)
            this.touchButton.measure(this.setParams);
    }

    setParams = (ox, oy, width, height, px, py) => {
        let obj = {
            ox: ox,
            oy: oy,
            width: width,
            height: height,
            px: px,
            py: py,
        };
        this.setState({pos : obj});
    }

    _open = () => {
        if (!this.props.opened) {
            var ops = {
                obj : this.state.pos,
                items : this.props.items,
            }
            this.props.cbOpenMainMenu(ops, this.props.index);
        }else {
            this.props.cbCloseMainMenu();
        }

    }

    render () {
        return (
                <>
                {
                    this.props.items !== undefined && this.props.items.length > 1 ? 
                <TouchableOpacity onPress={this._open} style={[styles.button,this.props.style]} ref={(c) => this.touchButton = c}>
                    <Ptext style={this.props.style}>
                        {this.props.title}
                    </Ptext>
                    <Chevron opened={!this.props.opened} style={{paddingHorizontal: 0}}/>
                </TouchableOpacity>
                :
                <NavLink to={this.props.to} style={this.props.style}
                activeStyle={{
                    fontWeight: "bold",
                    color: '#FF7C77',
                  }}>
                       {this.props.title}
                </NavLink>
                }      
                </>

        )
    }
}


const styles = StyleSheet.create({
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent : 'center',
    },
    absolut_pos: {
        position: 'absolute',
        top: 76,
        zIndex: 4000,

    }
});

export default MenuArrowIcon;

/*
export default function MenuArrowIcon({ title = 'Blog', style = {} , active = false, onPress = null, fontSize = 14, to={}})
{
  return (
    <NavLink to={to} style={style}
        activeStyle={{
        fontWeight: "bold",
        color: '#FF7C77',
      }}>

        {title}

    </NavLink>
  )
}*/