import React from 'react'
import { StyleSheet } from 'react-native'
import { TouchableOpacity } from 'react-native';

export default function SearchIcon({ style = {}, notify = false, onPress = null  })
{
  return (
    <TouchableOpacity style={[styles.icon, style]} onPress={onPress}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9268 3C6.55608 3 3 6.55608 3 10.9268C3 15.2978 6.55608 18.8536 10.9268 18.8536C15.2978 18.8536 18.8536 15.2978 18.8536 10.9268C18.8536 6.55608 15.2978 3 10.9268 3ZM10.9268 17.3902C7.36292 17.3902 4.46341 14.4907 4.46341 10.9268C4.46341 7.36295 7.36292 4.46341 10.9268 4.46341C14.4907 4.46341 17.3902 7.36292 17.3902 10.9268C17.3902 14.4907 14.4907 17.3902 10.9268 17.3902Z" fill="black" fill-opacity="0.3"/>
          <path d="M20.7855 19.7509L16.5904 15.5558C16.3045 15.27 15.8416 15.27 15.5557 15.5558C15.2698 15.8414 15.2698 16.3049 15.5557 16.5905L19.7508 20.7856C19.8937 20.9285 20.0808 21 20.2682 21C20.4552 21 20.6425 20.9285 20.7855 20.7856C21.0713 20.5 21.0713 20.0366 20.7855 19.7509Z" fill="black" fill-opacity="0.3"/>
          </svg>
  </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  icon: {
    height: 56,
    alignItems: 'center',
    justifyContent : 'center',
  }
})
