import React, {Component} from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import ButtonArrow from "../../common/ButtonArrow.js"
import Ptext from "../../common/Ptext.js"
import ReviewItem from "./reviewItem.js"

import globalStyle from "../../styles/globalStyle.js"


class ReviewPageView extends Component {

  

  render () {
    
    return (
      <View style={[styles.page,{width: '100%', alignSelf : 'center', backgroundColor: 'white'}]} >
        <TouchableOpacity style={[styles.header_wrapper,{backgroundColor : 'white'}]} onPress={() => this.props.history.push('/review/')}>
          <View style={[styles.bar,{width : this.props.fullWidth > 510 ? 120 : 24}]}/>
          <Ptext style={styles.header_caption}> Отзывы</Ptext>
        </TouchableOpacity>
        <View style={[styles.reviewWrapper,{paddingHorizontal : this.props.fullWidth > 510 ? 124 : 10}]}>
        {this.props.review !== undefined ?

            this.props.review.map((item, index,arr)=> {
                return (
                  <ReviewItem 
                    fullWidth={this.props.fullWidth > 555 ? 300 : (this.props.fullWidth - 20)}
                    key={index}
                    header={item.header}
                    text={item.text}
                    style={{marginTop: 10}}
                >
                </ReviewItem>
                )
            })
        :
        null
        }
        </View>
        { this.props.nobutton === undefined || this.props.nobutton === false ? 
          <ButtonArrow title={'Все отзывы'} onPress={() => this.props.history.push('/review/')}/>
          :
          null
        }
      </View>
    );
  }
}
//
const styles = StyleSheet.create({
  page : {
    flexDirection : 'column',
    backgroundColor: globalStyle.colors.matrix_page_background,
    alignItems : 'center',
    alignContent: 'flex-start',
    justifyContent : 'flex-start'
   
  },
  reviewWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    alignSelf: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  contentContainer:{
    flex: 1,
    alignSelf: 'center',
    alignItems:'center',
    justifyContent: 'flex-start',
  },
  bar : {
    width : 24,
    height : 1,
    backgroundColor : globalStyle.colors.secondary,
  },
  header_wrapper : {
    width : '100%',
    height: 74,
    alignSelf : 'flex-start',
    flexDirection : 'row',
    justifyContent : 'flex-start',
    alignItems : 'center',
  },
  header_caption : {
    letterSpacing: 2,
    textTransform: 'uppercase',
    fontSize : 18,
    lineHeight : 20,
    fontStyle : 'normal',
    fontWeight : 'normal',
  }
})

export default ReviewPageView;
