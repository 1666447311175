import React,{Component} from 'react';
import {View, StyleSheet, TouchableOpacity,Image } from 'react-native';
import Modal from 'modal-react-native-web';
import Ptext from "../../../common/Ptext.js";
import Button from "../../../common/Button.js";




class ProfileViewOrder extends Component {
  render () {
    return (
          <Modal visible={this.props.visible}
            transparent={false} ariaHideApp={false} style={styles.modal} animationType={'fade'}>
            <View style={styles.header}>
              <TouchableOpacity style={styles.headerChevron} onPress={this.props.order.cbBack}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4 7.4L14 6L8 12L14 18L15.4 16.6L10.8 12L15.4 7.4Z" fill="black"/>
                </svg>
                <Ptext style={styles.headerCaption}>
                  Заказ №{this.props.order.number}
                </Ptext>
              </TouchableOpacity>
              <Ptext>
                В работе
              </Ptext>
              <TouchableOpacity onPress={this.props.order.cbBack} style={styles.closeCross}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5" y="17.9937" width="18" height="2" rx="1" transform="rotate(-45 5 17.9937)" fill="black"/>
                <rect x="6.5" y="5.49902" width="18" height="2" rx="1" transform="rotate(45 6.5 5.49902)" fill="black"/>
                </svg>
              </TouchableOpacity>
            </View>
            <View style={styles.wholeForm}>
              <View style={styles.expertView}>
                <View style={styles.expertViewContent}>
                <View style={styles.expertViewHoriz}>
                  <View style={styles.expertViewHoriz1stColumn}>
                    <Ptext style={{marginBottom : 12}}>
                      Валентина Игнатова
                    </Ptext>
                    <Ptext style={[styles.expertStatus,{marginBottom : 12}]}>
                      Эксперт
                    </Ptext>
                  </View>
                  <View style={styles.expertViewHoriz2ndColumn}>
                    <Image source={require('../../../icons/me-img-olga.png')} style={styles.expertAvatarstyle}/>
                  </View>
                </View>
                <Button title="Задать вопрос" style={styles.ButtonAskExpertQuestion}/>
                </View>
              </View>
            </View>
          </Modal>

        )
  }

}

const styles=StyleSheet.create({
  modal : {
  width : '100%',
  height : '100%',
  },
  header : {
  width : '100%',
  minHeight : 65,
  justifyContent : 'flex-start',
  alignItems : 'center',
  alignSelf : 'center',
  flexDirection : 'row',
  },
  headerChevron : {
    marginLeft : 10,
    flexDirection : 'row',
    alignSelf : 'center',
    justifyContent : 'flex-start',
    alignItems : 'center',
    flex : 2,
  },
  headerCaption : {
    flex : 2,
    fontWeight : 'bold',
    fontSize : 20,
    lineHeight : 24,
    textAlign : 'left',
  },

  closeCross : {
    marginRight : 10,
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
  },
    backChevron : {
    marginLeft : 10,
  },
  wholeForm : {
    width : '90%',
    flexDirection : 'column',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
    alignSelf : 'center',
  },
  mainHint : {
    width  : '100%',
    fontWeight : 'normal',
    fontSize : 14,
    lineHeight : 20,
    textAlign : 'left',
    marginBottom : 20,
  },
  formItem : {
    width : '100%',
    flexDirection : 'column',
    alignSelf : 'flex-start',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  itemHint : {
    fontSize : 12,
    lineHeight : 16,
    marginBottom : 8,
  },
    itemSubHint : {
    fontSize : 12,
    lineHeight : 16,
    color : '#B4BBC2',
    textAlign : 'left',
    marginBottom : 20,
  },
    itemTextInput : {
    borderWidth : 1,
    height : 40,
    minHeight : 40,
    borderRadius : 4,
    flex : 1,
    width : '100%',
    marginBottom : 20,
    borderColor : '#B4BBC2',
  },
    button : {
    width : '100%',
  },
  expertView : {
    flexDirection : 'column',
    width : '100%',
    alignSelf :  'center',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
    backgroundColor : 'rgba(180, 187, 194, 0.2)',
  },
  expertViewContent : {
    flexDirection : 'column',
    width : '90%',
    alignSelf : 'center',
    alignItems : 'flex-start',
    justifyContent : 'flex-start',
  },
  expertViewHoriz : {
    flexDirection : 'row',
    width : '100%',
    justifyContent : 'flex-start',
    alignSelf : 'flex-start',
    alignItems : 'flex-start',
    paddingTop : 14,
  },
  expertViewHoriz1stColumn : {
    flex : 1,
    flexDirection : 'column',
    alignSelf : 'flex-start',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',
  },
  expertViewHoriz2ndColumn : {
    alignSelf : 'flex-start',
    justifyContent : 'flex-start',
    alignItems : 'flex-start',

  },
  expertAvatarstyle : {
    width : 48,
    height : 48,
  },
  expertStatus : {
    backgroundColor : '#DC6AE6',
    borderRadius : 30,
    padding : 7,
    color : '#fff',
  },
  ButtonAskExpertQuestion : {
    backgroundColor : 'rgba(180, 187, 194, 0.2)',
    width : '100%',
    borderWidth : 1,
    borderColor : 'rgba(180, 187, 194, 0.6)',
    marginBottom : 16,
  },

  });


export default ProfileViewOrder;
