import React, { Component } from 'react';
import { Image, StyleSheet,TouchableOpacity, ScrollView} from 'react-native';
import Ptext from "../../common/Ptext.js"

class StoriesItem extends Component{

  onPress = () => {
    if (this.props.onSelect)
      this.props.onSelect(this.props.id)
  }

  render () {
    let item = {
      name : '',
      img_url : '',
    }
    if (this.props.item) {
      if (this.props.item.img_url)
        item.img_url = this.props.item.img_url;
      else
       item.img_url = require('../../icons/me-img-olga.png');
      if (this.props.item.name)
        item.name = this.props.item.name;
      else
        item.name = 'Пусто';
    }
    return (
      <TouchableOpacity onPress={this.onPress} style={{
            width : 76,
            height : 85,
            flexDirection : 'column',
            justifyContent:'center',
            alignItems : 'center'}}>
        <Image source={item.img_url} style={{width:60,height:60}}/>
        <Ptext style={[styles.captionStories,{marginTop : 8}]}>{item.name}</Ptext>
      </TouchableOpacity>
    )
  }
}


class Stories extends Component {

  render () {
    return (
      <ScrollView contentContainerStyle={[styles.mainContent]}
                style={{height : 120,
                  width : '100%',
                  shadowOpacity: 2,
                  shadowRadius: 2,
                  shadowColor: '#B4BBC2',
                  backgroundColor: 'white',
                  shadowOffset: { height: 0.5, width: 0 }}}
                  horizontal={true}>

        <>
        {
          Array.isArray(this.props.items) ? 
          <>
          {
                this.props.items.map((prop,key,arr) => {
                  return (
                      <StoriesItem key={key} id={key} item={prop} onSelect={this.props.onSelect}/>
                  );
                })
          }
          </>
          :
          null
        }
        </>

      </ScrollView>
    )
  }
}

const styles=StyleSheet.create({
  mainContent : {
    justifyContent: 'center',
    alignItems : 'center',
    flexDirection : 'column',
    backgroundColor : 'white',
    width: '100%',
    flexWrap : 'wrap',
  },
  captionStories : {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: 12,
    /* identical to box height */
    
    textAlign: 'center',
  },


});

export default Stories;
